import React from 'react'

/**
 * WHERE: caller <PageWrapper>
 */
export default function Four04( p ) {
    return (
        <div>
            404
        </div>
    )
}