import React from 'react';


import ParsedDescription from '../../../LayoutComponents/ParsedDescription';
import Gallery from '../../../LayoutComponents/Gallery';
import Video from '../../../LayoutComponents/Video';
import Comparison from './comparison-subcomps/Comparison';
import FadeHover from '../../../LayoutComponents/FadeHover';

// TODO: use parse.replace to make interactive ref links with popup

export default function CompContentAndGallery( { componentInfo,
                                                 index, 
                                                 webparams, setWebparams }) {
    
    // the bt4 grid classes for the colu,n of thext and gallery are set in acf,
    // separated by |. 
    const [ widthParentClass, setWidthParentClass] = React.useState('');
    const [ widthTextClass, setWidthTextClass] = React.useState('');
    const [ widthImgClass, setWidthImgClass] = React.useState('');
    // TODO: add option:
    // there is a way to show them in tiles , see https://simple-react-lightbox.dev/
    React.useEffect(()=>{
        // indexes 0: parent cotainer,  1: text column,  2: gallery column.   
        const classes = componentInfo.layout_columns.split('|'); // ie: splitting  "flex-column|is_one-col-md col-12|col-12"
        setWidthParentClass(classes[0]);
        setWidthTextClass(classes[1]);
        setWidthImgClass(classes[2]);
    }, []);
    

    return (
        <div className={`ContentAndGallery row 
                ${componentInfo.layout_position === 'images-text'? 'ContentAndGallery--inverse-md' : ''} 
                ${widthParentClass}`} 
             id={`component-${index}`}>

            <div className={ `ContentAndGallery__content lead ${componentInfo.description_layout?.includes('first-letter-large')?'first-letter-highlighted':''}  col-12 
                             ${widthTextClass} ${componentInfo.layout_position === 'center-text'? 'offset-md-2' : ''}`} >

                { [0,"0"].includes(index) && componentInfo.layout_position === 'text-images' && componentInfo.description? 
                    <span className="GapUnderLogo"></span> : null }
                
                <ParsedDescription  description={ componentInfo.description_layout?.includes('letter-small')?
                                                        `<div class="letter-small">${componentInfo.description}</div>` : componentInfo.description} 
                                    webparams={webparams} setWebparams={setWebparams} />
            </div>

            
            <div className={`ContentAndGallery__gallery col-12 
                            ${widthImgClass }
                            images-count-${componentInfo.gallery?.length}
                            ${componentInfo.include_video? ' has-video' : '' }
                            ${ componentInfo.gallery_layout?? null }
                            `} >
                { componentInfo.include_comparison && componentInfo.comparison && !componentInfo.comparison.is_fadeinout_comparison && 
                    <Comparison comparisonInfo={ componentInfo.comparison} webparams={webparams} /> }
                { componentInfo.include_comparison && componentInfo.comparison && componentInfo.comparison.is_fadeinout_comparison && 
                    <FadeHover comparisonInfo={ componentInfo.comparison} webparams={webparams} /> }

                { componentInfo.include_video ? 
                <Video videoInfo={ componentInfo.video } webparams={webparams} className="ContentAndGallery__video" /> 
                : null }
                <Gallery gallery={componentInfo.gallery} webparams={webparams} 
                         quality={  componentInfo.layout_columns.includes('is_one-col-md') || 
                                    (widthImgClass.includes('col-md-8')) ||  
                                     componentInfo.description_layout?.includes('gallery-preview-hi') ? 
                                        'large' : 'medium' }
                          />
            </div>
            
        </div>
    )
}