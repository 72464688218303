import React from 'react'
import { isDesktop } from 'react-device-detect';
import { linkToPage, updateQueryStringParameter } from '../../../../helpers';
import CircleButton from '../../../../LayoutComponents/CircleButton';
import { _t, __ } from '../../../../translations';

/**
 * Button to take to 180 explore and show video.
 * @returns 
 */
export default function MapButtonsTop({
    webparams, setWebparams, componentInfo, selectedHotspot, setSelectedHotspot
}) {

    const hasExploreLandscape   = componentInfo.hotspots[selectedHotspot]?.explore_landscape? true : false;
    const hasExploreQuadro      = componentInfo.hotspots[selectedHotspot]?.explore_quadro? true : false;
    const hasVideo              = componentInfo.hotspots[selectedHotspot]?.video_url? true : false;
    const hasPanoComponent      = componentInfo.hotspots[selectedHotspot]?.in_pano?.panorama_image_360? true : false;
    const hasPageLink           = componentInfo.hotspots[selectedHotspot]?.page_link? true : false;

    if  ( typeof selectedHotspot !== 'number' ) return null;

    return (<>
   <div className="btn-top-googlemaps">
       {hasExploreLandscape &&
       <CircleButton 
            onClickAction={ e=> { setWebparams(Object.assign({...webparams}, {
                                    exploreLanscapeSelected: componentInfo.hotspots[selectedHotspot].explore_landscape
                                }));
                                const newUrl = updateQueryStringParameter(window.location.origin, 'explore-landscape', selectedHotspot); 
                                window.history.replaceState(null, document.title, newUrl);
                                
                         } }  
            classes='btn-explore-180 btn-primary position-relative mx-2'
            iconClass='bi-camera2' textHelp={_t('Panorama/Panorama')} textInside={ _t('Explore Panorama/Esplora Panorama') } />
        }

       {hasVideo && 
       <CircleButton 
            onClickAction={ e=> { setWebparams(Object.assign({...webparams}, {
                                            videoBalconeSelected: componentInfo.hotspots[selectedHotspot].video_url,
                                            extraClasses: webparams.extraClasses + ' video-full-screen'
                            }))  } }  
            classes='btn-video-balcone btn-secondary position-relative mx-2'
            iconClass='bi-camera-reels' textHelp='Video' textInside={ 'Video Aereo' } />
        }
        
        { hasExploreQuadro &&
        <CircleButton 
            onClickAction={ e=> { setWebparams( Object.assign({...webparams}, 
                                                {exploreQuadroSelected:componentInfo.hotspots[selectedHotspot].explore_quadro} ));
                                const newUrl = updateQueryStringParameter(window.location.origin, 'explore-quadro', selectedHotspot); 
                                window.history.replaceState(null, document.title, newUrl);  
                                }  
                            }
            classes='btn-explore-quadro btn-primary position-relative mx-2'
            iconClass='bi-easel' textHelp={_t("Painting/Dipinto")} textInside={ _t('Explore Painting/Esplora dipinto') } />
        }
       { hasPanoComponent && isDesktop &&
        <CircleButton 
            onClickAction={ e=> { setWebparams( Object.assign({...webparams}, {pano360Selected:componentInfo.hotspots[selectedHotspot].in_pano} ))  }  }
            classes='btn-360pano-balcone btn-info position-relative mx-2'
            iconClass='bi-camera-reels' textHelp='360' textInside={ 'Interactive 360 panorama' } />
       }
       { hasPageLink &&
        <CircleButton 
            onClickAction={ e=> {
                // debugger
                const newUrl = updateQueryStringParameter( componentInfo.hotspots[selectedHotspot].page_link.permalink,
                                                         'back', window.location.href ); 
                window.location = newUrl;
                // window.history.replaceState(null, document.title, newUrl);  
                 // linkToPage(componentInfo.hotspots[selectedHotspot]?.page_link.post_name)    
            } }
            classes='btn-page-link btn-warning position-relative mx-2'
            iconClass='bi-arrow-right-circle' textHelp='Tour' textInside={ 'Read more' } />
       }
       
   </div>

   {/* Close selecetd hotspot btn */}
       <CircleButton 
            onClickAction={ e=> { setSelectedHotspot(null) }  }
            classes='btn-close-hotspot-balcone big-in-xs btn-danger mx-2'
            iconClass='bi-arrow-left-circle' textHelp={ '' } textInside={__('Back')} />
    </>

    )
}

