import React from 'react'
import { dev2, devdel, isDebug } from '../helpers';
import { __ } from '../translations';
import useKeypressHook from '../useKeyPressHook';
import ParsedDescription from './ParsedDescription';

import './Popup.scss';

/**
 * We set a popup modal window updating the field webparams.reference, using setWebparams.
 * The value of webparams.reference is set, we call the API tro grab the info of that reference slug in the posts DB, with a restful API call.
 * @param { object} webparams. 
 *          webparams.reference ( string slug ) if the field that we need
 */
export default function Popup( { webparams, setWebparams }) {

    // State. Every starts in webparams.reference though. The API call sets up the html to display.
    const [referencePost, setReferencePost]   = React.useState(null);
    const [hasError, setErrors]                     =  React.useState(false);

    async function fetchReferencePost(refSlug) {
        const endpoint  = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/reference/${refSlug}`;
        devdel('Reference endpoint: ', endpoint);
        const res = await fetch(endpoint);
        res.json().then(res => { 
                    dev2('res: ', res);
                    setReferencePost(res)
                } )
                .catch(err => {
                    console.warn('res error: ', res);
                    setErrors(err)
                });
    }

    // when the reference slug is given, look in the API for the content
    React.useEffect( ()=> {
        if (!webparams.reference) return;
        
        fetchReferencePost(webparams.reference)
        
        // return deval('cleanup popup');
    }, [webparams.reference]);

    // accept esc to close it
    useKeypressHook('Escape', () => { 
        if (webparams.reference) {
            const newWP = Object.assign( {}, webparams);
            delete newWP.reference;
            setWebparams(newWP);
        }
    }, [webparams.reference]);


    if (!webparams.reference) 
        return null;

    if (hasError) return <div className="text-danger">Error loading ref {webparams.reference}</div>;

    return (
        <div className="Popup">
            <div className="Popup__inner  col-12 col-md-8">
                <div className="card">
                    
                    <div className="card-header">
                        {/* <h3>HEADERR {referencePost?.post_title} </h3> */}
                        <button onClick={ e => setWebparams(Object.assign( { ...webparams}, { reference: null } ) ) }
                                className="btn btn-primary btn-small">
                            { __('Close') }
                        </button>
                    </div>
                    <div className="card-body">
                        <ParsedDescription description={referencePost?.post_content} webparams={webparams} setWebparams={setWebparams} />
                    </div>
                    {
                        isDebug() && process.env.REACT_APP_WP_BASEURL &&
                        <div className="card-footer">
                            <button onClick={ e => setWebparams(Object.assign( { ...webparams}, { reference: null } ) ) }
                                    className="btn btn-danger">
                                { __('Close') }
                            </button>
                            
                            <p className="text-dark d-none d-md-block">
                                <a  target="new" className="text-dark" 
                                    href={ process.env.REACT_APP_WP_BASEURL + 'wp-admin/post.php?post='+referencePost?.ID+'&action=edit'}>
                                        EDIT Ref in WP
                                </a>
                            </p> 
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}