import React from 'react'
import { dev1 } from '../helpers';
import { _t, __ } from '../translations';
export default function ContactForm({
    setCanOpenContactForm, setOpenContactForm
}) {
    return (
        <div className="modal-overlay-wrapper">
            <div className="modal-container">
                <button className="btn btn-danger close-modal" onClick={e => setOpenContactForm(false) && setCanOpenContactForm(false) }>
                    { _t('Close/Chiudi') }
                </button>
                <div className="card">
                    <div className="card-header">
                        <h2>{ _t('Contact us/Contattaci' )}</h2>
                    </div>
                    <div className="card-body">
                        <form onSubmit={ e=> {
                            e.preventDefault();
                            const endpoint  = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/ssend-eemail`;
                            const crap = Buffer.from(process.env.REACT_APP_USEREMAIL+":!:"+process.env.REACT_APP_USERPW).toString('base64'); // +":!:"+(new Date().toLocaleDateString())).toString('base64');
                            // const crap = base32.encode(process.env.REACT_APP_USEREMAIL+":"+process.env.REACT_APP_USERPW+(new Date().toLocaleDateString()));
                            fetch(endpoint, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    crap,
                                    name: document.querySelector('#form-name').value,
                                    email: document.querySelector('#form-email').value,
                                    message: document.querySelector('#form-message').value,
                                })
                            }).then( (red) => {
                                setOpenContactForm(false) && setCanOpenContactForm(false)
                                
                                red.json().then(
                                    res => { 
                                        dev1("ENVIADOO", res);
                                        if (res === false) alert('error in server. Email not sent');
                                        else alert(_t('Message sent/Messaggio inviato'));
                                    }
                                )
                            }).catch(err => {
                                alert('error sending');
                                dev1('Error when fetch ALL pages: ', err);
                                setOpenContactForm(false) && setCanOpenContactForm(false)
                            });

                        }}>
                            <div className="form-group">
                                <input id="form-name" name="name" className="form-control" type="text" placeholder='name' required defaultValue="" />
                                <input id="form-email" name="email" className="form-control" type="email" placeholder='email' required defaultValue="" />
                                <textarea id="form-message" name="message" className="form-control" placeholder='message' required defaultValue="">
                                </textarea>
                            </div>
                            <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="accept" id="form-acceptance" defaultChecked={false} required />
                                <label className="form-check-label" htmlFor="form-acceptance">
                                    { _t( 'Agree to /Accetto i') } <a href={ process.env.REACT_APP_BASEURL + __('privacy-link')} rel="noreferrer" target='_blank'> { _t('terms and conditions/Accetto i termini e condizioni' ) }</a>
                                </label>
                            </div>
                            </div>
                            <button className="btn btn-primary">{ _t('send/invia') } </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

