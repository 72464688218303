import React from 'react'
import CircleButton from '../../LayoutComponents/CircleButton';
import { _t } from '../../translations';


export default function IndexList( { webparams, indexedPages, options }) {

    const [indexCollapsed, setIndexCollapsed] = React.useState(false);

    if (!Array.isArray(indexedPages)) return null;

    const optionsO = Object.assign({
        showThumbnail: false,
        showCollapse: true
    },options);

    return  <>
            { optionsO.showCollapse && 
                <CircleButton
                onClickAction={ e=> setIndexCollapsed(!indexCollapsed) }
                classes='btn-expand-collapse-index btn-primary position-relative btn-info circle-btn-sm ml-3'
                iconClass='bi-arrow-down-circle' textHelp={indexCollapsed ? _t('Expand Index/Espandi Indice ') : _t('Collapse/Ripiega') } textInside={ _t('Change/Cambia') } />
            }
            <ul className={`IndexList ${indexCollapsed? 'index-collapsed' : 'index-expanded' }`}>{ indexedPages.map( (chapter,i) => 
                
                    jsx_single_page( chapter, webparams, 0, i, optionsO, indexedPages)
                
            
            ) } </ul></>
}
       



/**
 * Recursive way to show a page, and its children with a call to this same fn.
 * @param {object} page 
 * @param {object} webparams , with .lang
 * @param {int} depth 
 * @param {int} i 
 */
function jsx_single_page( page, webparams, depth = 0, i, opt) {
    
    let theLink;
    const hasLink = !page.template.includes("template-no-page.php");
    if (hasLink)
        theLink = page.permalink;
    else {
        // get the link of next page, it hast have one (doesnt apply to the top top level (depht 0))
        if (page.pages && page.pages[0]?.permalink) {
            theLink = page.pages[0]?.permalink;
        }
    }
    
    return <li key={`li-${depth}-${i}`}>
                
                { theLink && depth > 0? <a href={theLink}>
                    <span>{ page.post_title }</span>
                    { opt?.showThumbnail && depth === 1 && page.thumbnail && 
                        <div className='img-below'>
                            <img src={page.thumbnail} 
                                alt={'chapter'}
                                style={ { opacity: 0 }}
                                className='img-fluid' 
                                onLoad={ (e) =>  e.target.style.opacity = 1}
                                />
                        </div>
                    }
                </a> : <span> { page.post_title } </span>}
                
                

                { page.pages? <ul className={`depth-${depth}`}> { page.pages.map( (subpage,j) => 
                    jsx_single_page( subpage, webparams, (depth+1), j, opt )
                ) } </ul> : null }
            </li>
}