// TODO: min and max lng no funciona.
// crear hotspots
// crear commandos
// crear cuadro superposicion, o incluso fade to panorama solo con siluetas.

import React, {useRef, useState, useEffect} from 'react'
import * as THREE from "three";

import ParsedDescription from '../../../LayoutComponents/ParsedDescription';

import { 
    createThreeJSBase, // creates the renderer, camera, scene and world and saves it in an object useState `Base`
    mouseDownHandler,  // The handlers attached to the container. Used for orbit controls, selecting hotspots, drag n drop
    mouseMoveHandler,
    mouseUpHandler,
    mouseClickHandler,
    onZoomControl       // i removed it as it scrolls the whole window. But I think I could fix it TODO
        } from './panorama-subcomps/threejs-base';
import { animatedLookAt } from './panorama-subcomps/threejs-base'; // logic attached to Base to make an object draggable.
import { showShade } from './panorama-subcomps/hotspot-threejs';
import quadroPoster3D, { hideQuadro } from './panorama-subcomps/quadro-poster-3d'
import PanoHotspot from './panorama-subcomps/PanoHotspot';
import { convertFileUrlIntoFilePath, dev1, dev2, deval, devdel, isDebug } from '../../../helpers';
import { initDragNDrop } from './panorama-subcomps/drag-n-drop';





/**
 * Incapsulated logic to show a proper 360 panorama with hotspots.
 * CALLED FROM: a Tour Page with the component Panorama or
 *              From the page mappa-balconi, when selected a balcony and then activate the Pano 360 for it.
 * @param {*} param0 
 * @returns 
 */
export default function CompPanorama({ componentInfo,
    index, 
    webparams, setWebparams }) {


    // REACT params of this component. The interaction inside the Canvas Threejs is not controlled with react (use of DOM listeners and storage in `base` variable)
    const panoRef = React.useRef();
    const [base, setBase] = useState(null); // I don't use it as a state.
    const [panoHotspots, setPanoHotspots] = useState([]); // array ob hotspots (objects)
    const [errors, setErrors] = useState(null);
    const [selectedHotspot, setSelectedHotspot] = useState(null); // int (index in 'panoHotspots')
    const [zoomParamBackup, setZoomParamBackup] = useState({});


    const fetchPanoHotspots = async () => {
        // componentInfo.hotspots: set of post IDs of the hotspots, separated by commas.
        const endpoint  = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/pano-hotspots/${componentInfo.hotspots}`;
        console.log('Hotspots endpoing: ', endpoint);
        const res       = await fetch(endpoint);        
        res.json().then(res => { 
                    dev2('hotspots info result for '+endpoint+': ',res);
                    setPanoHotspots(res);
                } )
                .catch(err => {
                    setErrors(err)
                });
    }


    // On Mount : create the Three Scene. ( and fetch the hotpost info too )
    // The base.camera object (THREE js object) saves also several info as a redux store.
    useEffect( () => {
        // This only run once!. But just in case I check the 'base' existence/
        if (!componentInfo) return;
        if (base || !panoRef.current) return;
        
        fetchPanoHotspots(); // api call to get info about hotspots

        window.THREE = THREE; // for easy accesss in debug

        const filePano = componentInfo.panorama_image_360.url.replace('-scaled','');//sizes['2048x2048'];
        const bb = createThreeJSBase(panoRef.current, filePano);
        dev2('The created Base ThreeJS', bb);
        
        window.scene    = bb.scene; // for easy accesss in debug
        window.base     = bb; // for easy accesss in debug (and I need it to fix the bug of stopping animaterender)

        
        if (componentInfo.initial_params?.lat  && componentInfo.initial_params?.lng) 
            animatedLookAt( bb.camera, componentInfo.initial_params );

        if (componentInfo.initial_params?.limit_lng_left)   bb.camera.__limitLngLeft = parseFloat(componentInfo.initial_params?.limit_lng_left);
        if (componentInfo.initial_params?.limit_lng_right)  bb.camera.__limitLngRight = parseFloat(componentInfo.initial_params?.limit_lng_right);
        if (componentInfo.initial_params?.limit_lat_top)   bb.camera.__limitLatTop = parseFloat(componentInfo.initial_params?.limit_lat_top);
        if (componentInfo.initial_params?.limit_lat_bottom)  bb.camera.__limitLatBottom = parseFloat(componentInfo.initial_params?.limit_lat_bottom);

        // setBase( bb ); // init base
        window.scene = bb.scene; // will it work for three chrome ext.?

        if (bb.world && componentInfo.img_quadro) {
            // A place3D with the texture of the quadro.
            const quadroname = quadroPoster3D( {  theBase: bb,
                                                world: bb.world, 
                                                params: { 
                                                    image: componentInfo.img_quadro,
                                                    sprite: 1,
                                                    position: [-1000,-1000,-1000], // outside the view initially
                                                    transparent: true,
                                                    alpha: componentInfo.img_alpha_quadro?? null,
                                                },
                                                setBase } )
        } else setBase(bb);
        
        // @BOOK:DRAGNDROP Drag & Drop feature, see drag-n-drop.js
        // RESTORETHIS:?
        // initDragNDrop(bb, ['the_quadro']);        
        window.scrollTo(0, panoRef.current.offsetParent.offsetTop); 


        // on every resize we recalculat the scene dimensions. We need to use the 
        window.addEventListener("resize", () => {
            return; // Creo q esto on va y rompe el pano. TODO: ver con atencion.
            if (!window.base) return;
            var width = window.base.renderer.domElement.innerWidth;
            var height = window.base.renderer.domElement.innerHeight;
            window.base.camera.aspect = width / height;
            window.base.camera.updateProjectionMatrix();
            window.base.renderer.setSize(width, height);
        });
    
        return () => dev2('exiting Panorama')
    }, [] );

    // on loaded hotspots.
    useEffect( () => {
        if (!panoHotspots) return;

        if (componentInfo.initial_params.initial_hotspot_selected) {
            
            // initial ht sepected: find ht with that ID
            const theHt = panoHotspots.findIndex( ht => parseInt(ht.ID) === parseInt(componentInfo.initial_params.initial_hotspot_selected) );
            if (typeof theHt === 'number') 
                setSelectedHotspot(theHt);
            // alert(theHt.post_title);
        }

        devdel('A VER THE H:',panoHotspots);
    }, [panoHotspots]);

    // watch selectedHotspot change
    // WHEN: when clicking on a ht in the pano or in the list.
    useEffect( () => {
        // Selecting ht: zoom to it and display foto shade.
        if (typeof selectedHotspot === 'number') {
            setZoomParamBackup(Object.assign({...zoomParamBackup}, {zoom: base.camera.fov}))
            const ht3d = base.getHotspot3D(selectedHotspot);
            if (ht3d) {
                animatedLookAt( base.camera, ht3d.position, 20 ); // THREE.Vector3 {x,y,z}
                // onZoomControl( { deltaY: 100 }, base);
                base._selectedHt = selectedHotspot;
                setTimeout(() => {
                    showShade(ht3d, 'foto');
                }, 1000);
            }
        }
        // Unselecting ht (we could use the return??)
        if (typeof selectedHotspot !== 'number') { 
            panoHotspots.forEach((ht,i) => {
                const ht3d = base.getHotspot3D(i);
                showShade(ht3d, 'foto', 0 );
            });
            if (zoomParamBackup?.zoom) { // zoom back to where we were
                animatedLookAt( base.camera, null, zoomParamBackup?.zoom );
            }
            hideQuadro(base);
            if (base) delete(base._selectedHt);
            return;
        }
        return () => deval('unselectd HT');
    }, [selectedHotspot]);


    if (!componentInfo) return null;
    return (<>
        <div id={`panorama`}
            //onDragStart
            // onWheel={ (event) => onZoomControl(event, base) }
            onDoubleClick={ e => animatedLookAt( base.camera, null, base.camera.fov <= 9.1? 44.4 : base.camera.fov - 20) }

            onMouseDown={ (event) => mouseDownHandler(event, base)}
            onTouchStart={ (event) => mouseDownHandler(event, base)}
            //onDragOver
            onMouseMove={ (event) => mouseMoveHandler(event, base)}
            onTouchMove={ (event) => mouseMoveHandler(event, base)}
            //onDragEnd
            onMouseUp={ (event) => mouseUpHandler(event, base) }
            onMouseOut={ () => base? base.camera.__isUserInteracting = false : null }
            onClick={ event => mouseClickHandler(event, base) }
            ref={panoRef}
            className="w-100 app-main d-flex align-items-center overflow-hidden">      



            <div className='Panorama__controls'>
                <button className="btn btn-info rounded-circle" 
                        onClick={e=> {
                            if (isDebug() && window.base?.camera?.dragndropObjects?.length ) { 
                                // debugging purposes; TODELETE (resize quadro to find the right scale to put in cms)
                                const quadroSprite = window.base._getQuadro();
                                const ratioScale = 0.9;
                                quadroSprite.scale.set( quadroSprite.scale.x * ratioScale, quadroSprite.scale.y * ratioScale, quadroSprite.scale.z * ratioScale )

                            } else
                                animatedLookAt(base.camera,null,base.camera.fov+20)}
                        }>
                    <i className="bi bi-dash"></i>
                </button>
                <button className="btn btn-info rounded-circle"
                        onClick={e=> {
                            if ( isDebug() && window.base?.camera?.dragndropObjects?.length ) { // debugging purposes TODELETE
                                const quadroSprite = window.base._getQuadro();
                                const ratioScale = 1.1;
                                quadroSprite.scale.set( quadroSprite.scale.x * ratioScale, quadroSprite.scale.y * ratioScale, quadroSprite.scale.z * ratioScale )

                            } else
                            animatedLookAt(base.camera,null,base.camera.fov-20);
                            }}>
                    <i className="bi bi-plus"></i>
                </button>
            </div>
        </div>

        <ul className={"Panorama__hotspots-list card list-unstyled list-group " 
                        + ( typeof(selectedHotspot)==='number'? ' hotspot-selected':'')}>
            <li className="card-header list-group-item bg-dark text-light">
                Hotspots
            </li>
            <li className='card-body'> <ul className="list-unstyled">
            {  
                panoHotspots && panoHotspots[0]?.in_pano? 
                    panoHotspots.map( (hotspotParams,i) => {
                        hotspotParams.hotspotIndex = i;
                        return <PanoHotspot thebase={base} params={ hotspotParams } key={ 'ht'+i }
                            selectedHotspot={selectedHotspot} setSelectedHotspot={setSelectedHotspot} />
                        }
                    ) : null
            }
                </ul>
            </li>
        </ul>

        
        <div className={ 'Panorama__panel-hotspot card ' + 
            ( typeof(selectedHotspot)==='object'? 'hidden' : 'shown')
        }>
            { (typeof(selectedHotspot)!=='object' && panoHotspots[selectedHotspot]) ? (<>
                <div className="card-header h4">
                    {panoHotspots[selectedHotspot]?.post_title}

                    <button className='btn btn-secondary btn-sm float-right' 
                            onClick={ e => setSelectedHotspot(null) }>
                        Back
                    </button>
                </div>
                
                <div    className="card-body first-letter-highlighted" >
                    <ParsedDescription  description={panoHotspots[selectedHotspot]?.post_content} 
                            webparams={webparams} setWebparams={setWebparams} />
                </div> </>) 
            : null }
        </div>


        { componentInfo?.description?
            <div className={ 'Panorama__panel-description card ' + 
                ( typeof(selectedHotspot)==='object'? 'selected-hotspot' : '')
            }>
                    <div    className="card-body" >
                        <ParsedDescription  description={componentInfo?.description} 
                                webparams={webparams} setWebparams={setWebparams} />
                    </div>

            </div>
        : null }



        <div id="info-lat-lon" className={`card position-absolute ${isDebug()? '' : 'd-none'}`}/>
    </>)
}

