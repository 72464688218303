import * as THREE from "three";

/**
 * Standard fn to calculate the interected objects in the clicked point of the pano
 * @param { event } event : the click event
 * @param { object } base : The three base object that we use along this Panorama
 * @param { THREEJS object } childrenOf : optional. Returns only interections children of this threejs element
 * @param { string } findObjectName : optional. If set, returns the intersected object only if its name is this one. 
 */
export function checkIntersection( event, base, childrenOf = null, findObjectName = null) {

    // if not mobile
    if (typeof event.nativeEvent === 'undefined') {
        console.error('todelete. browser doesnt accept ');
        return null;
    }

    if (event.changedTouches) { // for mobile
        event.nativeEvent.offsetX = event.changedTouches[0].clientX;
        event.nativeEvent.offsetY = event.changedTouches[0].clientY;
    }
    
    const mouse = new THREE.Vector2();
    mouse.x = ( event.nativeEvent.offsetX / base.renderer.domElement.clientWidth ) * 2 - 1; // distance to the center
    mouse.y = - ( event.nativeEvent.offsetY / base.renderer.domElement.clientHeight ) * 2 + 1;
    
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera( mouse, base.camera );
    
    const parentObject = childrenOf? childrenOf : base.world;
    var intersects = raycaster.intersectObjects( parentObject.children ); 

    if ( intersects.length > 0 ) {
        // console.log('algo hay');
        if (findObjectName === 'all')
            return intersects;
        if (!findObjectName) 
            return intersects[0];
        else if (intersects[0].object.name === findObjectName) 
            return intersects[0];
    }else {
        // console.log("%cno interesction","color:red");
    } 

    return null; //no intersection
  }

// horizontal degrees
export function lngFromPosition(positionXYZ) {
    var angle = THREE.Math.radToDeg(Math.atan2(positionXYZ.z,positionXYZ.x));
    angle += 180;
    console.log('angle LNG:', angle);
    return angle;
}
// vertical degrees
export function latFromPosition(positionXYZ) { 
    var radio = Math.hypot(positionXYZ.x, positionXYZ.z);
    const hight = positionXYZ.y;
    var angle = THREE.Math.radToDeg(Math.atan2(radio,hight)); // atan gives angle to a point
    angle -= 90;
    console.log('lat', angle);
    return angle;
}

export function latLngToPosition(lat, lng, distance = 500) { 
    // convert degrees horizontal into position at 500 distance
    var angle = THREE.Math.degToRad(lng - 180);
    var [x, z] = [ Math.cos(angle) * distance, Math.sin(angle) * distance];

    // hight
    angle = THREE.Math.degToRad(lat + 90);
    var y = Math.cos(angle) * distance;

    return [x,y,z];
}


// function for drawing rounded rectangles. Copied and pasted
function roundRect(ctx, x, y, w, h, r) {
    ctx.beginPath();ctx.moveTo(x+r, y);ctx.lineTo(x+w-r, y);ctx.quadraticCurveTo(x+w, y, x+w, y+r);ctx.lineTo(x+w, y+h-r);ctx.quadraticCurveTo(x+w, y+h, x+w-r, y+h);ctx.lineTo(x+r, y+h);ctx.quadraticCurveTo(x, y+h, x, y+h-r);ctx.lineTo(x, y+r);ctx.quadraticCurveTo(x, y, x+r, y);ctx.closePath();ctx.fill(); ctx.stroke();   
}

// To create labels. copied and pasted from internet, seems to be workingo ok.
export function makeTextMaterial(message, parameters) {

    message = ` ${message} `
    // still doesnt accept more than one line
    // const words = messageOriginal.split(' ');
    // const message = words.reduce( (acc,word,indx)=> {
    //     let ret = ((indx && (indx % 2 === 0))? '<br> ' : ' ');
    //     return acc + ret + word;
    // }, '' ) + ' ';
    
    const p = Object.assign( {
        materialType: 'sprite',
        fontface: "Arial",
        fontsize: 18,
        borderThickness: 4,
        borderColor: { r:0, g:0, b:0, a:1.0 },
        backgroundColor: { r:255, g:255, b:255, a:1.0 },
    }, parameters);
			
	var canvas = document.createElement('canvas');
	var context = canvas.getContext('2d');
	context.font = "Bold " + p.fontsize + "px " + p.fontface;
    
    // get size data (height depends only on font size)

	var metrics = context.measureText( message );
	var textWidth = metrics.width;
	
	// background color
	context.fillStyle   = "rgba(" + p.backgroundColor.r + "," + p.backgroundColor.g + ","
								  + p.backgroundColor.b + "," + p.backgroundColor.a + ")";
	// border color
	context.strokeStyle = "rgba(" + p.borderColor.r + "," + p.borderColor.g + ","
								  + p.borderColor.b + "," + p.borderColor.a + ")";

	context.lineWidth = p.borderThickness;
    roundRect(context, p.borderThickness/2, p.borderThickness/2, 
                textWidth + p.borderThickness,   // width
                p.fontsize * 1.4 + p.borderThickness, // height
                6);
	// 1.4 is extra height factor for text below baseline: g,j,p,q.
	
	// text color
	context.fillStyle = "rgba(0, 0, 0, 1.0)";

	context.fillText( message, p.borderThickness, p.fontsize + p.borderThickness);
	
	// canvas contents will be used for a texture
	var texture = new THREE.Texture(canvas) 
	texture.needsUpdate = true;

    const materialParams = { map: texture, useScreenCoordinates: false }
    var spriteMaterial = parameters['materialType'] === 'mesh'? 
                                new THREE.MeshBasicMaterial( materialParams ) : 
                                new THREE.SpriteMaterial( materialParams );
    
    return { material: spriteMaterial, width: textWidth } ;
}

export function makeTextSprite( message, parameters )
{
	if ( parameters === undefined ) parameters = { materialType: 'sprite'};

    const {material, width} = makeTextMaterial(message, parameters);
    // const material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
	var sprite = new THREE.Sprite(material);
	sprite.scale.set(300,150,1); // not sure if I should calculate it depending on the text.
	return sprite;	
}
