import React from 'react'
import { dev1, dev2, deval, updateQueryStringParameter } from '../helpers';
import { _t, __ } from '../translations';
import CircleButton from '../LayoutComponents/CircleButton';
import Video from '../LayoutComponents/Video';
import CompExplore from './Components/SingleComponent/CompExplore';
import { Offline } from "react-detect-offline";

import CompLeafletOrGoogleMap from './Components/SingleComponent/CompGoogleMap';
import CompPanorama from './Components/SingleComponent/CompPanorama';

/**
 * WHAT: the page showing the Google map with  all the balconies, and the 360 Pano of the selected balcony.
 * note: it's actually not the full page, only the map.
 * WHERE: caller <PageWrapper>. In Sigla (homepage) and in the page with template map-balconies in WP
 * Logic: sets up webparams for the children, which are components inside a page.
*/
export default function MapPage( { componentInfo, index, webparams, setWebparams} ) {
    
    if (!componentInfo) componentInfo = { // default params when called from Sigla
        description_layout:  ['show-list-of-hotspots' ]
    }

    /** STATES */
    const [allBalconies, setAllBalconies]       = React.useState([]);
    // We use the states in webparams to select if
    // pano360Selected - pano360 is selected (info in the .in_pano in component)
    // exploreLanscapeSelected - explore 180 is selected (info in the .explore_pano in component)
    // exploreQuadroSelected - explore quadro (info in the .explore_quadro in component)
    // videoBalconeSelected is selected ( .video_url )
    // before we used:
    // const [pano360Selected, setPano360Selected] = React.useState(false); // probably we will not use the 360.
    // we use webparams.pano360Selected so we can use it anywhere.
    // we should use [exploreLanscapeSelected, setExploreLanscapeSelected], but
    // these selected params for CompExplore are stored in webparams.exploreLanscapeSelected, in that way we can access to them from upper levels.

    const defaultDescription_layout = [
        'show-list-of-hotspots', 'use-leaflet-not-google'
    ];
    
    /*> ON MOUNT */ React.useEffect(()=>{
        if (!webparams.lang) return;
        fetchBalconies();
        return ()=>{};
    },[webparams.lang]);

    /** WATCH the selection/unselection of the 180 panorama (for a Balcone) */
    React.useEffect(()=>{
        // when selecting explore panorama 180, scroll to the component (not needed because I made it full screen fied in the end)
        // also, add classes to div container so we can hide/show things with csss
        const newWP = {...webparams};
        //selection
        if (webparams.exploreLanscapeSelected) {
            document.getElementById('explore-180').scrollIntoView({behavior: "smooth"});
            setWebparams(Object.assign(newWP, { extraClasses: newWP.extraClasses + ' explore-180-selected' }))
        } 
        //unselection
        if (webparams.exploreLanscapeSelected === null) { // it's null when it was somwthing and then one has been delesected (it's not the initial state)
            window.scrollTo(0,0);
            if (newWP.extraClasses)
                setWebparams(Object.assign(newWP, { extraClasses: newWP.extraClasses.replace(' explore-180-selected','') }));
            const newUrl = updateQueryStringParameter(window.location.href, 'explore-landscape', null ) ; // delete the query string in the url (it was created when the btn was clicked)
            window.history.replaceState(null, document.title, newUrl);
        }
        // selction
        if (webparams.exploreQuadroSelected) {
            setWebparams(Object.assign(newWP, { extraClasses: newWP.extraClasses + ' explore-quadro-selected' }))
        } 
        //unselection
        if (webparams.exploreQuadroSelected === null) { // it's null when it was somwthing and then one has been delesected (it's not the initial state)
            if (newWP.extraClasses)
                setWebparams(Object.assign(newWP, { extraClasses: newWP.extraClasses.replace(' explore-quadro-selected','') }));
            const newUrl = updateQueryStringParameter(window.location.href, 'explore-quadro', null ) ; // delete the query string in the url
            window.history.replaceState(null, document.title, newUrl);
        }
        // selection pano 360 > set
        if (webparams.pano360Selected) {
            setWebparams(Object.assign(newWP, { extraClasses: newWP.extraClasses + ' pano-360-selected' }))
            // by removing the overflow we can use the wheel without scrolling the whole page
            document.body.dataset.originalbodyoverflow = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
        } 
        // unselection 360 
        if (webparams.pano360Selected === null) { // when closing the 360 pano we set it to null
            if (newWP.extraClasses)
                setWebparams(Object.assign(newWP, { extraClasses: newWP.extraClasses.replace(' pano-360-selected','') }))
            const backupedBodyOverflow = document.body.dataset.originalbodyoverflow;
            if (backupedBodyOverflow)  // @LASTFIXED - remove this line. When coming back from 360 the app does not scroll.
                document.body.style.overflow = backupedBodyOverflow;
        }
    },[webparams.exploreLanscapeSelected, webparams.pano360Selected, webparams.exploreQuadroSelected]);

    /** FETCH API FN */
    async function fetchBalconies() {
        const { lang }  = webparams;
        if (! lang ) return;
        const endpoint  = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/balconies-map/${lang}`;
        const res       = await fetch(endpoint);
        dev2('endpoint balconi: ', endpoint);
        res.json().then(res => { 
            dev2(`Balconies dio: `, res);
            res.map( balc => Object.assign(balc, balc.in_map) ); // make in_map properties at level of the object
            setAllBalconies(res);

            // TODELETE DEBUG 
            // setSelectedHotspot(1); in google maps component
            // setWebparams(Object.assign( {...webparams}, {
            //     exploreQuadroSelected:  res[4].explore_quadro
            // }));
            // system to send the user from Landscape exploration to a Tour page and make him come to the landscape exploration.
            var search = window.location.search;
            var params = new URLSearchParams(search);
            if (params.get('explore-landscape')) {
                const balco = parseInt(params.get('explore-landscape'));
                if (res[balco])
                    setWebparams(Object.assign( {...webparams}, {
                        exploreLanscapeSelected:  res[balco].explore_landscape
                    }))
            }
            else if (params.get('explore-quadro')) {
                const balco = parseInt(params.get('explore-quadro'));
                if (res[balco])
                    setWebparams(Object.assign( {...webparams}, {
                        exploreQuadroSelected:  res[balco].explore_quadro
                    }))
            }



        } )
        .catch(err => {
           dev1('error fetching balconies for google maps '+endpoint, err.message);
        });
    }

    /** HANDLERS */


    /** template jsx */
    return (<>
        <div className="Component">
            
            <CompLeafletOrGoogleMap componentInfo={ Object.assign(
                                            componentInfo, { 
                                                hotspots: allBalconies,
                                                description_layout: defaultDescription_layout
                                            }) }
                        index={index}
                        // setPano360Selected={setPano360Selected}
                        // setExploreLanscapeSelected={setExploreLanscapeSelected}
                        webparams={webparams} setWebparams={setWebparams} />
        
            <Offline>
                <div className="Component__noGoogleMap">
                    <p> { _t('You are offline/Sei offline') }</p>
                    <p> { _t('You can not access to the Map of the Balconies, as it requires internet connection. /Non puoi accedere alla cartina dei balconi. La connessione ad internet e\' necessaria') }</p>
                </div>
            </Offline>
        </div>

        { webparams.pano360Selected && ( // we prbably wont use the 360 spherical threejs TODELETE
            <div className="Component Component__panorama Component__panorama-mappage">
                <div className="Component__panorama--btn-close-wrapper">
                <CircleButton
                    onClickAction={ e => setWebparams( Object.assign({...webparams}, {pano360Selected:null} )) }
                    classes='btn-danger position-relative'
                    iconClass='bi-x-circle' textHelp={__('Close')} textInside={ 'X' } />
                </div>
                {/* componentInfo: .description (str), .hotspots (array of ids) ... */}
                <CompPanorama
                        componentInfo={webparams.pano360Selected}
                        index={index}
                        webparams={webparams} setWebparams={setWebparams} />
            </div>)
        
        }
        { (webparams.exploreLanscapeSelected || webparams.exploreQuadroSelected) && (
        <div id="explore-180" className="position-fixed">
            {/* Close btn  */}
            <div className="explore-180-close-btn z-9">
                <CircleButton
                    onClickAction={ e=> setWebparams(Object.assign({...webparams}, 
                                            webparams.exploreLanscapeSelected? {exploreLanscapeSelected:null} : {exploreQuadroSelected:null} )   ) }
                    classes='btn-primary position-relative btn-danger'
                    iconClass='bi-arrow-left-circle' textHelp={__('')} textInside={ __('') } />
            </div>
            {  webparams.exploreLanscapeSelected && (
            <CompExplore 
                        title={webparams.exploreLanscapeSelected.title}
                        fullImage={webparams.exploreLanscapeSelected.full_image.url} 
                        markers={webparams.exploreLanscapeSelected.markers} 
                        options={webparams.exploreLanscapeSelected.options}
                        />
            )}
            {  webparams.exploreQuadroSelected && (
            <CompExplore 
                        title={webparams.exploreQuadroSelected.title}
                        fullImage={webparams.exploreQuadroSelected.full_image.url} 
                        markers={webparams.exploreQuadroSelected.markers} 
                        options={webparams.exploreQuadroSelected.options}
                        />
            )}

        </div>) }

        { webparams.videoBalconeSelected && (
            <div id="video-balcone-fullscreen" className="position-fixed">
                {/* Close btn  */}
                <div className="explore-180-close-btn z-9">
                    <CircleButton
                        onClickAction={ 
                            e => setWebparams(Object.assign(  {...webparams}, { 
                                    videoBalconeSelected:null, 
                                    extraClasses: webparams.extraClasses.replace(' video-full-screen','') })) 
                        }
                        classes='btn-primary big-in-xs position-relative btn-danger'
                        iconClass='bi-bi-arrow-left-circle' textHelp={ __('Back') } textInside={ __('Back') } />
                </div>
                
                <Video videoInfo={ { video_type:'url', video_url: webparams.videoBalconeSelected,
                                         className: 'w-100 h-100', playing: true } } 
                        webparams={webparams} />
            </div>) 
        }
    </>)
}

