// React and styles
import React, {useState} from 'react';
import './Sigla.scss';
// Other components
import TopBar from '../LayoutComponents/TopBar';
import IndexList from './Components/IndexList';
import MapPage from './MapPage';
// import ExploreItaly3D from './ExploreItaly3D'; @BOOK:Explore3D
import ParsedDescription from '../LayoutComponents/ParsedDescription';
import Video from '../LayoutComponents/Video';
// helpers
import { _t, __ } from '../translations.js';
import { dev2, linkToPage } from '../helpers';
import CircleButton from '../LayoutComponents/CircleButton';
import useKeypressHook from '../useKeyPressHook';
import { isDesktop } from 'react-device-detect';
import { Offline, Online } from 'react-detect-offline';
import Comparison from './Components/SingleComponent/comparison-subcomps/Comparison';
import FadeHover from '../LayoutComponents/FadeHover';

/**
 * WHERE: caller <PageWrapper>
 * Logic: sets up webparams for the children.
 * Grabs the frontpage info to show the right video.
 * Shows VIDEO and below the index for all the site navigation.
 * @param { p.allPages } p : needs the p.allPages to show the full index
 * @param { p.webparams } p : 
 */
export default function Sigla( p ) {
    // on mount we can generate the webparams
    

    // current Page info
    const [hasError, setErrors] =  useState(false);
    const [pageParams, setPageParams] =  useState({});
    // video related
    const [videoPlay, setVideoPlay] = useState(true);
    const [mostrareCover, setMostrareCover] = useState(true);
    const [isVideoClosed, setIsVideoClosed] = useState(false);
    const videoContainerRef = React.useRef();
    const videoRef          = React.useRef();
    const videoRefHandler = React.useCallback((node) => { // better way to check when we can use videoRef.current safely.
        // on mount video
        if (!node) {
        //    alert('node is null');
            return;
        }
        videoRef.current = node;
        if (localStorage.getItem('videoClosedAtLeastOnce') === '1') {
            setIsVideoClosed(true);
            return;
        } 
        setTimeout( () => setVideoPlay(videoRef.current._playing), 500 );
    }, []);

    const endpoint_video = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/frontpage/${p.webparams.lang}`;
    async function fetchFrontpage() {
        // TODO: get language from URL!
        const res = await fetch(endpoint_video);
        res.json().then(res => { 
            dev2('fetch frontpage dio: ', res); // [ { ID:2, post_title:...}, {}, ...]
            setPageParams(res)
        })
             // .catch(err => { setErrors(err); alert(err) } );
    }

    React.useEffect(()=>{
        // API call to load the video params.
        if (!p.webparams.lang) return;
        fetchFrontpage(); // init params for this page (video url)

        // when page loads, we decide if whowing the video or the map:
        if (0) // if we saved in localstorage that the video shoudnt be displayed.
            handleCloseVideo({}) // or setIsVideoClosed(true);, its the same
    },[p.webparams.lang]);
    // Watch isVideoClosed: show the map.
    React.useEffect(()=>{
        // when the video closes, or if it's closed by default, we show the map.
        if (!isVideoClosed) return;
        videoContainerRef?.current?.classList.add('video-closed');
        const newWP = Object.assign( {...p.webparams}, { isMapPage: true });
        localStorage.setItem('videoClosedAtLeastOnce', '1');
        p.setWebparams(newWP)
    },[isVideoClosed]);//WATCH

    // accept esc to close video
    useKeypressHook('Escape', () => { 
        if (!isVideoClosed) {
            setVideoPlay(false);
            setIsVideoClosed(true);
        }
    }, [isVideoClosed]);
    

    if (!p.webparams.lang) return null;

    // --- 
    function handleVideoPaused(e) {
        e.preventDefault();
        if (!videoRef.current) return;
        if (videoPlay)
            videoRef.current?.pause();
        else videoRef.current?.play();
        setVideoPlay(!videoPlay);
    }
    function handleCloseVideo(e) {
        if ((!videoRef.current) || (!videoContainerRef.current)) {
            setIsVideoClosed(true);
            return;
        }
        videoRef.current.pause();
        videoContainerRef.current.style.height = videoContainerRef.current.offsetHeight + "px";
        setTimeout( ()=> {
            setIsVideoClosed(true);
        }, 500);
    }
    return (<>
        <div className={`Sigla Sigla--${mostrareCover? 'cover-on' : 'map-on' }`}>
            <TopBar webparams={p.webparams} setWebparams={p.setWebparams}
                    allPages={p.allPages} ></TopBar>
            
            {/* IF THE USER HAS ALREADY CLOSED THE VIDEO */}
            { isVideoClosed? <>    
            <div className="Sigla__CircleBtns PrevNextButtons z-9">
                { !mostrareCover &&
                    <p className="text-intro only-mobile-portrait card text-center">
                        <span className='card-body text-center d-block'>{ __('intro-text') }</span>
                    </p>
                }   
                <div className="btns-wrapper">
                { mostrareCover &&
                <CircleButton
                    onClickAction={ e => setMostrareCover(false) }
                    classes='btn-warning position-relative explore-map'
                    iconClass='bi-map' textHelp={_t('Explore Map/Esplora Mappa')} textInside={ _t('Balconies/I Balconi') } />
                }

                <CircleButton
                    onClickAction={ e => { localStorage.removeItem('videoClosedAtLeastOnce'); setIsVideoClosed(false); setVideoPlay(true);  } }
                    classes='btn-info position-relative replay-btn'
                    iconClass='bi-play' textHelp={_t('Play Intro/Play Intro')} textInside={ 'replay' } />

                <CircleButton
                    onClickAction={ e => linkToPage(p.allPages?.first_page?.post_name) }
                    classes='btn-primary PrevNextButtons__next position-relative start-tour-btn'
                    iconClass='bi-arrow-right-circle' textHelp={__('Start Tour')} textInside={ p.allPages?.first_page?.post_title } />
                </div>
            </div>

                {/* @BOOK:Explore3D */}
            {/* <ExploreItaly3D webparams={p.webparams} setWebparams={p.setWebparams}/> */}
            {mostrareCover ? 
            <div className="Sigla__cover"> 
                {pageParams?.post_content && 
                <div className="Sigla__cover-poem d-none d-md-block">
                    <ParsedDescription description={pageParams?.post_content} 
                            webparams={p.webparams} setWebparams={p.setWebparams}
                    />
                </div>}
                {
                pageParams?.cover_comparison_option === '2'? (
                    <FadeHover comparisonInfo={ { 
                        image_left: process.env.REACT_APP_BASEURL + 'sigla-landscape.jpg' ,
                        image_right: process.env.REACT_APP_BASEURL + 'sigla-landscape-quadro.jpg'
                    } }  />
                    )
                :   (
                    <Comparison comparisonInfo={ { 
                        image_left: { sizes: { medium_large: process.env.REACT_APP_BASEURL + 'sigla-landscape.jpg' } },
                        image_right: { sizes: { medium_large: process.env.REACT_APP_BASEURL + 'sigla-landscape-quadro.jpg' } }
                        } }
                        />
                    ) 
                }
            </div>
            :
                <MapPage // show the map when the video has dissapeared.
                        webparams={p.webparams} setWebparams={p.setWebparams}
                /> 
            }

            </>: null }



            {/* SHOW THE VIDEO: IF THE USER DIDNT CLOSE THE VIDEO */}
           
            { pageParams?.video_url && !isVideoClosed  ?
                <div ref={videoContainerRef} 
                     className={"Sigla__video-container position-relative"}>
                    
                    <Video videoInfo={ { video_type:'custom', video_upload: pageParams.video_url,
                                         className: 'w-100 h-100', videoRef: videoRefHandler, handleVideoPaused,
                                         playing: videoPlay,
                                         poster_url:  pageParams.poster_url } } 
                             />
                    { !videoPlay && (
                        <div className="btn-play-overlay-wrapper">
                            <CircleButton
                                onClickAction={ handleVideoPaused }
                                classes='btn-info position-relative'
                                iconClass='bi-play' textHelp={__('Play')} textInside={ 'X' } />
                        </div> ) }
                    
                    <button className="icon icon-close position-absolute"
                            onClick={ handleCloseVideo }>
                        ╳
                    </button>

                </div> :null}

            

            <Offline>   
                {!isVideoClosed  &&
                    <p className="w-100 text-center btn btn-primary"
                        onClick={ handleCloseVideo }>No Connection</p>
                }
            </Offline>

            { hasError? <p><div className="h1 card card-error">Error loading video</div>
                            {JSON.stringify(hasError)} {endpoint_video} </p>:null}

            
            <div className="Sigla__separator separator-video" />
            
        </div>


        {/* Index with all pages. Only for desktop. In mobile they have to use the button for index */}
        { isDesktop && 
            <section className={`section Sigla__index container Sigla__index--${mostrareCover? 'cover-on' : 'map-on' }`}>
                
                <h2>{ _t('The Tour/Il Tour') }</h2>

                <IndexList webparams={p.webparams} indexedPages={p.allPages.indexed} options={{ showThumbnail: true, twoColumns: true, showCollapse: false}} />

            </section>
        }
        
    </>
    )
}