import React from 'react'

import { getLang } from '../helpers';
import { _t, __ } from '../translations';
import CircleButton from './CircleButton';
import './PrevNextButtons.scss';


/**
 * 
 * @param {Post Object} currentPage , we use currentPage.prev, currentPage.next, which is given from PHP, (api call)
 */
export default function PrevNextButtons( { currentPage }) {

    const [hasBackReferral, setBackReferral] = React.useState(false);


    React.useEffect(()=>{
        
        var search = window.location.search;
        var params = new URLSearchParams(search);
        if (params.get('back')) {
            setBackReferral(params.get('back'));
        }
    },[]);
        

    if (hasBackReferral) 
        return <div className="PrevNextButtons z-9">
            <CircleButton
                    onClickAction={ e=> window.location = hasBackReferral }
                    classes='btn-primary position-relative btn-danger'
                    iconClass='bi-arrow-left-circle' textHelp={_t('back/torna')} textInside={ _t('Back to map/Torna all\'esplorazione del balcone') } />

            { currentPage?.next &&
            <CircleButton
            onClickAction={ e=> window.location = currentPage.next.permalink }
            classes='btn-primary position-relative btn-info circle-btn-sm ml-3'
            iconClass='bi-arrow-right-circle' textHelp={_t('Continue tour/Continua il tour')} textInside={ currentPage.next.post_title } />
            }
        </div>

    return (
        <>
        { currentPage?.breadcrums && currentPage.breadcrums.length &&
            <div className="Breadcrums navbar bg-dark w-100 d-none d-lg-block">
                <ul className='d-flex list-unstyled'>
                    { currentPage.breadcrums.map( (pag,i) => 
                        <li key={`br-${i}`} className={'depth-'+i+' '}>
                            { ((i+1) === currentPage.breadcrums.length? 
                                (<span className='mx-2 text-light'>...</span>) : (
                                (i)? <span className='mx-2 text-warning'>↦</span> : ''
                                )) }
                            { pag.permalink?
                                <a href={ pag.permalink }>{ pag.post_title }</a>
                              : <>{ pag.post_title }</> }
                        </li>
                    )}
                </ul>
            </div>
        }

        <div className="PrevNextButtons z-9">
            {
                currentPage?.prev?
                    <button className='circle-btn btn btn-primary PrevNextButtons__prev position-relative d-none d-sm-block'
                            onClick={ e => window.location.href=`/${getLang()}/${currentPage.prev.post_name}`}
                    >
                        <i className="bi bi-arrow-left-circle"></i>
                        <span className="circle-btn__text-help">{ __('Prev') }</span>
                        <span className="circle-btn__text-inside text-dark">{ currentPage.prev.post_title }</span>
                    </button>
                :null
            }

            {
            currentPage?.next?
                    <button className='circle-btn btn-primary PrevNextButtons__next position-relative'
                            onClick={ e => window.location.href=`/${getLang()}/${currentPage.next.post_name}`}>
                        <i className="bi bi-arrow-right-circle"></i>
                        <span className="circle-btn__text-help">{__('Next')}</span>
                        <span className="circle-btn__text-inside">{ currentPage.next.post_title }</span>
                    </button>
                :null
            }
        </div>
        </>
    )
}