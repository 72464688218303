/** Two Images in one. When hovering you see the second image in dissolvence */
import React, { useState } from 'react'
import './FadeHover.scss';

import {
    // BrowserView,
    // MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";
import { __ } from '../translations';

/**
 * comparisonInfo = { options_fadeinout, image_left, image_right, caption, id }
 * @param {string|object} image_left : can be an url or a WP media object. (.sizes.large)
 * @param {string|object} image_right
 * @returns 
 */
function FadeHover( { comparisonInfo }  ) {

    const options = React.useMemo(()=>{
        return {
            showControlOnOff:   comparisonInfo.options_fadeinout?.includes('show_control_on_off') ,
            showHelp:           comparisonInfo.options_fadeinout?.includes('show_help') ,
            size:               comparisonInfo.options_fadeinout?.includes('use_large_images')? 'large' : 'medium',
        };
    }, [comparisonInfo])

    // texts for help overlay
    const texts = {
        'show_overlay' : (isMobile? __('Tap') : __('Click')) + __(' to see the overlay'),
        'show_initial' : (isMobile? __('Tap') : __('Click')) + __(' to see the inital image'),
    };

    const [showImageRightFixed, setShowImageRightFixed] = useState(false);
    const [showingImageRight, setShowingImageRight] = useState(false);

    // const [images, setImages] = useState({ image_left: comparisonInfo.image_left, image_right: comparisonInfo.image_right });
    React.useEffect(() => {
        if (showImageRightFixed) setShowingImageRight(true);
        if (!showImageRightFixed) setShowingImageRight(false);
        return () => {
        }
    }, [showImageRightFixed])


    // COMPUTED
    const imageSrc = (img)=> typeof img === 'string'? img : img.sizes[options.size];
    const interactOnHover = () => isBrowser && !showImageRightFixed && !options.showControlOnOff;
    return (
        <div    className={`FadeHover 
                            ${showingImageRight && 'show_img-right'}
                            ${showImageRightFixed && 'fixed_img-right'}`
                          }
                id={'comp-'+comparisonInfo.id?? 'rnd-'+parseInt(Math.random()*100)}

                onMouseEnter={ e=> interactOnHover() && setShowingImageRight(1)} // only browser, when hover exists
                onMouseLeave={ e=> interactOnHover() && setShowingImageRight(0)}
                onClick={      e=> setShowImageRightFixed(!showImageRightFixed)} // browser and mobile. Clicking fixes the image.
                >
            
            { options.showHelp && 
                <div className="FadeHover__help">
                    { !showingImageRight && interactOnHover() ? <p>
                        { __('hover to overlay the image') } 
                    </p> : null }
                    { showingImageRight && !showImageRightFixed && interactOnHover() ? <p>
                        { __('click to stick the image') } 
                    </p> : null }

                    { !showingImageRight && !interactOnHover() ? <p>
                        {texts.show_overlay}
                    </p> : null }
                    { showingImageRight && !interactOnHover() ? <p>
                        {texts.show_initial}
                    </p> : null }
                </div>
            }


            <figure>
                <div className="img-wrapper FadeHover__image-left">
                    <i className="bi bi-hand-index-thumb"></i>
                    <img src={imageSrc(comparisonInfo.image_left)} alt="img left" />
                </div>
                <div className="img-wrapper FadeHover__image-right">
                    <i className="bi bi-eye"></i>
                    <img src={imageSrc(comparisonInfo.image_right)} alt="img right" />
                </div>
            </figure>
         

            {comparisonInfo.caption &&
                <figcaption>
                    {comparisonInfo.caption}
                </figcaption> }
        </div>
    )
}

export default FadeHover
