import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // Redirect,
  // useLocation,
  // useParams
} from "react-router-dom";


import PageWrapper from './PageWrapper';
import Four04 from './Pages/404';
import './App.scss';

import { isComingSoon } from './helpers';
import CompExplore from './Pages/Components/SingleComponent/CompExplore';

/**
 * Making the routing here. 
 */
function App() {
  if (0)
  return <CompExplore fullImage={`http://localhost:9000/wp-content/uploads/2021/03/PANO_serantes-1-scaled.jpg`} 
                      markers={
                        [
                          {
                            id: 'marker-1',
                            title: 'Monte d\'Asdrubale',
                            position: ['55%', '20%'],
                            size: ['10%', '25%'],
                            description: '<p>Blabalbalba</p>',
                            stick_size: '20px',
                            shapes: {
                              default_img: 'http://localhost:9000/wp-content/uploads/2021/05/4-9-rocca-test.png',  
                              hover_img: 'http://localhost:9000/wp-content/uploads/2021/05/4-16-antico-castello.jpg',  
                              selected_img: 'http://localhost:9000/wp-content/uploads/2021/05/4-16-antico-castello.jpg'
                            }
                          },
                          {
                            id: 'marker-2',
                            title: 'Colle dei Matto',
                            position: ['15%', '80%'],
                            size: ['20%', '5%'],
                          }
                        ] } 
                      options={{ showMarkersList: true, reactToMouseOnShape: true}}
          />

  return   <div className="App"><Router>
            <Switch>

              <Route exact path="/" children={ <PageWrapper lang="it" navInfo={isComingSoon()? 'TourPage' : 'Sigla' } /> } />

              <Route exact path="/en">
                <PageWrapper lang="en" navInfo="Sigla" />
              </Route>
              <Route path="/it/:pageSlug">
                <PageWrapper lang="it" navInfo="TourPage" />
              </Route>
              <Route path="/en/:pageSlug">
                <PageWrapper lang="en" navInfo="TourPage" />
              </Route>

              {/* Default (404) */}
              <Route>
                <Four04 lang="it"/>
              </Route>
            </Switch>
          </Router></div>
}

export default App;
