import React from 'react'
import ParsedDescription from './ParsedDescription';
import ContactForm from './ContactForm';
import { dev1, dev2 } from '../helpers';
import './Footer.scss';
import { _t } from '../translations';
export default function Footer({ webparams }) {

    const [footerPages, setFooterPages] = React.useState(null); // Object with the parent. All pages ar in the footerPages.pages
    const [hasError, setErrors]         =  React.useState(false);
    const [canOpenContactForm, setCanOpenContactForm] =  React.useState(false);
    const [openContactForm, setOpenContactForm] =  React.useState(false);

    
    async function fetchFooterPages () {
        
        if (!webparams.lang) return [];

        // API CALL for all pages in index.
        const endpoint  = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/footer-pages/${webparams.lang}`;
        dev2('Footer pags endpoint: ', endpoint);
        const res = await fetch(endpoint);
        res.json().then(res => {
                    dev2('fetch footer pages: ', res); // [ { ID:2, post_title:...}, {}, ...]
                    setFooterPages(res);
                } )
                .catch(err => {
                    dev1('Error when fetch FOOTER pages: ', res, err);
                    setErrors(`${endpoint} >>> ${err.message}`);
                });
    };


    React.useEffect( ()=>{
        fetchFooterPages();
    }, [webparams.lang]);

    if (!webparams || !webparams.lang) return null; 

    if (hasError) return <p>Footer error</p>
    return <>
        <footer id="Footer" className="bg-primary pb-5">
            <div className='container'>
                <div className='row'>
                    <div className='left-links col-12 col-sm-5 mb-5 d-flex flex-column align-items-center justify-content-center'>
                        <ul className="footer-pages-links">
                         {footerPages?.pages? footerPages.pages.map(page => (
                             <li key={'page-'+page.ID}>
                                 <a href={ page.permalink } className='text-white'>
                                    {page.post_title}
                                 </a>
                             </li>
                         )): <p className='small text-info'>Loading footer</p>}
                         </ul>
                         <div className="text-center mt-3">                            
                            <button className="btn btn-secondary text-center m-auto" 
                                    onMouseEnter={e=> setCanOpenContactForm(true)}
                                    onClick={ e => canOpenContactForm && setOpenContactForm(true) } >
                                    { _t ('Contact us/Contattaci') } 
                            </button>
                        </div>
                    </div>
                    <div className='col-12 col-sm-7'>
                        <ParsedDescription description={footerPages?.post_content} 
                                webparams={webparams}
                            />                    
                    </div>
                </div>
            </div>
        </footer>
        { openContactForm && <ContactForm 
                                setCanOpenContactForm={setCanOpenContactForm} 
                                setOpenContactForm={setOpenContactForm} 
                                /> }
    </>

}

