import React from 'react'

function MarkerLi({
         markerParams, markerKey,
         selectedMarker, setSelectedMarker, 
         hoveredMarker, setHoveredMarker }
    ) {

    const letterABC = ('ABCDEFGHIJKLMNOPQRSTUWXYZ')[markerKey];

    // COMPUTED classNames: the class selected or hovered
    const markerClassByMouseComputed = React.useMemo(() => {
        let markerClassByMouse = (markerKey === selectedMarker)? ` marker-selected ` : '';
        markerClassByMouse += (markerKey !== selectedMarker && markerKey === hoveredMarker )? ` marker-hovered ` : '';
        markerClassByMouse += (markerKey === selectedMarker && markerKey === hoveredMarker )? ` marker-selected-hovered ` : '';
        return markerClassByMouse;
    }, [selectedMarker, hoveredMarker]);


    return (
        <li onMouseEnter={ e => setHoveredMarker(markerKey) }
            onMouseLeave={ e => setHoveredMarker(null) }
            onClick={ e => selectedMarker === markerKey? setSelectedMarker(null) : setSelectedMarker(markerKey) }
            className={`${markerClassByMouseComputed}`}
        >
            <span>{letterABC}</span> <div>{ markerParams?.title }</div>
        </li>
    )
}

export default MarkerLi
