import {    checkIntersection, 
    lngFromPosition, latFromPosition,
    latLngToPosition,
} from './pano-helpers.js'


import * as THREE from "three";
import TWEEN from '@tweenjs/tween.js';
import { dev1, dev2, deval, devdel } from '../../../../helpers.js';


/**
* Creates scene, camera, renderer, orbitcontrols.
* @param {domElement} domElement: where the <canvas> is built.
* @returns {Base class object} Base.
*               props: 
*               methods: 
                    .getHostpost3D(0)  
*                       .
*/
export function createThreeJSBase(theElement, panoMap) {

    if (!theElement) return;

    // === THREE.JS CODE START ===
    var BaseClass = function(panoramaMap) {
            
        this.fov = 30; // Field of View default
        this.width =  window.innerWidth
        this.height = window.innerHeight
        this.ratio = this.width / this.height;
        this.updateCallbacks = []; // array of fns
        this.addUpdateCallback = (fn) => this.updateCallbacks.push(fn);
        this.removeLastUpdateCallback = () => this.updateCallbacks.pop();
        // action filters on events (@BOOK:DRAGNDROP, for example, uses these as filters).
        this.registeredOnMoveEvents = [];
        // Each event registration is an array of fns. Add a fn to be executed in that event.
        this.addRegisteredOnMoveEvent = (fn) => this.registeredOnMoveEvents.push(fn);
        this.removeLastRegisteredOnMoveEvent = () => this.registeredOnMoveEvents.pop();
        this.registeredOnDownEvents = [];
        this.addRegisteredOnDownEvent = (fn) => this.registeredOnDownEvents.push(fn);
        this.removeLastRegisteredOnDownEvent = () => this.registeredOnDownEvents.pop();
        this.registeredOnUpEvents = [];
        this.addRegisteredOnUpEvent = (fn) => this.registeredOnUpEvents.push(fn);
        this.removeLastRegisteredOnUpEvent = () => this.registeredOnUpEvents.pop();

        // useful stuff 
        this.getHotspot3D = function(htIndex) { 
            if (typeof htIndex === 'number') 
                return this.world.children.filter(ht=>ht.name.startsWith('hotspot_'))[htIndex];
            return this.world.children.find( ob => ob.name === 'hotspot_'+htIndex);
        }


        // Create Base elements
        this.camera = new THREE.PerspectiveCamera(this.fov, this.ratio, 1, 1000);

        // DEBUG TODELETE
        window.TWEEN = TWEEN;
        this.scene = window.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setSize(this.width, this.height);

        // Elements used by orbit controls
        this.camera.__isUserInteracting = false;
        this.camera.__orbitCameraBlocked = false; // @BOOK:DRAGNDROP
        this.camera.__autoRotate = 0.05;
        this.camera.lng = 0; // always updating. Says the lon(horizl) of the camera (0-360), `z` and `x` axis
        this.camera.lat = 0; // "       ". Says the lat(verticl), -90 (top), 90 (bottom), `y` axis
        this.camera.phi = 0;
        this.camera.theta = 0;
        // only for calculations when obiting the camera
        this.camera.onMouseDownMouseX = 0; this.camera.onMouseDownMouseY = 0; this.camera.onMouseDownLon = 0; this.camera.onMouseDownLat = 0;
        // vars when mouse is down: dont need to understand them
        this.camera.onPointerDownPointerX = 0; this.camera.onPointerDownPointerY = 0; this.camera.onPointerDownLon = 0; this.camera.onPointerDownLat = 0;

        // Permanently updated data 
        this.currentMouseIntersection = null; // always updating. Says the obj over the mouse at any time.
        this.camera.renderActive = true; // to stop animating (fn animate()) when we are not in the screen anymore
        /** ------ ------ ------ ------ ------ ------  */
        /** ------ ------ ------ ------ ------ ------  */

        // create world
        const geometry = new THREE.SphereBufferGeometry( 1000, 60, 40 );
        geometry.scale( - 1, 1, 1 ); // invert the geometry on the x-axis so that all of the faces point inward
        dev2('creating world with '+panoramaMap);
        this.world      = new THREE.Mesh( geometry, new THREE.MeshBasicMaterial( 
                                        
            {   map: new THREE.TextureLoader().load( panoramaMap ),
                                        } ) );
        this.world.name = 'world-sphere';
        this.scene.add( this.world );

        // Animation, render loop of any 3d scene
        const self = this;
        var animate = function () {
            if (!self.camera.renderActive) return; 

            requestAnimationFrame( animate );

            TWEEN.update();
            // custom frame animations. Use addUpdateCallback
            self.updateCallbacks.forEach( callbackFn => 
                    callbackFn(self) 
            );
            self.renderer.render( self.scene, self.camera );
        };
        animate();

        }; // end definition BaseClass. Now we call it and include it in the DOM.

        // Call of the base to init the 3D World
        const Base = new BaseClass(panoMap);
        window.addEventListener('resize', () => {
        Base.camera.aspect = window.innerWidth / window.innerHeight;
        Base.camera.updateProjectionMatrix();
        Base.renderer.setSize( window.innerWidth, window.innerHeight );
        });

        Base.addUpdateCallback(bb => animationUpdateCamera(bb) ); // this makes the orbit camera functionality work
        theElement.appendChild( Base.renderer.domElement ); // insert the Canvas in the div
        // mousewheell doesnt exist on react, so I include it here.
        // theElement.addEventListener( 'mousewheel', (event)=> onZoomControl(event, Base)); 


        console.log("%c Start the APP", "fontWeight:bold;")
        // to debug in Chrome:


        // - example TWEEN -
        // var position    = { x : 0, y: 300 };
        // var target      = { x : 400, y: 50 };
        // var mtween      = new TWEEN.Tween(position).to(target, 2000);
        // mtween.onUpdate(() => console.log('TODELETEpos: ', position) );
        // mtween.start();

        return Base;
        // === THREE.JS EXAMPLE CODE END ===
    }

    /**
    * My personal Orbit Controls:
    * on mouse down I save where I was pointing
    * on mouve move I update the angle where I am aiming.
    * on animation frame update I make the camera looking 
    */

    // executres on every frame render. 
    // Makes the orbit camera work when dragging the mouse.
    export function animationUpdateCamera( base ) {

        if ( isNaN(base.camera.lat) ) return;
        if ( base.camera.__orbitCameraBlocked ) return; // when drag n drop

        // autorotate action:
        if (base.camera.__isUserInteracting === false) {
            base.camera.lng += base.camera.__autoRotate;
            // if there are limits right and left, we change direction of autorotate when limit is overcomen
            if  (   (base.camera.__limitLngLeft && base.camera.lng < base.camera.__limitLngLeft) ||
                    (base.camera.__limitLngRight && base.camera.lng > base.camera.__limitLngRight) ) {
                        base.camera.__autoRotate = -base.camera.__autoRotate;
                    }
        
            
            
        }

        // cleanup: simplification of lng, let's keep it always between -360-360
        if (base.camera.lng < -360) base.camera.lng += 360;
        if (base.camera.lng > 360) base.camera.lng -= 360;

        
        // restrictions of lng (horizontal axis)
        if  ( (base.camera.__limitLngLeft && base.camera.lng < base.camera.__limitLngLeft ) ) 
            base.camera.lng = parseFloat(base.camera.__limitLngLeft);
        if  ( (base.camera.__limitLngRight && base.camera.lng > base.camera.__limitLngRight ) ) 
            base.camera.lng = parseFloat(base.camera.__limitLngRight);
        
        // restrictions of lat (top (-90 by default) and bottom (90 by default))
        if  ( (base.camera.__limitLatTop && base.camera.lat < base.camera.__limitLatTop ) ) 
            base.camera.lat = parseFloat(base.camera.__limitLatTop);
        if  ( (base.camera.__limitLatBottom && base.camera.lat > base.camera.__limitLatBottom ) ) 
            base.camera.lat = parseFloat(base.camera.__limitLatBottom);


        // the update if user is interacting
        base.camera.lat = Math.max(-85, Math.min(85, base.camera.lat));    
        base.camera.phi = THREE.Math.degToRad(90 - base.camera.lat);
        base.camera.theta = THREE.Math.degToRad(base.camera.lng);
        base.camera.position.x = 100 * Math.sin(base.camera.phi) * Math.cos(base.camera.theta);
        base.camera.position.y = 100 * Math.cos(base.camera.phi);
        base.camera.position.z = 100 * Math.sin(base.camera.phi) * Math.sin(base.camera.theta);

        // ONLY ON DEBUG
        var log = ("position <br/>"); 
        // i dont understan why it gives the pos in the negative 
        log = log + "<input id='pos-input' value='" + ( parseInt(base.camera.position.x) + ", " +  parseInt(base.camera.position.y) + ", " + parseInt(base.camera.position.z)) + "' />";
        log = log + "<button onClick=' document.querySelector(\"#pos-input\").select(); document.execCommand(\"copy\"); '>copy</button>";
        log = log + ("<br/>fov: " + parseFloat(base.camera.fov).toFixed(4));
        log = log + ("<br/>lat: " + parseFloat(base.camera.lat).toFixed(4));
        log = log + ("<br/>lng: " + parseFloat(base.camera.lng).toFixed(4));
        log = log + ("<br/>Selected: " + base.camera.currentMouseIntersection?.object?.name);
        
        const debugPanel = document.querySelector('#info-lat-lon');
        if (debugPanel) debugPanel.innerHTML = log;


        

        // console.log(log);
        // if (base.camera.__isUserInteracting) {
        base.camera.lookAt(base.scene.position); 
        base.camera.position.set(0,0,0);

        
    // }
    }

    // Orbit camera starts
    export function mouseDownHandler( event, base )
    {

        if (!base) return;

        // event.preventDefault();
        const clickedObj = base.camera.currentMouseIntersection?.object;
        
        if (base.camera.dragndropObjects?.includes(clickedObj?.name)) // @BOOK:DRAGNDROP
        {
            // drag n drop exception. 
            base.camera.__orbitCameraBlocked = true;
        }
        else
        {
            // Orbit camera calculations. Sets the clicked point for the camera
            if (typeof event.touches !== 'undefined') {
                event.clientX = event.touches[0].clientX;
                event.clientY = event.touches[0].clientY;
            }
            
            base.camera.onPointerDownPointerX = event.clientX;
            base.camera.onPointerDownPointerY = event.clientY;
            base.camera.onPointerDownLon = base.camera.lng || 0;  // lon and lat are updated on every
            base.camera.onPointerDownLat = base.camera.lat || 0;     // mouse move
            base.camera.__isUserInteracting = true;
            base.camera.__autoRotate = 0;
        }

        // THIS WORKS: just for info on screen. Grabs info of where the mouse is in the world sphere.
        // this is different than currentMouseIntersection, which talks about the object pointed.
        // TODELETE (or show only on debug);
        const intersectWorld = checkIntersection(event, base, base.scene, "world-sphere");
        var lon = lngFromPosition(intersectWorld.point);
        var lat = latFromPosition(intersectWorld.point);
        const position = latLngToPosition(lat, lon, 600) ;
        console.log(`clicked lat: ${lat}, lon: ${lon} POS:`);
        console.log('%c'+position[0].toFixed(2)+','+position[1].toFixed(2)+','+position[2].toFixed(2), 'color:lightgreen');




        // Execute other mousedown events, like drag and drop. (Registered mousedown event to the Object under the mouse.)
        if (base.camera.currentMouseIntersection) {
            
            // Additional onMouse Watch events. Used for drag and drop. Not in use at the moment
            base.registeredOnDownEvents.forEach( theFunction => 
                theFunction(event, base, clickedObj)
            );

            // other actions passed as a filter
            if (clickedObj._onMouseDownCallback) {
                clickedObj._onMouseDownCallback(event, base, clickedObj);
            }
        }

    }


    export function mouseMoveHandler(event, base) {
        
        // Mobile:
        if (typeof event.touches !== 'undefined') {
            event.clientX = event.touches[0].clientX;
            event.clientY = event.touches[0].clientY;
        }


        if (base) {
        if (base.camera.__isUserInteracting) // calculation for orbit camera. 
        {
            base.camera.lng = (event.clientX - base.camera.onPointerDownPointerX) * -0.175 + base.camera.onPointerDownLon;
            base.camera.lat = (event.clientY - base.camera.onPointerDownPointerY) * -0.175 + base.camera.onPointerDownLat;
        }

        // permanently check what object is aiming the mouse (currentMouseIntersection).
        const intersectObject = checkIntersection(event, base, base.world, null);
        if (typeof intersectObject !== 'undefined' && intersectObject) {
            if (intersectObject.object.name !== base.camera.currentMouseIntersection?.object.name) {
                base.camera.currentMouseIntersection = intersectObject;
                if (intersectObject.object._onMouseEnterCallback)
                    intersectObject.object._onMouseEnterCallback(event, base);
            }
        // mouse is not pointing to any element. We update the var to null
        } else if (base.camera.currentMouseIntersection) {
            if (base.camera.currentMouseIntersection.object._onMouseOutCallback)
                    base.camera.currentMouseIntersection.object._onMouseOutCallback(event, base);
            base.camera.currentMouseIntersection = null
        }
            // console.log('interext todelete', intersectObject.map(i => i.object.name).join(',') );


        // Drag and drop feature. We place the selected object where the mouse is
        // if (base.camera.currentDragging) {
            
        //     const interection = checkIntersection( event, base, base.scene, 'world-sphere');
        //     const v = interection.point.normalize().multiplyScalar(base.camera.currentDragging.distance);
            
        //     let v_and_offset = [
        //         v.x + base.camera.currentDragging.clickOffset[0],
        //         v.y + base.camera.currentDragging.clickOffset[1],
        //         v.z + base.camera.currentDragging.clickOffset[2]
        //         ];
        //     base.camera.currentDragging.object.position.set( ...v_and_offset )
        // }

        // Additional onMouse Watch events. Used for drag and drop. Not in use at the moment
        base.registeredOnMoveEvents.forEach( theFunction => 
            theFunction(event, base) 
        );

        }
    }

    // releasing the button of mouse (means we are not dragging anymore)
    export function mouseUpHandler(event, base) {
        if (!base) return;
        if (base.camera.__isUserInteracting) {
            base.camera.__isUserInteracting = false
            // setTimeout( ()=>base.camera.__isUserInteracting = false, 500); trying to avoid that, when dragging and ending over a hotspot, the hotspot is selected.
        }

        // Additional onMouseup Watch events. Used for drag and drop. (at the moment we dont have any, so this code is ont used in this project.)
        base.registeredOnUpEvents.forEach( theFunction => 
            theFunction(event, base)
        );

    // // start animation from - to
    // const intersectObject = checkIntersection(event, base, base.scene, 'world-sphere');
    // animatedLookAt( intersectObject.point )

    }

    // on wheel zoom
    export function onZoomControl( event, bb ) {
        var fovMAX = 40;
        var fovMIN = 5;

        bb.camera.fov -= event.deltaY * 0.05;
        bb.camera.fov = Math.max( Math.min( bb.camera.fov, fovMAX ), fovMIN );
        bb.camera.updateProjectionMatrix();
    // Base.camera.projectionMatrix = new THREE.Matrix4().makePerspective(Base.camera.fov, window.innerWidth / window.innerHeight, Base.camera.near, Base.camera.far);
    }

    /** WHAT: clicking on the Pano: selects a hotspot  
     * HOW: use the 'currentMouseIntersection' (which is continously calculated) to know if
     *      there is a hotspot being selected (that ht threejs object will have a callback after clicking.)
    */
    export function mouseClickHandler(event, base) {
        if (!base) return;
        // console.log('TODELETE EVENT click', event);

        // Registered onclick event to the Object on the mouse.
        if (base.camera.currentMouseIntersection) {

            // testing TODELETE. if hovering an object, return coordenates
            if (base.camera.currentMouseIntersection?.object.children)
                base.camera.currentMouseIntersection?.object.children.forEach(ob => {
                    console.log('%c Object data. position', 'color:lightblue');
                    const {x,y,z} =ob.position;
                    console.log(`${x},${y},${z}`);
                })

            const clickedObj = base.camera.currentMouseIntersection.object;
            devdel('clicked!', clickedObj.name)
            if (clickedObj._onClickCallback) {
                // alert('clicked');
                clickedObj._onClickCallback(event, base, clickedObj);
            }
        }
    }


    
    /**
    * 
    * @param {THREE.Vector3 or {lng:?, lat:?}} vectorTarget | object
    */
    export function animatedLookAt( theCamera, vectorTarget, fovOnComplete = null ) {
        // start animation from - to
        let start_animation = { lng: theCamera.lng, lat: theCamera.lat }; 
        let end_animation;
        if (vectorTarget?.x) {        
            end_animation   = {   
                            lng: lngFromPosition(vectorTarget),
                            lat: latFromPosition(vectorTarget) 
                        }
        } else if (vectorTarget?.lat) {
            end_animation   = { lng: vectorTarget.lng, lat: vectorTarget.lat }
        }
        if (! end_animation ) { 
            dev2('animationLookat will not rotate camera', end_animation, start_animation); 
            end_animation = start_animation; // there will not be animation
        }
        // fixtures: to avoid that it turns around 300 deg when the spot is just 10 deg on the left
        if (Math.abs(end_animation.lng - start_animation.lng) > 180) {
            end_animation.lng -= 360; 
        }

        const animationLookAt   = new TWEEN.Tween(start_animation).to(end_animation, 400);
        animationLookAt.onUpdate( () => {
            // this is executed on every frame render(). In the call TWEEN.update();
            // theCamera.lookAt( start_animation.x,start_animation.y,start_animation.z )
            theCamera.lng = start_animation.lng;
            theCamera.lat = start_animation.lat;
        }).onComplete( ()=> { 
            // make zoom after the animation of lookat
            if (fovOnComplete)
                (new TWEEN.Tween( theCamera ).to( { 
                        fov: Math.min(44.4, Math.max(9.1, fovOnComplete)) }, 1000))
                    .easing(TWEEN.Easing.Quadratic.Out)
                    .onUpdate(() =>  theCamera.updateProjectionMatrix())
                    .start();
        
        }).start();

    }