import React from 'react'

// subcomps.
import ParsedDescription from '../../../../LayoutComponents/ParsedDescription';

export default function MapTextLeft( {
                                    theTextDescription,
                                    selectedHotspot, setSelectedHotspot,
                                    hoveredHotspot, hotspots,
                                    // setPano360Selected, // only for all balconies google map
                                    webparams, setWebparams // we use webparams to set the Explore 180 panorama
                                    } ) {

    if (!theTextDescription) return null;

    return ( 
        <div className={`GoogleMapo col-12 col-md-4 lead GoogleMap__description 
                        ${(typeof selectedHotspot === 'number'? 'GoogleMap__description--hotspot-selected' : '')}
                        ${(typeof hoveredHotspot === 'number'? 'GoogleMap__description--hotspot-hovered' : '')}
                        `}>
            <div className="card">
                { typeof selectedHotspot === 'number' && hotspots[selectedHotspot]?.in_pano?.panorama_image_360 ?
                    // header for balcony selected
                    <div className="card-header d-flex">
                        <div className="cosa">
                            {/* <button className="btn btn-info" onClick={e=> {
                                setPano360Selected(hotspots[selectedHotspot].in_pano)}
                                }>
                                <img src={hotspots[selectedHotspot].in_pano.panorama_image_360.sizes.thumbnail} />
                                <span> Open Panorama 360°</span>
                            </button> */}
                            {/* { hotspots[selectedHotspot].explore && 
                                <button className="btn btn-info" 
                                        onClick={e=> setWebparams(Object.assign({...webparams}, {exploreLanscapeSelected: hotspots[selectedHotspot].explore})) }>
                                <img src={hotspots[selectedHotspot].in_pano.panorama_image_360.sizes.thumbnail} />
                                <span> Open Landscale 180°</span>
                            </button>} */}
                        </div>

                        {/* <button onClick={ e => {
                                    setSelectedHotspot(null);
                                    if (setPano360Selected) setPano360Selected(null);
                                } } >
                            Close
                        </button>     */}
                    </div>
                : (typeof selectedHotspot === 'number' ?
                    // header for hotspot in component google map selected
                <div className="card-header d-flex">
                    <button onClick={ e => setSelectedHotspot(null) } >
                        Close
                    </button>
                </div>
                :null) }
                <div className={`card-body ${ 
                         webparams.description_layout?.includes('first-letter-large')? 'first-letter-highlighted' : ''  }`}>

                    {/* When the hotspot corresponds to a Balcone: show links to 360 */}


                    <ParsedDescription  
                        description={ theTextDescription } 
                                webparams={webparams} setWebparams={setWebparams} />
                </div>
            </div>
        </div>
    )
}