import React from 'react'
import MapPage from '../MapPage';
import "./Component.scss";

import './SingleComponent/CompContentAndGallery';
import CompContentAndGallery from './SingleComponent/CompContentAndGallery';
import CompLeafletOrGoogleMap from './SingleComponent/CompGoogleMap';
import CompPanorama from './SingleComponent/CompPanorama';
import Separator from './SingleComponent/CompSeparator';

export default function Component( { componentInfo, index, webparams, setWebparams }) {

    if (!componentInfo) return null;

    let jsx = null;
    if (componentInfo['acf_fc_layout'] === 'content_gallery') {
        jsx  =  <CompContentAndGallery 
                        componentInfo={componentInfo}
                        index={index}
                        webparams={webparams} setWebparams={setWebparams}
                />
    } else if (componentInfo['acf_fc_layout'] === 'map' && componentInfo['use_balconies_as_hotspots'] ) {
        jsx  =  <MapPage componentInfo={componentInfo} index={index}
                        webparams={webparams} setWebparams={setWebparams} />
    } else if (componentInfo['acf_fc_layout'] === 'map') {
        jsx  =  <CompLeafletOrGoogleMap
                        componentInfo={componentInfo}
                        index={index}
                        webparams={webparams} setWebparams={setWebparams}
        />
    } else if (componentInfo['acf_fc_layout'] === 'panorama') {
        jsx  =  <CompPanorama
                        componentInfo={componentInfo}
                        index={index}
                        webparams={webparams} setWebparams={setWebparams}
        />
    } else if (componentInfo['acf_fc_layout'] === 'separator') {
        jsx  =  <Separator webparams={webparams} />
    }

    return (
        <div className={`Component Component__${componentInfo.acf_fc_layout} container`}>
            { jsx }
        </div>
    )
}