
import React from 'react'
import { isMobile } from 'react-device-detect';

import { updateQueryStringParameter, isDebug } from '../../../helpers'; // TOREMOVEIFMODULE
import CircleButton from '../../../LayoutComponents/CircleButton'; // TOREMOVEIFMODULE
import './CompExplore.scss';
import ExploreDebugTools from './explore-subcomps/ExploreDebugTools';
import MarkerLi from './explore-subcomps/MarkerLi';
import MarkerQuadro from './explore-subcomps/MarkerQuadro';

import { useGesture } from 'react-use-gesture';
import { _t } from '../../../translations';
/**
 * TODO: convert to npm module
 * when selecting a hotspot , target the hotspot and zoom a little
 * @param {*} param0 
 * @returns 
 */
export default function CompExplore({ title, fullImage, markers, options }) {

    // REFERENCES
    const containerRef = React.useRef();
    const containerPosition = React.useRef(); // inner container
    const imgRef = React.useRef();
    const scaleRef = React.useRef();

    // STATES
    const [instance, setInstance] = React.useState(null); // info about the image. Width, boundaries ... should be updated on every resize window
    const [snapShot, setSnapShot] = React.useState({}); // when start dragging, info about boundaries, offset,updated on any change on position, zoom, resize.
    const [zoom, setZoom] = React.useState(1);  // 1 is default, more is amplifying
    const [dragging, setDragging] = React.useState(false); // {clickedX: 0, clickedY: 0} saves initial pos of mouse.
    const [position, setPosition] = React.useState([0,0]); // displacement X,Y of the painting. (considering 0,0 the initial setup of the img (i think centered))
    const [isUsingWheel, setIsUsingWheel] = React.useState(null); // assign to a timeout. Zooming moves all the window. When starting zooming, we set the overflow to hidden.
    const [originalBodyOverflow, setOriginalBodyOverflow] = React.useState(null); // to restablish the overflow to body when finishing zooming
    const [toggleSelectedHoverOn, setToggleSelectedHoverOn] = React.useState(false); // on means "we want to see the hover image instead of the selected". This option appears only when marker is selected, of course.

    // when clickng or hovering the hotspot
    const [selectedMarker, setSelectedMarker] = React.useState(false);
    const [hoveredMarker, setHoveredMarker] = React.useState(false);

    // const [crop, setPosition] = React.useState([0,0]);
    // mobile touch gestures to drag and zoom image
    useGesture({
        // onDragStart: (e) => {
        //     console.log("drag start", { });
        //     // mouseDownHandler( e.event );
        //     //setDragging( Object.assign(e.event, { clickedX: , clickedY: } ) );
        // },
        // onDrag: ( e ) => {
        //     // const offset = {dx: e.offset[0], dy: e.offset[1]};
        //     //debugger
        //     const newPos = [ parseInt(e.offset[0])*10, parseInt(e.offset[1])*10];
        //     console.log("is dragging ", e.offset, newPos);
        //     setPosition(newPos);
        //     //setPosition([1000,1000]);
        //     // mouseMoveHandler(e.event);
        // },
        onPinch: ( e ) => {
            console.log('pinching', e);
            let newZoom = e.offset[0]/50;
            if (newZoom < instance.minZoom/2) newZoom = instance.minZoom/2;
            setZoom(newZoom);
            takeSnapShot();
        }
    }, {
        domTarget: containerRef,
        eventOptions: { passive: false }
    })



    const isDebugging = isDebug? isDebug() : false; // isDebug() is a function defined outside this component.

    // const
    const wheelSensibility = 0.05;
    const dragSensibility = 5;
    const initialFit = 'fit-cover'; // fit-cover || fit-contain
    
    const theOptions = Object.assign( {
        showMarkersList: options.showMarkersList? true : false, // show the list on the left with the marker names.
        markersRight: options.markersRight? true : false,
        reactToMouseOnShape: options.reactToMouseOnShape? true : false, // when hovering the stick or name, it react as hovered. With this, it reacts also when hovering the rectangle.
    }, options );

    // on MOUNT
    React.useEffect( ()=>{
        if (!isMobile)
            window.addEventListener('resize', e=>initInstance());
    });
    function initInstance() {
        if (!imgRef.current) return {};
        //alert('init Instance')
        var newInstance = { ...instance };
        newInstance.imgWidth    = imgRef.current.width;
        newInstance.imgHeight   = imgRef.current.height;
        newInstance.imgRatio    = imgRef.current.width/imgRef.current.height;
        const windowRatio       = containerRef.current.offsetWidth/containerRef.current.offsetHeight;
        var newZoom             = null;

        if (initialFit === 'fit-contain') {
            // set the zoom to fit the scale as "fit-contain"
            if (windowRatio/newInstance.imgRatio > 1) {
                newInstance.fitDimension = 'height';
                newZoom = containerRef.current.offsetHeight/newInstance.imgHeight;
            } else {
                newInstance.fitDimension = 'width';
                newZoom = containerRef.current.offsetWidth/newInstance.imgWidth;
            }
        } else {
            // set the zoom to fit the scale as "fit-cover" (there will be some part of the img hidden initally, but not blank spaces)
            if (windowRatio/newInstance.imgRatio > 1) { // the image is more vertical than the container
                newInstance.fitDimension = 'width';
                newZoom = containerRef.current.offsetWidth/newInstance.imgWidth;
            } else { // the image is very landscape, more than the container (normally this one, for panoramic images)
                newInstance.fitDimension = 'height';
                newZoom = containerRef.current.offsetHeight/newInstance.imgHeight;
            }
        }
        newInstance.minZoom = newZoom;
        setZoom(newZoom);
        setInstance(newInstance);
        takeSnapShot();
    }


    /****** HANDLERS (zoom, pan ) ************/
    // zoom
    function wheelHandler(e) {
        
        
        if (!instance) return;
        if (!isUsingWheel) 
            setOriginalBodyOverflow(document.body.style.overflow);

        document.body.style.overflow = 'hidden';
        let newZoom = 1;
        if (e.deltaY) // for desktop wheel
            newZoom = zoom + (zoom * e.deltaY / 5) * wheelSensibility;
        else if (e.scale) 
            newZoom = e.scale;
        // restrict no more than this
        if (newZoom < instance.minZoom) newZoom = instance.minZoom;
        setZoom(newZoom);
        takeSnapShot();
        
        if (isUsingWheel) {
            clearTimeout(isUsingWheel);
        }
        setIsUsingWheel( setTimeout( () => document.body.style.overflow = originalBodyOverflow, 500 ) ); 
    }

    // pan on drag
    function mouseDownHandler(e) {
        // containerRef.current.style.cursor = 'grabbing';
        
        let clickedX;
        let clickedY;
        if ( e.touches ) {
            clickedX = e.touches[0].clientX;
            clickedY = e.touches[0].clientY;
        } else  { // desktop
            clickedX = e.clientX;
            clickedY = e.clientY;
        }
        // dev1('setting initla xy', clickedX, clickedY);
        setDragging({ clickedX, clickedY }); // save initial pos of mouse.
    }
    function mouseMoveHandler(e) {
        
        let clickedX = e.clientX;;
        let clickedY = e.clientY;;
        if ( e.touches ) { // mobile tap
            clickedX = e.touches[0].clientX;
            clickedY = e.touches[0].clientY;
        } 
        if (dragging) {
           
            // THE REAL CALCULATION OF POSITION X and Y of the PICTUR after dragging
            const deltaX = clickedX - dragging.clickedX;
            const deltaY = clickedY - dragging.clickedY;
            const directionXRight   = deltaX > 0 // moving the mouse left, dragging right.
            const directionYDown    = deltaY > 0 // direction of the painting
            
            
//            const maxOffsetR = snapShot.outsideRight/zoom; // boundary right limit

            let offsetX  = deltaX*dragSensibility; // px that we move the painting
            let offsetY  = deltaY*dragSensibility;
//            document.querySelector('#m').textContent = `${offsetY} max ${minOffsetY.toFixed()}`


            if (directionXRight) 
                offsetX = Math.min(offsetX, snapShot.outsideLeft/zoom);            
            else 
                offsetX = Math.max(offsetX, -snapShot.outsideRight/zoom);
            if (directionYDown) 
                offsetY = Math.min(offsetY, snapShot.outsideTop/zoom);
            else 
                offsetY = Math.max(offsetY, -snapShot.outsideBottom/zoom);
            
            // if the painting doesnt overflows left nor right, set the x position to 0. Same with y.
            if (snapShot.outsideRight<=0 && snapShot.outsideLeft<=0) offsetX = -snapShot.initialPosition[0];
            if (snapShot.outsideTop<=0 && snapShot.outsideBottom<=0) offsetY = -snapShot.initialPosition[1];

            if (snapShot.initialPosition) {
                setPosition([ snapShot.initialPosition[0] + offsetX, 
                    snapShot.initialPosition[1] + offsetY ]);
            }
                
            
           
        }
    }
    
    function mouseUpHandler(e) {
        setDragging(false);
        takeSnapShot()
    }


    // FUNCTIONS

    // update info about the situation. Used on zoom and resizing and finishing drag.
    function takeSnapShot(){ 
        const containerBoundaries = containerRef.current.getBoundingClientRect(); 
        const paintingBoundaries  = containerPosition.current.getBoundingClientRect();
        const theSpanShot         =
                    { 
                      initialPosition: [...position],               // [x,y] pos of the quadro (respect top left matching top left of container.)
                      boundaryTop: containerBoundaries.top,        // save the limits where the image position can move on.
                      boundaryBottom: containerBoundaries.bottom,
                      boundaryLeft: containerBoundaries.left,
                      boundaryRight: containerBoundaries.right,
                      // now the pixels that the painting are not shown in the screen
                      outsideTop: containerBoundaries.top - paintingBoundaries.top,
                      outsideBottom: -containerBoundaries.bottom + paintingBoundaries.bottom,
                      outsideLeft: containerBoundaries.left - paintingBoundaries.left,
                      outsideRight: -containerBoundaries.right + paintingBoundaries.right,
                    };
        setSnapShot(theSpanShot);

        // correction of position on zoom out, to avoid leaving empty paddings when we should see more image.
        var newPosX = position[0];
        var newPosY = position[1];
        if (theSpanShot.outsideLeft > 0 && theSpanShot.outsideRight <= 0) {
            newPosX = position[0] - theSpanShot.outsideRight/zoom; 
        }
        if (theSpanShot.outsideRight > 0 && theSpanShot.outsideLeft <= 0) {
            newPosX = position[0] + theSpanShot.outsideLeft/zoom; 
        }
        if (theSpanShot.outsideTop > 0 && theSpanShot.outsideBottom <= 0) {
            newPosY = position[1] - theSpanShot.outsideBottom/zoom; 
        }
        if (theSpanShot.outsideBottom > 0 && theSpanShot.outsideTop <= 0) {
            newPosY = position[1] + theSpanShot.outsideTop/zoom; 
        }

        // correct the position, centering, if there is margin in both boundaries.
        if (theSpanShot.outsideLeft <= 0 && theSpanShot.outsideRight <= 0) 
            newPosX = 0;
        if (theSpanShot.outsideTop <= 0 && theSpanShot.outsideBottom <= 0) 
            newPosY = 0;
        if (newPosY || newPosX)
            setPosition([ newPosX, newPosY ] )
    }

    // WATCH and CUSTON HOOK, listen to 'Escape' when a marker is selected
    React.useEffect(() => {
        // creating hoof for escape
        function onKeyup(e) {
            if (e.key === 'Escape') 
                setSelectedMarker(null);
        }
        if (typeof selectedMarker === 'number')
            window.addEventListener('keyup', onKeyup);
        else window.removeEventListener('keyup', onKeyup);

        // other actions
        if (typeof selectedMarker === 'number') {
            setToggleSelectedHoverOn(false);
        }
        // selected marker
        // return () => window.removeEventListener('keyup', onKeyup);
      }, [selectedMarker]);

    React.useEffect( () => {
        if (toggleSelectedHoverOn) {
            
        }
    },[toggleSelectedHoverOn]);


    // THIS IS JUST for debugging
    const infoClickHandler = e => {
        // position respect the containerPosition
        if (!isDebugging) // TODELETE if I want to create the nmp package 
            return;
        const containerBoundaries = containerRef.current.getBoundingClientRect();
        const imgBoundaries = containerPosition.current.getBoundingClientRect();
        const [canvasLeft, canvasTop] = [ e.clientX - containerBoundaries.left, e.clientY - containerBoundaries.top];
        const [imgLeft, imgTop] = [ e.clientX - imgBoundaries.left, e.clientY - imgBoundaries.top];
        console.log('canvas Left Top', canvasLeft, canvasTop);
        console.log('img Left Top', imgLeft, imgTop);
        // console.log('img boundaries', imgBoundaries);
        const [percentLeft, percentTop] = [ imgLeft * 100 / imgBoundaries.width, imgTop * 100 / imgBoundaries.height ];
        const debugElPos = document.getElementById('Explore__last-click-pos');
        if (debugElPos) debugElPos.value = percentLeft.toFixed(1) +', '+percentTop.toFixed(1);

        const lastC = [ Number(localStorage.getItem('lastClickPercentLeft')), Number(localStorage.getItem('lastClickPercentTop')) ];
        if (lastC) {
            const delta = [ (percentLeft - lastC[0]).toFixed(2), (percentTop - lastC[1]).toFixed(2)];
            const debugElArea = document.getElementById('Explore__last-click-area');
            if (debugElArea) debugElArea.value = Math.abs(delta[0]) +', '+Math.abs(delta[1]);
        }
        localStorage.setItem('lastClickPercentLeft', percentLeft.toFixed(2));
        localStorage.setItem('lastClickPercentTop', percentTop.toFixed(2));
        // percentages

        // console.log('container', containerBoundaries.left, containerBoundaries.width);
        // console.log('img', imgBoundaries.left, imgBoundaries.width);
        // console.log('mouse', e.clientX, e.clientY);
    }

    // helper: to use the title and description formatted as HTML inside a div.
    const formattedHTML = () => {
        const markerParams = markers[selectedMarker];
        if (!markerParams) return null;

        const descriptionHtml = markerParams.description? `<div class="description text-center">${markerParams.description}</div>`: '';

        return { __html: descriptionHtml };
    }

    /** --------- TEMPLATE JSX --------- */
    return (
        <div className={`Explore__supercontainer`}
            >

            { title && <div className="Explore__title"><h2>{ title } </h2></div>}

            { (<div ref={ containerRef } 
                    className={`Explore__image-cropper-wrapper `}

                    // behaviour for desktop
                    onWheel={ isMobile? null : wheelHandler }
                    onDragStart={ isMobile? null : mouseDownHandler}
                    onDragOver={ isMobile? null : mouseMoveHandler}
                    onDragEnd={ isMobile? null : mouseUpHandler}
                    // behaviour for mobile. Zoom with useGest
                    onTouchStart={ isMobile? mouseDownHandler : null }
                    onTouchMove={ isMobile? mouseMoveHandler : null }
                    onTouchEnd={ isMobile? mouseUpHandler : null }

                    onClick={ isDebugging? infoClickHandler : null}
            >
                <div    className="Explore__inner-zoom" ref={scaleRef}
                    style={ { transform: `scale(${zoom})` } }>

                    <div className="Explore__inner-position-draggable"
                        ref={ containerPosition }
                        style={ { transform: `translate(${position[0]}px, ${position[1]}px)`}}
                        >

                        <img    src={fullImage} className="Explore__image"
                                alt={ 'panorama' }
                                ref={imgRef}
                                onDragStart={e=>{ // to aboid native behavious of showint the thumb o fiage when dragging
                                    var img = document.getElementById('ghost-img');
                                    e.dataTransfer.setDragImage(img, 0, 0);
                                }}
                                onLoad={ (e) => initInstance() } 
                                
                        />
                        { markers && markers.length && markers.map((markerParams,i) =>
                            markerParams.marker_options.includes('disabled') ? null : 
                            <MarkerQuadro   scale={1/zoom}
                                            markerParams={markerParams}
                                            selectedMarker={selectedMarker}
                                            setSelectedMarker={setSelectedMarker}
                                            hoveredMarker={hoveredMarker}
                                            setHoveredMarker={setHoveredMarker}
                                            toggleSelectedHoverOn={toggleSelectedHoverOn}
                                            setToggleSelectedHoverOn={setToggleSelectedHoverOn}
                                            markerKey={i}
                                            options={theOptions}
                                            key={`marker-${i}`} >
                            </MarkerQuadro>
                        )}
                    </div>
                </div>

                { theOptions?.showMarkersList && 
                <div className={`Explore__markers-list-wrapper Explore__markers-list-wrapper--${theOptions?.markersRight? 'right':'left'}`}>
                    <ul>
                        { markers && markers.length && markers.map((markerParams,i) =>
                            markerParams.marker_options.includes('disabled') ? null : 
                            <MarkerLi markerParams={markerParams}
                            selectedMarker={selectedMarker}
                            setSelectedMarker={setSelectedMarker}
                            hoveredMarker={hoveredMarker}
                            setHoveredMarker={setHoveredMarker}
                            markerKey={i}
                            key={`marker-${i}`} />
                        ) }
                    </ul>
                </div> 
                }

            </div>) }
            
            <div className={`Explore__description-wrapper ${ 
                        typeof selectedMarker === 'number' && 
                            (markers[selectedMarker]?.description || markers[selectedMarker]?.page_link) ? 'show-description': ''} ${
                            markers[selectedMarker]?.position_description
                        }`}
                    >
                    <button className="Explore__description-close-btn" onClick={e=>setSelectedMarker(null)}>
                        <span>╳</span>
                    </button>
                    
                    { markers[selectedMarker]?.page_link &&  // TOREMOVEIFMODULE
                    <div className="Explore__description-footer text-center">
                        <a href={ updateQueryStringParameter( markers[selectedMarker].page_link,  // this query string is a way to be able to put the back button when visitng the Tour page
                                                        'back', window.location.href) } 
                            className="m-auto">
                            <CircleButton
                                classes='btn-primary position-relative btn-primary'
                                iconClass='bi-bi-arrow-left-circle' textHelp={_t('page/pagina')} textInside={ _t('tour') }
                            />
                        </a>
                    </div>
                    }
                    <h2 className="text-center">{markers[selectedMarker]?.title}</h2>
                    <div className="Explore__description-inner" 
                         dangerouslySetInnerHTML={ formattedHTML() } />
                    
            </div>

            {/* This is to avoid the ghost image when dragging (default by browser) */}
            <img id="ghost-img" style={{width:'1px', height:'1px'}} src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="-"/>

            { isDebug() &&
            <ExploreDebugTools 
                dragging={dragging} snapShot={snapShot} position={position} zoom={zoom} 
                selectedMarker={selectedMarker} />
            }
            

            
        </div>
                
    )
}