import React from 'react'

import TopBar from '../LayoutComponents/TopBar';
import PrevNextButtons from '../LayoutComponents/PrevNextButtons';
import Popup from '../LayoutComponents/Popup';
import Component from './Components/Component';
/**
 * WHERE: caller <PageWrapper>
 * Logic: sets up webparams for the children, which are components inside a page.
 * Shows VIDEO and below the index for all the site navigation.
 * @param { p.index } p : needs the p.index to show the full index
 * @param { p.webparams } p : webparams.lang gives the current lang
 * @param { p.currentPage } p : .post_title , ... .options.
 */
export default function TourPage( p ) {
    /** STATES     */
    const [hierarchyPages, setHierarchyPages] = React.useState([]);
    
    /** ON MOUNT:
     *      
     */
    React.useEffect(()=>{
        const components = p.currentPage?.components;
        if (!components || !components.length) return;
        if ( components[0]['acf_fc_layout'] && components[0]['use_balconies_as_hotspots'] ) {
            // if the 1st component is the map with balconies, we highlight the top 'Map' btn.
            p.setWebparams( Object.assign( {...p.webparams}, { isMapPage: true } ) );
        }
        // we set the hierarchy of pages, and the list of pages of this level of ierarchy.
        
    },[p.currentPage]);

    /** FUNCTIONS HELPERS */
    function ShowComponent( componentI, ind) {
        if (!componentI) return <div className=" w-100 h-100vh d-flex justify-content-center align-items-center"><div className="spinner-border text-primary h1" 
                                     role="status">
                                        <span className="sr-only">Loading...</span>
                                </div> </div>
        return <Component  componentInfo={componentI}
                    index={ind} key={`component-${ind}`}
                    webparams={p.webparams} setWebparams={p.setWebparams}  />
    }


    return (
        <main role="main" className={`TourPage page-${p.currentPage?.post_name} ${p.currentPage?.options?.logo_type?? ''}`}>
            <div className={p.currentPage?.options?.first_component_100_screen? "page-fullscreennooo-wedontuseit" : 'nooo'}>
                <TopBar webparams={p.webparams} setWebparams={p.setWebparams}
                    allPages={p.allPages} currentPage={p.currentPage} ></TopBar>

                    <PrevNextButtons allPages={p.allPages} currentPage={p.currentPage} />


                {/* MaybeI wnat to show the 1st component at full screen, so it's inside this container, the rest are in a list */}
                {
                  //  ShowComponent( (p.currentPage?.components? p.currentPage.components[0]: null), 0) 
                }
                
                
            </div>
            
                <article>

            
            {
                ( p.currentPage?.components?.length > 0)?
                    p.currentPage.components.map( (componentInfo, compIndex) => {
                        return compIndex > -1? 
                            ShowComponent( componentInfo, compIndex+1) 
                            : null
                    }                    
                ) : null
            }
                </article>
           


                <Popup webparams={p.webparams} setWebparams={p.setWebparams} />
        </main>
    )
}