import React from 'react'

import ReactPlayer from 'react-player';

/**
 * TODO: pasar los params para setup loop, autoplay, controls...
 * @param {object} videoInfo 
 *      .video_type ( upload|url )
 *      .video_url ( string url, if video_type === 'url') 
 *      .video_upload ( string url,  if video_type === 'upload')
 *      
 */
export default function Video( { videoInfo, webparams } ) {

    const videoParams = Object.assign( {
            className: '',
            playing: true
        },
        videoInfo );

    // checking for validation
    if (videoInfo.video_url && !videoInfo.video_upload) {
        videoInfo.video_upload = videoInfo.video_url;
    }

    if (videoInfo.video_type === 'upload') 
    return <ReactPlayer 
                url={ videoInfo.video_upload } 
                playing={videoParams.playing}
                loop={true}
                controls
                className={ 'video ' + videoInfo.className?? '' }
            />
    
    // if (videoInfo.video_type === 'embed') 
       //  return <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
    
    if (videoInfo.video_type === 'url') 
        return <ReactPlayer 
                    url={ videoInfo.video_upload } 
                    playing={videoParams.playing}
                    controls
                    // muted
                    loop={false}
                    className={ 'video ' + videoInfo.className?? '' }
               />

    if (videoInfo.video_type === 'custom') 
        return <video  className={ 'video ' + videoInfo.className?? '' } 
                autoPlay 
                muted={false}
                poster={videoInfo.poster_url?? ''} 
                ref={videoInfo.videoRef} 
                onClick={ videoInfo.handleVideoPaused }>
            
            {/* <source src="http://localhost/balconi/balconiapp/wordpress/wp-content/uploads/2021/02/Intro-balconi-v1.mp4" type="video/mp4"/> */}
            <source src={videoInfo.video_upload} type="video/mp4"/>
            Your browser does not support the video tag.
        </video>
}
