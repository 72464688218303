import React from 'react'

export default function MarkerQuadro({
        markerKey,
        scale,markerParams,selectedMarker,setSelectedMarker,hoveredMarker,setHoveredMarker,
        setToggleSelectedHoverOn, toggleSelectedHoverOn,
        options
    }) {

    // the letter
    const letterABC = ('ABCDEFGHIJKLMNOPQRSTUWXYZ')[markerKey];
    let style_letter = {
        fontSize: ( markerParams.letter_size?? '1' ) + 'rem',
        left: ( markerParams.letter_size?? '1' ) + 'rem'
    }
    let style_circle = {
        padding: ( markerParams.letter_size?? '1' ) + 'rem'
    }
    let style_stick = {
        height: ( markerParams.stick_size?? '25' ) + 'px'
    }
    // now the rectangle and pin: This is the position for both pin and rectangle
    let style_marker = {  
        left: markerParams.position[0] + ((markerParams.position[0].indexOf('%') === false)? '%':''),  // accepts either '24' and '24%'
        top: markerParams.position[1] + ((markerParams.position[1].indexOf('%') === false)? '%':''),
    }
    // let style_pin = { ... style_marker };
    let style_pin = Object.assign( {...style_marker}, { 
                        transform: (markerParams.pin_position !== 'bottom')? `translateY(-100%)` : '', // `scale(${scale})
                        marginLeft: markerParams.offset_left_stick? markerParams.offset_left_stick + '%' : '30px',
                        marginTop: markerParams.offset_top_stick? markerParams.offset_top_stick + '%' : '-5px',
                    } ) ;
    style_marker = Object.assign(style_marker, {
        width: markerParams.size[0]? markerParams.size[0] + ((markerParams.size[0].indexOf('%') === false)? '%':'') : 'auto',
        height: markerParams.size[1]? markerParams.size[1] + ((markerParams.size[1].indexOf('%') === false)? '%':'') : 'auto',
        ...((markerParams.marker_options.includes('hideShapeBorder') && 
             !markerParams.marker_options.includes('forceAllowHoverOnShape')) 
            && {display: 'none'}) // we need to eliminate the intreractivity or we cant drag the img
    }) ;
    let style_selected_img = {};
    if (markerParams.extra_size_selected) // the selected image might be bigger in % than the default and hover
         style_selected_img = {
            position: 'absolute',
            width: markerParams.extra_size_selected[0],
            height: markerParams.extra_size_selected[1],
            transform: `translate( ${(-1*(parseFloat(markerParams.extra_size_selected[0])-100)/4)}%, ${(-1*(parseFloat(markerParams.extra_size_selected[1])-100)/4)}% )` // `translate(-50%,-50%)`, // 
         }
    
    
    if ( markerParams.pin_position === 'bottom')
        style_pin.top = `calc( ${markerParams.position[1] + ((markerParams.position[1].indexOf('%') === false)? '%':'')} + ${markerParams.size[1]? markerParams.size[1] + ((markerParams.size[1].indexOf('%') === false)? '%':'') : ''} )`;
    

    // COMPUTED classNames: the class selected or hovered
    const markerClassByMouseComputed = React.useMemo(() => {
        let markerClassByMouse = (markerKey === selectedMarker)? ` marker-selected ` : '';
        markerClassByMouse += (markerKey !== selectedMarker && markerKey === hoveredMarker )? ` marker-hovered ` : '';
        markerClassByMouse += (markerKey === selectedMarker && markerKey === hoveredMarker )? ` marker-selected-hovered ` : '';
        markerClassByMouse += (markerKey !== selectedMarker && markerKey !== hoveredMarker )? ` marker-default ` : '';

        markerClassByMouse += (markerKey === selectedMarker && toggleSelectedHoverOn)? ' marker-toggle-hover-img ' : '';
        markerClassByMouse += ' '+markerParams.marker_options.join(' '); // useful the option 'hideShapeBorder'
        markerClassByMouse += ' pin-position-'+markerParams.pin_position;

        return markerClassByMouse;
    }, [selectedMarker, hoveredMarker, toggleSelectedHoverOn]);

    // computed
    const reactToHoverOnShape = React.useMemo(() => { 
        return options.reactToMouseOnShape || markerParams.marker_options?.includes('forceAllowHoverOnShape');
    }, [options, markerParams]);
    const reactToClickOnShape = React.useMemo(() => { 
        if ( options.reactToMouseOnShape ) return true; // if generic option, reacts always to click
        if ( markerKey !== selectedMarker && markerParams.marker_options?.includes('forceAllowClickOnUnselectedShape') ) return true;
        if ( markerKey === selectedMarker && markerParams.marker_options?.includes('forceAllowClickOnSelectedShape')) return true;  // if selected and particular optin set to true, accept clicking on it to swap between hover and selected img.
    }, [options, markerParams]);
        
    const stick_jsx = <div  className={`Explore__marker-pin--stick`} 
                            style={ style_stick }></div>;
    return (<>
        <div    className={`Explore__marker-pin ${markerClassByMouseComputed}`}     
                style={ style_pin }
             onMouseEnter={ e => setHoveredMarker(markerKey) }
             onMouseLeave={ e => setHoveredMarker(null) }
             onClick={ e => {
                 if (markerKey === selectedMarker)
                    setToggleSelectedHoverOn(!toggleSelectedHoverOn)
                 else {
                     setToggleSelectedHoverOn(false);
                     setSelectedMarker(markerKey)
                 }
            } } 
            >
            { markerParams.pin_position === 'bottom' &&
                stick_jsx
            }
            <div className="Explore__marker-pin--letter" style={ style_circle }>
                <span style={ style_letter }>{letterABC}</span>
            </div>
            { markerParams.pin_position !== 'bottom' &&
                stick_jsx
            }
            <div className="Explore__marker-pin--label" style={ style_letter } >{ markerParams?.title }</div>
        </div>

        {/* Now the actual image of the marker (default, hover and selected) */}
        <div className={`Explore__marker ${markerClassByMouseComputed}`} style={ style_marker }
            onMouseEnter={ e => reactToHoverOnShape? setHoveredMarker(markerKey) : false }
            onMouseLeave={ e => reactToHoverOnShape? setHoveredMarker(null) : false }
            onClick={ e => { 
                if (!reactToClickOnShape) return;
                if (markerKey === selectedMarker) {
                    setToggleSelectedHoverOn(!toggleSelectedHoverOn);
                }
                else {
                    setToggleSelectedHoverOn(false);
                    setSelectedMarker(markerKey)
                }
            } } 
           >
            {
                markerParams.shapes?.default_img && <div className='shape default-img'>
                    {hoveredMarker !== markerKey && selectedMarker !== markerKey &&
                    <img src={markerParams.shapes.default_img} />}
                </div>
            }
            {
                markerParams.shapes?.hover_img && <div className='shape hovered-img'>
                    {(hoveredMarker === markerKey || toggleSelectedHoverOn) &&
                    <img src={markerParams.shapes.hover_img} />}
                </div>
            }
            {
                markerParams.shapes?.selected_img && (
                <div className='shape selected-img' 
                    style={ selectedMarker === markerKey? style_selected_img : {} }>
                    {selectedMarker === markerKey &&
                    <img src={markerParams.shapes.selected_img} />}
                </div>)
            }
        </div>
        </>
    )
}

