import React from 'react'

/** the ul li with the balconies (or hotspots) */
export default function MapListOfHotspots({
    selectedHotspot, setSelectedHotspot,
    hoveredHotspot, setHoveredHotspot,
    componentInfo,
    options
}) {
    
    
    if (!componentInfo.description_layout.includes('show-list-of-hotspots')) return null;
    
    // if ( typeof selectedHotspot === 'number' || typeof hoveredHotspot === 'number' ||
    // if ( (!componentInfo.description_layout?.includes('show-list-of-hotspots')))
    //     return null;

    return (
        <div className={`GoogleMap__list-of-hotspots--wrapper  ${ 
                            typeof hoveredHotspot === 'number' ? 'is-hovering-hotspot card-info ' : '' } ${ 
                            typeof selectedHotspot === 'number' ? 'is-hotspot-selected card-success' : '' }`}>
            <ul className='list-unstyled'>
                { componentInfo.hotspots.map( (ht,i) => 
                    <li className={`the-li ${ hoveredHotspot===i && 'is-hovered' } ${ selectedHotspot===i && 'is-selected' }` } 
                        key={'marker-li'+i}
                        onClick={e=> setSelectedHotspot(i) }
                        onMouseEnter={ options.reactToHover? e=>setHoveredHotspot(i) : null  }
                        onMouseLeave={ options.reactToHover? e=>setHoveredHotspot(null) : null  }
                        >
                        {
                            ht.uptitle? <div className="card-header bg-light">
                                <h3 className='m-0 text-dark h5'>{ht.uptitle}</h3>
                            </div> : ''
                        }
                        {
                            ht.uptitle2? <div className="card-header bg-dark subsubtitle">
                                <h3 className='m-0 text-light h6'>{ht.uptitle2}</h3>
                            </div> : ''
                        }
                        <a onClick={e=> { e.preventDefault(); }} href="#"
                            className={`hotspot-item bg-white px-4 ${ hoveredHotspot === i? ' hovered ' : '' } ${ selectedHotspot === i ? ' selected ' : '' }`}>
                            <span className="GM-thumb mr-3">
                                <img className="img-lfuid" src={ ht.thumbnail?.sizes.thumbnail?? null } />
                            </span>
                            <p className="GM-title m-0">{ ht.title }</p>
                        </a>
                    </li>)
                }
            </ul>
        </div> 
    )
}

