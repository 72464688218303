import * as THREE from "three";
import TWEEN from '@tweenjs/tween.js';
import { deval } from "../../../../helpers";

/**
 * Not a React Component: it creates a plane (Poster) in the given 3D world
 * @param {object} base 
 */
export default function quadroPoster3D( { theBase, world, params, setBase }) {

    
    const attrs = Object.assign( {
        position: [500,0,0], // Object.values(theBase.camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(100)), // this normalizes but not to unitary, but to 300 long
        scale: params.sprite? 100 : 10,
        rotation: [0, 0, 0],
        sprite: false, // false,
        animatedMap: 0, // if 2 or more, the number is the set of frame sin the sprite texture
        animatedMapSpeed: 25,
        image: 'https://picsum.photos/200/300',
        alpha: null, //'http://localhost/balconi/wp-content/uploads/2021/01/alpharayas.jpg',
        name: 'the_quadro'
    }, params );


    const loader = new THREE.TextureLoader();
    const texture1 = loader.load( attrs.image , function(im) { 

        attrs.ratio = im.image.height/im.image.width;
        
        // apply alphamap
        const alphaMap = attrs.alpha? loader.load( attrs.alpha, im => null ) : null;

        let materialAttrs = {
            color: 0xffffff, 
            side: THREE.DoubleSide,
            map: texture1, 
            alphaMap: alphaMap,
            transparent: alphaMap || attrs.transparent || ( attrs.animatedMap > 1 ) || (attrs.image.slice(-3) === 'png') ? true : false,
            depthTest: alphaMap? false : true,
        };

        // create 
        var mesh;
        if (attrs.sprite) {
            var material2 = new THREE.SpriteMaterial( materialAttrs ); // turns everythign black!
            mesh = new THREE.Sprite(material2); // this works, to create a sprite instead of a mesh, but in edit mode is not selectable
            mesh.scale.set(attrs.scale, attrs.scale * attrs.ratio ,attrs.scale);
            
        } else 
        {
            const material2 = new THREE.MeshBasicMaterial( materialAttrs );
            const geometry =  new THREE.PlaneBufferGeometry( 10, 10 * attrs.ratio, 3 );
            mesh = new THREE.Mesh( geometry, material2 );            
            mesh.scale.set(attrs.scale, attrs.scale, attrs.scale);
        }
        mesh._mask = attrs.alpha; // saves the img for alhpa map.
        
        //apply position, scale, rotation
        mesh.name = attrs.name;
        mesh.position.set(...attrs.position);
        mesh.rotation.set(...attrs.rotation);

        if ( attrs.animatedMap > 1 )
            applyAnimatedMap(theBase, mesh, attrs);
        
        // useful method in base to access the quadro object
        theBase._getQuadro = () => theBase.world.children.find( ob => ob.name === attrs.name )
        
        //
        

        // Publish it!
        world.add( mesh );
        
        setBase( {... theBase }); // this will call the update of Controls
        window.scene = theBase.scene;
    });

    return attrs.name;
}

export function hideQuadro(theBas) {
    if (!theBas) return;
    if (!theBas._getQuadro) return;
    const quadroSprite = theBas._getQuadro();
    if (!quadroSprite) return;
    new TWEEN.Tween(quadroSprite.position)
                .to({x: -5000, y: 0, z: 10}, 1500).start();
    activateMaskQuadro(theBas)
}
export function hideMaskQuadro(theBas) {
    const quadroSprite = theBas._getQuadro();
    if (!quadroSprite) return;
    quadroSprite.material.alphaTest = 0; // this ensures that is in front of other planes with textures.
    quadroSprite.material.depthTest = true;
    quadroSprite.material.transparent = false;
}
export function activateMaskQuadro(theBas, maskImg) {
    const quadroSprite = theBas._getQuadro();
    if (!quadroSprite) return;
    quadroSprite.material.alphaTest = 1;
    quadroSprite.material.depthTest = false;
    quadroSprite.material.transparent = true;
}
export function showQuadro(theBas, position, scale = null) {
    
    const quadroSprite = theBas._getQuadro();
    if (!quadroSprite) return;
    
    if (scale) { // scale by respect the original ratio width/height
        const ratioScale = scale/quadroSprite.scale.x;
        quadroSprite.scale.set( quadroSprite.scale.x * ratioScale, quadroSprite.scale.y * ratioScale, quadroSprite.scale.z * ratioScale )
    }
    // quadroSprite.scale.set(scale,scale,scale);
    // quadroSprite.position.set(...position);
    new TWEEN.Tween(quadroSprite.position)
                .to({x: position[0], y: position[1], z: position[2]}, 500).start();
    // (new TWEEN.Tween( quadroSprite.position ).to( { 
    //     x: position[0], y: position[1], z: position[2] }, 400))
    // .onUpdate(() => {}).start();
    if (quadroSprite._mask)
        quadroSprite._onClickCallback = (ev, bas, clickedObj) => {
            if (bas.camera.__orbitCameraBlocked) return;
            if (clickedObj.material.transparent)
                hideMaskQuadro(bas, quadroSprite._mask);
            else {
                if (bas.camera.__orbitCameraBlocked) return;
                hideQuadro(bas);
            }
        }
    else quadroSprite._onClickCallback  = (ev, bas, clickedObj) => {
            hideQuadro(bas);
        }
    
}

// not tested. Animates an image sprite
function applyAnimatedMap(base, poster, attrs) {
    if (attrs.animatedMap > 1) attrs.ratio *= attrs.animatedMap;
    // reduce the scale of the object
    poster.scale.set(attrs.scale, attrs.scale * attrs.ratio, 3);
    // changes in the texture
    const mat = poster.material;
    mat.transparent = true;
    mat.map.renderCount = 0;
    mat.map.repeat =  { x: 1/attrs.animatedMap, y: 1 }
    // add the animation to the map
    base.addUpdateCallback( () => {
        if ((mat.map.renderCount++ % parseInt(1000/attrs.animatedMapSpeed)) === 0 )
            mat.map.offset.x = (mat.map.offset.x + 1/attrs.animatedMap) % 1
        if (mat.map.renderCount === 1000) mat.map.renderCount = 0;
    });
}

function onClickCallbackFn(obj, world) {
    console.log('CALLBACL', obj, world)
}
