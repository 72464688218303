import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import TourPage from './Pages/TourPage';
import Sigla from './Pages/Sigla';
import Footer from './LayoutComponents/Footer';
// import ComingSoon from './Pages/ComingSoon';

import { dev1, dev2, devdel, isComingSoon, isDebug } from './helpers';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { _t, __ } from './translations';
import EbookReader from './EbookReader';

/**
 * Lang setter! This instermediate component set up `webparams` 
 *  (needed along the app to know the lang, and loads the pages for the Index and TopNav index button)
 * @param {string} lang (it | en )
 * @param {string} navInfo (Sigla | TourPage)
 */
export default function PageWrapper( { lang, navInfo }) {
    
    const [webparams, setWebparams] = React.useState({ 
                                                extraClasses: '', // we use them to change style depending on state of children components
                                                cookieConsentResponse: null
                                                 });
    // load pages state
    const [hasError, setErrors]    =  useState(false);
    const [allPages, setAllPages ]       = useState([]); // allPages is the array with all the pages.
    const [currentPage, setCurrentPage ] = useState(null); // only for TourPages, not for sigla. All info about the page content, including next and prev pages.

    // const [cookieConsentResponse, setCookieConsentResponse] =  useState();

    let { pageSlug } = useParams(); // https://urlbase/it/the-page-slug > we need it for tha API call to wp
    
    if (isComingSoon()) {
        pageSlug = 'coming-soon';
        devdel('IS COMING SOON', pageSlug );
    } else {
        // dev2('PAPAPAPAAA', { dfads: 'mybrother'});
    }

    
    async function fetchPages () {

        setWebparams(Object.assign( {...webparams}, { lang } ) ); // IMPORTANT: we set here the webparams.lang used along the site.
        // API CALL for all pages in index.
        const endpoint  = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/all-pages/${lang}`;
        dev2('Pagewraper get all pages endpoint: ', endpoint);
        const res = await fetch(endpoint);
        res.json().then(res => {
                    dev2('fetch pages dio: ', res); // [ { ID:2, post_title:...}, {}, ...]
                    setAllPages(res);
                } )
                .catch(err => {
                    dev1('Error when fetch ALL pages: ', res, err);
                    setErrors(`${endpoint} >>> ${err.message}`);
                });
    };
    const fetchCurrentPage = async () => {
        const endpoint  = `${process.env.REACT_APP_WP_ENDPOINT}/bl/v1/tour-page/${pageSlug}`;
        dev2('CurrentPage endpoint: ', endpoint);
        const res       = await fetch(endpoint);        
        res.json().then(res => { 
                    dev2('currentPage result: ',res); // { post_title: ... }
                    setCurrentPage(res);
                } )
                .catch(err => {
                    devdel('Error when fetching page: ',res);
                    setErrors(err.message? err.message + '/' + endpoint : err);
                });
    }
    
    
    React.useEffect( () => {
        fetchPages(); // init pages

        // if it's a tourpage, fetch info about currentpage.
        if (navInfo === 'TourPage')
            fetchCurrentPage(); // init  the tour page

        // this helps to get lang without need to access webparams. `getLang` in helpers.js
        document.querySelector('html').setAttribute('lang', lang);
    }, []);


    // COMPOSED VARS - var that tells if the message is shown or not.
    const showCookieConsentMsg = React.useMemo( ()=>{
        const localSt = localStorage.getItem('cookieConsentResponse');
        if (localSt === '1') { // user had answered 'accept' before.
            window.activateGoogleAnalytics(false); // see index.html, activate with cookies.
            setWebparams( Object.assign({...webparams}, { cookieConsentResponse: true }) );
            //setCookieConsentResponse(true);
            return false; // if answered (0 or 1, doesnt mind)
        }
        if (localSt === '0') { // user had answered 'reject' before.
            window.activateGoogleAnalytics(true);
            setWebparams( Object.assign({...webparams}, { cookieConsentResponse: false }) );
            //setCookieConsentResponse(false);
            return false; // if answered (0 or 1, doesnt mind)
        }
        if (typeof webparams.cookieConsentResponse === 'undefined') return true;
        if (webparams.cookieConsentResponse !== null) return false;
        return true;
    }, [webparams.cookieConsentResponse]);

    React.useEffect( () => {
        if (webparams.cookieConsentResponse === true) { // clicked ACCEPT
            localStorage.setItem('cookieConsentResponse', 1);
            window.activateGoogleAnalytics(false); // with cookies
        }
        if (webparams.cookieConsentResponse === false) { // clicked REJECT
            localStorage.setItem('cookieConsentResponse', 0);
            // remove the cookies if they were already installed.
            document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
            window.activateGoogleAnalytics(true); // without cookies
        }
    }, [webparams.cookieConsentResponse]);

    

    if (!hasError) {

        let jsx = null;
        switch (navInfo) {
            case "Sigla":
                // jsx = <ComingSoon webparams={webparams} index={index} />
                jsx = <Sigla webparams={webparams} setWebparams={setWebparams} allPages={allPages} />
            break
            case "TourPage":
                jsx = <TourPage webparams={webparams} setWebparams={setWebparams}
                                    allPages={allPages}
                                    currentPage={currentPage} />
            break
            default: 
                jsx = <p>Should show 404</p>; // TODO show 404
        }

        return <HelmetProvider>
                <div className={`PageWrapper ${webparams.extraClasses?? ''}`}>
                    <Helmet>
                        <html lang={webparams?.lang?? 'it'} />
                        <title>Recolor | {currentPage?.post_title?? __( 'metatitle-text') }</title>
                        <meta name="description" content={currentPage?.options?.metadescription?? __( 'metadescription-text') } />
                    </Helmet>

                    {
                        showCookieConsentMsg && <div className='cookie-consent__wrapper'>
                            <div className="cookie-consent__inner card">
                                <div className='card-header'>
                                    <h3>Montefeltro Art Views</h3>
                                </div>
                                <div className="card-body">
                                    <p className="small">{ __('intro-cookie-consent') }</p>
                                     <p className='h4'>{ _t('Cookie consent/Accettazione cookie') }</p>
                                    { __('cookie-consent') }
                                </div>
                                <div className='card-footer'>
                                    <button className='btn-success btn mr-4' onClick={ e=> setWebparams( Object.assign({...webparams}, { cookieConsentResponse: true }) )  }>
                                        { _t('Accept/Accettare') }
                                    </button>
                                    <button className='btn-danger btn' onClick={ e=> setWebparams( Object.assign({...webparams}, { cookieConsentResponse: false }) ) }>
                                        { _t('Reject/Rifiutare') }
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    { !showCookieConsentMsg && (<button className='permanent-btn-cookie-consent-open btn btn-sm btn-info'
                            onClick={e=> {
                                    localStorage.removeItem('cookieConsentResponse');
                                    setWebparams( Object.assign({...webparams}, { cookieConsentResponse: null } ) ); // remove answer as if the user didnt answer yet
                                } }>
                        { _t('Cookie consent')} <i className={`bi ${ webparams.cookieConsentResponse? 'bi-bookmark-check bg-success' : (webparams.cookieConsentResponse === false? 'bi-bookmark-x bg-danger': 'bi-bookmark-x bg-info' )  }`}></i>
                    </button>) }


                    {jsx}

                    { webparams.ebookSelected && 
                        <EbookReader webparams={webparams} setWebparams={setWebparams} /> }


                    <Footer  webparams={webparams} />
               </div>
               </HelmetProvider>
                
    }

    if (hasError) {
        dev1('hasErrot:', hasError);
        return <pre className='text-danger'> error!: 
            {/* {Object.keys(hasError).map((er,i) => <p key={`er${i}`}>{er}, {hasError[er]}</p>)}  */}
            { hasError }
        </pre>;
    }

    // If the endpoint doesnt exist it stays always in this loading.
    return <pre>Loading Page Wrapper

        { isDebug() && <p className="card"> 
            Esto se muestra mientras carga la pagina.
            TODO: reemplazar por Loading.</p> }
    </pre>;
}