import React from 'react';
// import { getLang } from '../../../../helpers.js';


import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

//import BeforeAfterSlider from 'react-before-after-slider'
// import BeforeAfterReact from 'before-after-react'


// import * as juxtacobi from '../../../../juxtacobi.js';
// import '../../../../juxtacobi.scss';

/**
 * 
 * @param {wp media object} comparisonInfo.image_left 
 * @param {wp media object} comparisonInfo.image_right 
 */
export default function Comparison( { comparisonInfo } ) {

    const [ imgLeft, setImgLeft ] = React.useState({});
    const [ imgRight, setImgRight ] = React.useState({});
    const [ caption, setCaption ] = React.useState('');

    React.useEffect(() => {

        if (!comparisonInfo) return;

        const [img1,img2]   = [ comparisonInfo.image_left, comparisonInfo.image_right];
        if (!img1 || !img2) return;
        const [img_left,img_right]   = [ img1.sizes.medium_large, img2.sizes.medium_large ];
        // const [ww, hh] = [ img1.sizes['medium_large-height'], img1.sizes['medium_large-width']];
        const ratio     = img1.sizes['medium_large-width'] / img1.sizes['medium_large-height'];
        const ww        = document.querySelector('.ContentAndGallery__gallery')?.offsetWidth;
        const hh        = ww/ratio;

        setImgLeft({ url: img_left, w: ww, h: hh});
        setImgRight({ url: img_right, w: ww, h: hh});

        if (comparisonInfo.caption) setCaption(comparisonInfo.caption);

    //      const label_left    = getLang() === 'en' ?  img1.alt : img1.title;
    //      const label_right   = getLang() === 'en' ?  img2.alt : img2.title;
    }, [])

    if (!comparisonInfo) return null;
    return (
        <div className="Comparison" id={comparisonInfo.id? `comp-${comparisonInfo.id}` : ''}>
            {/* < BeforeAfterReact
            firstImgSrc={imgLeft.url}
            secondImgSrc={imgRight.url}
            cursor="pointer"
            containerClass="add-my-class"
            /> */}
            {/* < BeforeAfterSlider
                before={imgLeft.url}
                after={imgRight.url}
                width={imgLeft.w}
                height={imgLeft.h}
            /> */}
            <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src={imgLeft.url}  alt="Image one" />}
            itemTwo={<ReactCompareSliderImage src={imgRight.url}  alt="Image two" />}
            />

            { caption? <figcaption className='d-block'>
                {caption}
            </figcaption>: null}
        </div>
    )
}