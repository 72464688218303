import React from 'react'

import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet';
// TODO: use layer controls to change to satellite.
import L from 'leaflet';

import {isMobile} from 'react-device-detect';
import CircleButton from '../../../LayoutComponents/CircleButton';
import 'leaflet/dist/leaflet.css';
import { _t } from '../../../translations';
import MapListOfHotspots from './google-map-subcomps/MapListOfHotspots';
import ParsedDescription from '../../../LayoutComponents/ParsedDescription';
import Gallery from '../../../LayoutComponents/Gallery';
import MapButtonsTop from './google-map-subcomps/MapButtonsTop';
import useKeypressHook from '../../../useKeyPressHook';

/**
 * WHAT: call to leaflet library to display an map with interactive hotspots.
 * 
 * 
 * @param object componentInfo : object with all we need for this component, specially:
 *              Array componentInfo.hotspots, where every hotspot is an object with propierties like .coordinates.lat ... 
 * @param int index : the order of the component
 * @returns 
 */
export default function CompLeafletMap({
    componentInfo, index, webparams, setWebparams,
}) {
    
    // initial params 43.839045, 12.310384 - 43.971763, 12.400295 mobile
    const center = [ componentInfo.initial_params?.lat?? (isMobile? 43.971763 : 43.839045) ,
                     componentInfo.initial_params?.lng?? (isMobile? 12.400295 : 12.310384) ];
    const zoom  = componentInfo.initial_params?.zoom?? (isMobile? 9 : 10);
    
    // The ref, to use native leaflet methods for the map object.
    const [leafletMapRef, setLeafletMapRef ] = React.useState(null); //  we can use leafletMapRef as the object 'map' in leaflet documentation
    // Selected Hotspot .. 
    const [hoveredHotspot, setHoveredHotspot] = React.useState(null);
    const [selectedHotspot, setSelectedHotspot] = React.useState(null);


    // on MOUNT, leaflet map is created. 
    // React.useEffect(()=>{
    //     if (leafletMapRef) {
            
    //     }
    // },[leafletMapRef])

    // WATCH: add extra classes to parent so we hide buttons if there is a selected ht
    React.useEffect(()=>{         
        let extraClasses = (typeof selectedHotspot === 'number')? 
                                webparams.extraClasses + ' leaflet-hotspot-selected' :
                                webparams.extraClasses.replace(/ leaflet-hotspot-selected/g, '');
        setWebparams(Object.assign({...webparams}, { extraClasses }));
    }, [selectedHotspot]);
    
    // Hook to Esc fn   
    useKeypressHook( 'Escape', () => {
        if (!webparams.exploreLanscapeSelected && // se abbiamo un'altro component a full screen non fare caso
            !webparams.exploreQuadroSelected &&
            !webparams.pano360Selected &&
            !webparams.videoBalconeSelected) {
                if (typeof selectedHotspot === 'number') setSelectedHotspot(null);
            }
    },[selectedHotspot, webparams.exploreLanscapeSelected, webparams.pano360Selected, webparams.videoBalconeSelected]);



    if (!componentInfo) return null;

    return (
         <div id={`component-${index?? 0 }`} 
                className={`LeafletMap `}>
            
            <MapContainer center={center} zoom={zoom} 
                    whenCreated={ theMap => {
                        setLeafletMapRef(theMap); // artifially create the ref. (useref doesnt work)
                    }  } >
                
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    
                    {componentInfo.hotspots.map( (hotspot,i)=> {
                        
                        
                        return (
                            <LocationMarker hotspot = { hotspot }
                                    indexHt={i}
                                    leafletMapRef={leafletMapRef}
                                    hoveredHotspot={hoveredHotspot}
                                    setHoveredHotspot={setHoveredHotspot}
                                    selectedHotspot={selectedHotspot}
                                    setSelectedHotspot={setSelectedHotspot}
                                    key={'balc-'+i}
                            />
                        )
                    })}
                
                <LayersControl position="bottomright">
                    <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                        <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                        <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    {/* Works, but need api key
                    <LayersControl.BaseLayer name="OpenStreetMap.Landscape">
                        <TileLayer
                        attribution= 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
                        url="http://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer> */}

                    { !componentInfo.description_layout.includes('hide-down-button') && (
                    <CircleButton
                    onClickAction={ e=> window.scrollTo({ left:0, top:window.innerHeight - 50,  behavior: 'smooth'})  }
                    classes='read-more-mobile btn-primary position-absolute bottom-0 right-0 circle-btn-sm'
                    iconClass='bi-arrow-down-circle' textHelp={_t('')} textInside={ _t('Down/Giù') } />
                    ) }
                </LayersControl>

                 
            </MapContainer>
            
            {/* {featured image if selected, only for mappage, not for a component } */}
            { typeof selectedHotspot === 'number' && componentInfo.hotspots[selectedHotspot]?.image && 
                webparams.isMapPage && (
                    <div className="featured-image-right">
                        <small>{ _t('Click to explore/Clicca per esplorare') }</small>
                        <Gallery gallery={ [componentInfo.hotspots[selectedHotspot]?.image] } webparams={webparams} 
                        quality={  'medium' } />
                    </div>
                )}


            <MapListOfHotspots
                selectedHotspot={selectedHotspot} setSelectedHotspot={setSelectedHotspot}
                hoveredHotspot={hoveredHotspot} setHoveredHotspot={setHoveredHotspot}
                options={ {reactToHover:true} }
                componentInfo={componentInfo} />

            {/* In Tour Page: Gallery for Page Map component */}
            { componentInfo.gallery?
                <div className={`GoogleMap__gallery z-9`}>
                    <Gallery gallery={componentInfo.gallery} webparams={webparams} />
                </div>:null }


            {/* Button to select the 180 Panorama Component */}
            <MapButtonsTop 
                        webparams={webparams} setWebparams={setWebparams} 
                        selectedHotspot={selectedHotspot} setSelectedHotspot={setSelectedHotspot}
                        componentInfo={componentInfo} />

            { typeof hoveredHotspot === 'number' && selectedHotspot === null &&
            <div className="LeafletMap__PanelLeft--hover LeafletMap__PanelLeft card">
                <h3>{componentInfo.hotspots[hoveredHotspot].title}</h3>
                <div className='fff'>
                    { componentInfo.hotspots[hoveredHotspot].image && (
                        <div className="image-in-panel">
                            <img src={componentInfo.hotspots[hoveredHotspot].image.sizes.medium} />
                        </div>
                    )
                    }


                    <ParsedDescription  
                        description={ componentInfo.hotspots[hoveredHotspot].excerpt } 
                            webparams={webparams} setWebparams={setWebparams} />
                </div>
            </div>
            }

            { typeof selectedHotspot === 'number' &&
            <div className="LeafletMap__PanelLeft--selected LeafletMap__PanelLeft card">
                <h3>{componentInfo.hotspots[selectedHotspot].title}</h3>
                <div className="coordinates text-center mb-3">
                    <a  href={`https://www.google.com/maps/place/${componentInfo.hotspots[selectedHotspot].coordinates.lat},${componentInfo.hotspots[selectedHotspot].coordinates.lng}`} 
                        target="_blank" 
                        title={ _t('open google maps with this location/apri google maps con queste coordenate') }
                        className="btn btn-primary btn-sm mx-auto">
                            <i class="bi bi-geo-alt mr-4"></i>
                            {componentInfo.hotspots[selectedHotspot].coordinates.lat} °,
                            {componentInfo.hotspots[selectedHotspot].coordinates.lng} ° 
                            <i class="bi bi-arrow-up-right-square ml-4"></i>
                    </a>
                </div>
                <div className='fff'>
                    <ParsedDescription  
                        description={ componentInfo.hotspots[selectedHotspot].description } 
                            webparams={webparams} setWebparams={setWebparams} />
                </div>
            </div>
            }

            { componentInfo.description && typeof selectedHotspot !== 'number' && typeof hoveredHotspot !== 'number' &&
            <div className="LeafletMap__PanelLeft--description LeafletMap__PanelLeft card">
                <div className='fff'>
                    <ParsedDescription  
                        description={ componentInfo.description } 
                            webparams={webparams} setWebparams={setWebparams} />
                </div>
            </div>
            }

        </div>
    )
}

function LocationMarker({hotspot, indexHt, leafletMapRef,
                         hoveredHotspot, setHoveredHotspot, selectedHotspot, setSelectedHotspot}) {
    // STATE + COMPUTED: state to know if the location is seleced or not. Depends on props uniquely
    const [isSelected, setIsSelected] = React.useState(false);
        React.useEffect(()=> setIsSelected(selectedHotspot === indexHt), [selectedHotspot]);
    const [isHovered, setIsHovered] = React.useState(false);
        React.useEffect(()=>{ hoveredHotspot === indexHt? setIsHovered(true) : setIsHovered(false) },[hoveredHotspot]);
    const [backupPosAndZoom, setBackupPosAndZoom ] = React.useState(null);
    

    const size = isSelected? 50 : 30;
    const position = [
        hotspot.coordinates?.lat,
        hotspot.coordinates?.lng
    ]
    let imageToUse = hotspot.thumbnail? hotspot.thumbnail.sizes.thumbnail : hotspot.image.sizes.thumbnail;
    if (!imageToUse)
        imageToUse =  process.env.REACT_APP_BASEURL + 'imgs/generic-marker.png';

    const icon = L.icon({
            iconUrl: imageToUse,
            shadowUrl: (isSelected || isHovered)? process.env.REACT_APP_BASEURL + 'imgs/shadow-balcony-marker.png' : null,
            iconSize:     [size, size], // size of the icon
            shadowSize:    [size+10, size+10],
            iconAnchor:   [size/2, size/2], // point of the icon which will correspond to marker's location
            shadowAnchor: [size/2 + 5, size/2 + 5],
            popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor
        });

    // WATCH isSelected
    React.useEffect(()=>{
        // this marker is selected: pan and zoom to it!
        if(isSelected){
            if (refMarker.current && leafletMapRef) {
                refMarker.current.openPopup();
                leafletMapRef.flyTo(refMarker.current.getLatLng(), 15);
                setBackupPosAndZoom( [ leafletMapRef.getCenter(), leafletMapRef.getZoom() ]);
            }
        }
        if (!isSelected) {
            if (refMarker.current && leafletMapRef) {
                refMarker.current.closePopup();
                if (backupPosAndZoom)  // pans back to the previous position before selction
                    leafletMapRef.flyTo(backupPosAndZoom[0], backupPosAndZoom[1]);  
                setBackupPosAndZoom(null);
            }
        }
    },[isSelected]);


    // Add DOM interaction here (component doesnt accep onClick etc.)
    const refMarker = React.createRef();
    React.useEffect(()=>{
        if (refMarker.current) {
            refMarker.current.on('click', e=> {
                setSelectedHotspot(indexHt);
            }); 
            refMarker.current.on('mouseover', e=> setHoveredHotspot(indexHt) );
            refMarker.current.on('mouseout', e=> setHoveredHotspot(null) );
        }
    },[refMarker.current]);


    
    return position? (
      <Marker position={position}
              icon={icon}
              ref={refMarker}
        >
        <Popup>
            <h2 className="h5">{ hotspot.title }</h2>
            { hotspot.location && <p>{ _t('Location/Localizzazione') }:{ hotspot.location }</p> }
            

        </Popup>
      </Marker>
    ): null;
}

