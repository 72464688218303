
// I will probably use this one when a balcone is selected, 

import { dev2, devdel } from "../../../../helpers";

// to display the main places , mountains, river in the map. 
export function createMapOverlay(map, imageSVG, bounds) {

    let balconeOverlay;
    dev2("creating overlay createMapOverlay ", map, imageSVG, bounds);
    const imgBounds = {}; // sanitize
    Object.keys(bounds).forEach( cardinalP => imgBounds[cardinalP] = parseFloat(bounds[cardinalP]) )
    //     north: 43.6388,
    //     south: 43.6265,
    //     east: 12.5645,
    //     west: 12.5485,

      devdel('image SVG', imageSVG);
      if (!window.google) {
          alert('not google loaded. Can\'t create overlay');
          return;
      }
      balconeOverlay = new window.google.maps.GroundOverlay(
        imageSVG,
        imgBounds,
        // { opacity: 0.4 }
      );
      balconeOverlay.setMap(map);

      return(balconeOverlay);
}