import React from 'react'
import { Marker } from "@react-google-maps/api"

/**
 * 
 * Just the markers inside the map.
 */

export default function MapMarkers( { 
                                        hotspots,
                                        selectedHotspot,
                                        setSelectedHotspot,
                                        setHoveredHotspot
                                    }) {
    
    if (!hotspots) return null;
    
    var jsx = hotspots.map( (hotspot, i) => { 
        
        let size = i === selectedHotspot? 50 :  30;
        const imageToUse = hotspot.thumbnail? hotspot.thumbnail : hotspot.image;
        if (!imageToUse) size = 100; // when we use generic marker
        const icon = {
            url: imageToUse? imageToUse.sizes.thumbnail : process.env.REACT_APP_BASEURL + 'imgs/generic-marker.png',   // 'https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-a.png&text=A&psize=16&font=fonts/Roboto-Regular.ttf&color=ff333333&ax=44&ay=48&scale=1',
            // // path: window.google.maps.SymbolPath.CIRCLE,
            scaledSize: new window.google.maps.Size(size, size), // scaled size
            origin: new window.google.maps.Point(0,0), // origin
            anchor: new window.google.maps.Point(size/2,size/2), // anchor
        }
        
        // console.log('rendering todelete ht', hotspot, i);
        
        return (
        <React.Fragment key={'marker'+i}>
            <Marker 
                position={{ lat: parseFloat(hotspot.coordinates?.lat) , lng: parseFloat(hotspot.coordinates?.lng) }} 
                onClick={ (e) => setSelectedHotspot(i) }
                onMouseOver={ (e) => setHoveredHotspot(i) }
                onMouseOut={ e => setHoveredHotspot(null) }
                icon={icon}
                name={`hotspot-${i}`}
                />
        </React.Fragment>
        )
    })

    return jsx;
}


