import React, {useState} from 'react';
import { dev2 } from '../../../../helpers.js';

import hotspot3dcreation from './hotspot-threejs.js';
import { onZoomControl } from './threejs-base.js';

/** in PANORAMA 360:
 * Creates the jsx <li> 
 *  AND the threejs Objects for the hotspot with hotspot-threejs.js).
 * Adds the handlers on mouse enter, move, out and click for the object3D and <li>
 * @param {Base class} thebase . See threejs-base to see the bas class.
 * @param {object} params - has all the info about the hotspots. position, title ..
 *                          - here we assign also the params 'in_pano' from API ACF fields.
 *                          - 
 * @param {threejs Object} selectedHotspot : useState param
 */

export default function PanoHotspot({thebase, params, 
                                selectedHotspot, setSelectedHotspot }) {

    const [created, setCreated ]= useState(false);  // flag
    const [object3D, setObject3D] = useState(null); // the threejs object associated to this hotspot

    
    // 1) On mount: creation of the hotspots in the panorama 3D
    React.useEffect(()=>{
        if (!thebase || !thebase.world) {
            console.error('hotspot no cargado, waiting for world to be created');
            return null;
        }
        if (created) return null;
        dev2("COMP hotspot", params.in_pano)
        setObject3D( hotspot3dcreation( thebase, Object.assign(params, params.in_pano), setSelectedHotspot ) );

        setCreated(true);


    },[thebase])

    /** NOT IN USE (todelete) On selecting the hotspot.
     * This is set in the parent. 
     */


    if (!thebase) return null;
    if (!params) return null;
    // Creation of the hotspot in the list of names HTML
    return (<li className={"list-group-item py-0 d-flex " +
                            (selectedHotspot?.name === object3D?.name? 'active' : null)}
                onMouseEnter={ (e) => {
                    object3D._onMouseEnterCallback(null, thebase);
                }}
                onMouseOut={ (e) => {
                    object3D._onMouseOutCallback(null, thebase);
                }}
                onClick={ (e) => {
                    object3D._onClickCallback(null, thebase);
                }} >
        { params.post_title }
    </li>);
}

