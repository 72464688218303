import * as THREE from "three";
import TWEEN from '@tweenjs/tween.js';

import { animatedLookAt } from './threejs-base.js';
import { makeTextMaterial, makeTextSprite  } from './pano-helpers.js';
import { dev1, deval } from "../../../../helpers.js";
import {showQuadro} from './quadro-poster-3d';

/**
 * 
 *  @param {object} params 
 *  @param { string }  params.position: 300,45,12  (x,y,z) coords
 * 
 * returns object three js. 
 *                  methods:
 *                      .getShade('shape') / foto /paiting / 
 */
export default function hotspot3dcreation( theBase, params, setSelectedHotspot ) {

    // params become --> attrs.
    const attrs = Object.assign( {
        // params for the point of the hotspot. A marker will rise on top
        position: "0,0,0",
        rotation: "0,0,0",
        scale: "1",
        height_stick: 100, // height_stick of the stick
        extraHeight: 200, // extra height will contain the label and the shades, or part of them at least.
        // highPercent: 100,           // high of the marker
        // shades textures png
        offset: "0,0",
        img_shape: null,
        img_foto: null,
        img_painting: null,
        img_alpha_quadro: null,
        img_quadro: null,
        quadro_position: "-400, 0, 0",
        quadro_scale: 100,
        // _shades: [], // shortcut to access to the shape, foto and painting shade.
        // _label ( sprite object on top of the hs marker, above the letter )
        // _hotspot_index (corresponds to the index in the array 'hotspots')
    }, params);
    // preparing some attrs: 
    attrs.position = attrs.position.split(',').map( coor => parseFloat(coor) )
    attrs.rotation = attrs.rotation.split(',').map( coor => parseFloat(coor) )
    attrs.offset = attrs.offset.split(',').map( coor => parseFloat(coor) ) // x/y offset respect position
    attrs.height_stick = parseFloat(attrs.height_stick);

    dev1('CREATE Hotspot 3D', params);
    /**
         * CREATE THE HOTSPOT object
    */

    /** CREATE THE GROUP. It is a plane with childre: the label, the pin, and the shades */
    var geometry = new THREE.PlaneGeometry( 100, attrs.height_stick + attrs.extraHeight, 32 );
    geometry.applyMatrix4( new THREE.Matrix4().makeTranslation( 0,  // set pivot of the hotspot
                                                attrs.height_stick/2, 0 ) );
    const hotspotGroup = new THREE.Mesh( geometry, 
                            new THREE.MeshBasicMaterial( {color: 0xf00f00, side: THREE.DoubleSide, transparent: true, opacity: 0} ) );
    
    hotspotGroup.position.set( ...attrs.position );
    // rotation: facing to camera
    var theta = Math.atan2(attrs.position[0], attrs.position[2] ); // calculate angle of rotation to face to camera
    hotspotGroup.rotation.y = (theta + Math.PI); // 3 decimals
    hotspotGroup.scale.set( attrs.scale,attrs.scale,attrs.scale );
    


    // Creation of the PIN: the cilinder is the stick
    var cilGeo = new THREE.CylinderGeometry( 3, 1, attrs.height_stick, 3 );
    cilGeo.applyMatrix4( new THREE.Matrix4().makeTranslation( 0, attrs.height_stick/2, 0 ) );                                        
    var cylinder = new THREE.Mesh(  cilGeo, new THREE.MeshBasicMaterial( {color: 0xffff00} ) );                        
    cylinder.name   = 'pin';
    hotspotGroup.add( cylinder );

    const letterABC = ('ABCDEFGHIJKLMNOPQRSTUWXYZ')[params.hotspotIndex];
    const loader = new THREE.TextureLoader();
    const texture1 = loader.load( process.env.REACT_APP_BASEURL + `imgs/${letterABC}.png`);
    const circle = new THREE.Mesh(  new THREE.CircleGeometry( 20, 35 ), 
                                        new THREE.MeshBasicMaterial( { color: 0xffff00,
                                                                        map: texture1} ) );
    circle.name = 'circle';
    circle.position.y = parseInt(attrs.height_stick) + 10;
    cylinder.add(circle);
    
    
    



//        const hotspotImg = `${webapp.themeUrl}/images/react-app-images/hotspot-marker.svg`;
//        const loader = new THREE.TextureLoader();
//        const texture1 = loader.load( hotspotImg , function(im) { 
//            let ratio = im.image.height/im.image.width;

            // if (params.animatedMap > 1) ratio *= params.animatedMap;
            // const material = new THREE.SpriteMaterial( { 
            //     color: 0xffffff,
            //     map: texture1 
            // } );
            // const sprite    = new THREE.Sprite( material );
            // const material2 = new THREE.MeshBasicMaterial( { color: 0xffffff,
            //                                                  map: texture1,
            //                                                  transparent: true,
            //                                                  side: THREE.DoubleSide
            //                                                  } );

            // if sprite
            
            // If not sprite
            // // const timesShorter = 100/attrs.highPercent; // if 50% the its 2 times shorter
            // texture1.repeat =  { y: 1*timesShorter, x: 1 }                                                             
            // texture1.offset.y = 0 + (1 - timesShorter);
            // const size  = 30;
            // const geometry =  new THREE.PlaneBufferGeometry( size, size * ratio, 3 );
            // // the origin og the hotspot is on the bottom, not the center
            // // geometry.applyMatrix4( new THREE.Matrix4().makeTranslation( 0, (size * ratio/2) * attrs.highPercent/100, 0 ) );
            // const sprite = new THREE.Mesh( geometry, material2 );
            // // sprite.scale.y = attrs.highPercent/100;
            
            
            // // posPoint[1]     +=  size * ratio  / (2 * timesShorter);
            // sprite.position.set( ...attrs.position )
            // // rotation: facing to camera
            // var theta = Math.atan2(attrs.position[0], attrs.position[2] ); // calculate angle of rotation to face to camera
            // sprite.rotation.y = (theta + Math.PI); // 3 decimals
            

        // CREATE THE LABEL
        const label3D = makeTextSprite( params.post_title, { materialType: 'sprite'} );
        label3D.name = params.post_name
        label3D.position.y = 0; // put it on top of the marker
        label3D.position.z = -50; 
        label3D.position.x = 50; 
        circle.add(label3D);
        hotspotGroup._label = label3D;
        



            // CREATE THE SHADERS CHILDREN OF THE SPRITE
            
            if (attrs.textures.img_shape) {
                attrs.name      = 'shape';
                attrs.offset[2] = 10; // z depth to 0.
                attrs.opacity   = 1;
                createShade(attrs.textures.img_shape, hotspotGroup, attrs);
            }
            if (attrs.textures.img_foto) {
                attrs.name = 'foto';
                attrs.offset[2] = 20; // z depth a little closer
                attrs.opacity   = 0;
                createShade(attrs.textures.img_foto, hotspotGroup, attrs);
            }
            if (attrs.textures.img_painting) {
                attrs.name = 'painting';
                attrs.offset[2] = 40; // z depth a little closer
                createShade(attrs.textures.img_painting, hotspotGroup, attrs);
            }
            // add info about the position of the quadro
            hotspotGroup._quadro_position = attrs.quadro_position? attrs.quadro_position.split(',').map(h=>parseInt(h)) : null;
            hotspotGroup._quadro_scale = attrs.quadro_scale? parseFloat(attrs.quadro_scale) : null;

            // add it to the world
            hotspotGroup.name = 'hotspot_' + attrs.hotspotIndex; // 'hotspot_0' ...
            hotspotGroup._hotspot_index = attrs.hotspotIndex;
            theBase.world.add( hotspotGroup );
            // action when clicking the hotspot.
            hotspotGroup._onClickCallback = function(e, bas, object = null) {
                if (bas.camera.__isUserInteracting) return; // dont act if we are moving the camera
                const obj = object?? this;
                // Now the next of the actions, after selecting a hotspot are in useEffect/selectedHotspot change.
                if (typeof theBase._selectedHt !== 'number') {
                    setSelectedHotspot(parseInt(obj._hotspot_index));
                } else
                    toggleShadeView(theBase, obj);
            }

            hotspotGroup._onMouseEnterCallback = function(e, bas, object = null) {
                const obj = object?? this; //  we could also use camera.currentMouseIntersection
                if (obj._label)
                    obj._label.material.blending = THREE.SubtractiveBlending
                if (obj._shades?.length) {
                    obj._shades[0].material.opacity = 1;
                }
                document.body.style.cursor = 'pointer';
                // animation on - the shade shape flashes in.
                bas.camera._hoveredObject = obj;
                bas.addUpdateCallback(function animateHoveredObject(b) {
                    const shapeShade = (b.camera._hoveredObject?._shades?.length)? b.camera._hoveredObject?._shades[0] : null;
                    if (!shapeShade) return;
                    let childMat = shapeShade.material;
                    // if (!childMat) {alert('cac'); return;}
                    var opacity = childMat?.opacity?? 0;
                    const increment = opacity>=0.5? 0.02 : 0.01;
                    opacity = opacity>=0.99? 0.3 : opacity+increment;
                    childMat.opacity = opacity;
                } )
            }
            // hover out: remove highlighting of the 3d element and shade shape
            hotspotGroup._onMouseOutCallback = function(e, bas, object = null) { 
                const obj = object?? this;
                obj._label.material.blending = THREE.NormalBlending
                document.body.style.cursor = '';
                // animation off
                bas.removeLastUpdateCallback();
                const shapeShade = obj?.getShade('shape');
                if (shapeShade) {
                    let childMat = shapeShade.material;
                    childMat.opacity = 1; // TODO: maybe hide if the controls have hidden the shapes.
                }
                delete bas.camera['_hoveredObject']
            }
            // useful methods: not in use at the moment shape | foto | painting
            hotspotGroup.getShade = function(whichone) { 
                const found = this._shades?.find( s => s.name === whichone)
                if (found) return found;
                return this._shades;
            }

            // WE ADD THE Object 3D to react
            
//    });
    
    return hotspotGroup;

}
// ****  END OF THE CREATION OF THE HOTSPOT



function createShade( imgShade, parent = null, params = {} ) {

    const attrs = Object.assign( {
        offset:     [0,0,0],
        rotation:   [0,0,0],
        scale:      1,
        opacity:    0
    }, params)

    const loader = new THREE.TextureLoader();
    const texture1 = loader.load( imgShade , function(im) {
    
        let ratio = im.image.height/im.image.width;
        const material2 = new THREE.MeshBasicMaterial( { color: 0xffffff,
                                                         map: texture1,
                                                         // alphaMap: imgShade,
                                                         transparent: true,
                                                         side: THREE.DoubleSide
                                                         } );
                                                         // the origin og the hotspot is on the bottom, not the center
            
        // size: height
        const size  = 50;
        const height = size * ratio;
        const geometry =  new THREE.PlaneBufferGeometry( size, height, 3 );
        geometry.applyMatrix4( new THREE.Matrix4().
                                makeTranslation( 0, - height / 2, 0 ) );
        const plane = new THREE.Mesh( geometry, material2 );
        // plane.position.y = -size/5;
        plane.material.opacity = attrs.opacity;
        plane.scale.set(attrs.scale,attrs.scale, 1)
        plane.rotation.set(...attrs.rotation)
        // console.log('offset',attrs.offset)
        plane.position.set(...attrs.offset)
        
        plane.name = attrs.name;
        // position
        if (parent) {
            parent.add(plane);
            if (!parent._shades) parent._shades = [];
            parent._shades.push(plane); // so, the hotspot._shades[0] is the shape, [1] the foto, [2] the painting
        } 
    });
}


/** displays the foto or painting shade of the hotspot  */
export function showShade(obj, shadeName, finalOpacity = 1 ) {

    const theShade = obj.getShade(shadeName);
    if (!theShade) return;
    if (!theShade.material) return;
    (new TWEEN.Tween( theShade.material ).to( { 
        opacity: finalOpacity }, 400))
    .onUpdate(() => {}).start();

}

// depending on the current shade shown, it shows the next one
export function toggleShadeView(thebase, obj) {
    // devalert('toggling')
    if (!obj?._shades) return false;
    let [shape, painting, foto ] = obj._shades;

    const shapeMat = shape?.material? shape.material : false;
    const paintingMat = painting?.material? painting.material : false;
    const fotoMat = foto?.material? foto.material : false;
    
    if (fotoMat?.opacity >= 1) {
        // if foto shown >> then show the painting
        shapeMat.opacity = 0;
        paintingMat.opacity = 1;
        fotoMat.opacity = 0;
        obj._shadeSelected = 'painting';
    } else if (paintingMat?.opacity >= 1) {
        // if painting shown >> then show the quadro with alpha
        shapeMat.opacity = 1;
        paintingMat.opacity = 0;
        fotoMat.opacity = 0;
        obj._shadeSelected = 'shape';
        showQuadro(thebase, obj._quadro_position, obj._quadro_scale);
        // actions when quadro clicked in quadro-...js
    } else if (obj._shadeSelected === 'shape') { 
        shapeMat.opacity = 0;
        paintingMat.opacity = 0;
        fotoMat.opacity = 1;
        obj._shadeSelected = 'foto';
    }

}