import React from 'react'

export default function ExploreDebugTools({
    dragging, snapShot, position, zoom,
    selectedMarker
}) {

    // const [getSR(), setgetSR()] = React.useState(null);
    const getSR = () => document.querySelector('.Explore__marker.marker-selected');

    function moveMarker(axis = 'left', posOrNeg = 1, e = null) {
        if (!getSR()) return;
        let { left, top } = getSR().style;
        let amount = axis === 'left'? 0.1 : 0.5;
        if (e?.shiftKey) amount /= 2;
        left = parseFloat(left.replace('%',''));
        top = parseFloat(top.replace('%',''));
        const newValue = (axis === 'left'? left : top) + amount * posOrNeg;
        getSR().style[axis] = newValue + '%';
        updateShownParams()
    }
    function scaleMarker(axis = 'width', posOrNeg = 1, onlySelectedImg = false, e = null) {
        if (!getSR()) return;
        
        let width, height = null;
        const selectedEl  = getSR().querySelector('.selected-img');
        if (onlySelectedImg) {
            if (!selectedEl) return
            width   = selectedEl.style.width;
            height  = selectedEl.style.height;
        }
        else {
            width   = getSR().style.width;
            height  = getSR().style.height;
        }
        let amount = (axis === 'width')? 0.4 : 0.8;
        if (onlySelectedImg)
            amount *= 4;
        if (e?.shiftKey) amount /= 2;
        width = parseFloat(width.replace('%',''));
        height = parseFloat(height.replace('%',''));
        const newValue = (axis === 'width'? width : height) + amount * posOrNeg;
        if (!onlySelectedImg)
            getSR().style[axis] = newValue + '%';
        else 
            selectedEl.style[axis] = newValue + '%';
        updateShownParams()
    }
    function showHideMarker(opacity = 1) {
        if (!getSR()) return;
        
        const displayValue =  (opacity === 0) ? 'none' : 'block';
        Array.from(getSR().querySelectorAll('.shape')).forEach(h=>{
            h.style.display = displayValue;
        })
        if (opacity > 0)
            getSR().style.opacity = opacity;
        updateShownParams();
    }
    function marginStick( prop = 'margin-left', direction = 1, event = null) {
        let amount = prop === 'margin-left'? 0.3 : 0.5;
        if (event?.shiftKey) amount /= 2;
        const stickEl = document.querySelector('.Explore__marker-pin.marker-selected');
        const currentValue  = parseFloat(stickEl.style[prop]);
        const newValue      = currentValue + (amount * direction);
        stickEl.style[prop] = newValue + '%';
        updateShownParams()
    }
    function sizeStick(direction=1, e = null) {
        let amount = 10;
        if (e?.shiftKey) amount /= 2;
        const stickEl = document.querySelector('.marker-selected .Explore__marker-pin--stick');
        const currentValue  = parseFloat(stickEl.style.height);
        const newValue      = currentValue + (amount * direction);
        stickEl.style.height = newValue + 'px';
        updateShownParams()
    }
    function updateShownParams() {
        if (!getSR()) return;
        
        const { left, top, width, height} = getSR().style;
        const posText = `${left.replace('%','')}, ${top.replace('%','')}`;
        const scaleText = `${width.replace('%','')}, ${height.replace('%','')}`;
        
        const selectedImgEl = getSR().querySelector('.selected-img');         
        if (selectedImgEl) {
            const { width: widthSelected, height: heightSelected} = selectedImgEl.style;
            const scaleTextSelected = `${widthSelected.replace('%','')}, ${heightSelected.replace('%','')}`;
            document.getElementById('Explore__scale-selected-update').value = scaleTextSelected;
        }
        document.getElementById('Explore__scale-update').value = scaleText;
        document.getElementById('Explore__position-update').value = posText;
        // now the stick
        const pinEl = document.querySelector('.Explore__marker-pin.marker-selected');
        document.getElementById('Explore__scale-stick-offset-left').value = pinEl.style.marginLeft;
        document.getElementById('Explore__scale-stick-offset-top').value = pinEl.style.marginTop;
        const stickEl = document.querySelector('.marker-selected .Explore__marker-pin--stick');
        document.getElementById('Explore__scale-stick-size').value = stickEl.style.height;
    }

    return (
        <div id='info-debug' className="position-absolute info-debug bg-light">
            <button className='btn btn-info btn-small float-right' id="Explore__test" onClick={e=> document.getElementById('info-debug').classList.add('side-left')}>side</button>
            <button className='btn btn-danger btn-small float-right' onClick={e=> document.getElementById('info-debug').remove()}>close</button>
            { typeof selectedMarker !== 'number' &&  <>
                <p>{ dragging? <small>IS DRAGGING, initial pos (mouse) : <br/>{dragging.clickedX}px//{dragging.clickedY}px</small> : <small>No drag</small>} </p>
                TOP: 𝚫({snapShot.outsideTop?.toFixed()}) (N : {(snapShot.outsideTop/zoom).toFixed()})<br/>
                RIGHT: 𝚫({snapShot.outsideRight?.toFixed()}) (N : {(snapShot.outsideRight/zoom).toFixed()})<br/>
                LEFT: {snapShot.boundaryLeft} 𝚫({snapShot.outsideLeft?.toFixed()})<br/>
                BOTTOM: {snapShot.boundaryBottom} 𝚫({snapShot.outsideBottom?.toFixed()})<br/>
                <p>Pos painting: <span>{position[0].toFixed(2)},{position[1].toFixed(2)}</span><br/>
                    Zoom: {parseInt(zoom*100)/100}</p>
                <p id="m"></p>
            </> }
                <p> 
                    <span className="text-white">Selected: { selectedMarker }</span> </p>
                Last click x%,y%<input id="Explore__last-click-pos" defaultValue=""/><br/>
                Area 𝚫x%,𝚫y%<input id="Explore__last-click-area" defaultValue="" />
            { typeof selectedMarker === 'number' &&  <>
                    <button onClick={e=>moveMarker('top', -1,e)} className='text-center w-100'>top</button><br/>
                    <button onClick={e=>moveMarker('left', -1,e)} className='w-50'>left</button>
                    <button onClick={e=>moveMarker('left', 1,e)} className='w-50'>right</button><br/>
                    <button onClick={e=>moveMarker('top', 1,e)} className='text-center w-100'>bottom</button><br/> 
                    <br/>
                    <button onClick={e=>scaleMarker('width', -1, false,e)} className='w-50'>width -</button>
                    <button onClick={e=>scaleMarker('width', 1, false,e)} className='w-50'>width +</button><br/>
                    <button onClick={e=>scaleMarker('height', -1, false,e)} className='w-50'>height -</button>
                    <button onClick={e=>scaleMarker('height', 1, false,e)} className='w-50'>height +</button><br/>
                    <br/>
                    <button onClick={e=>showHideMarker(0.5)} className='w-50'>so so</button>
                    <button onClick={e=>showHideMarker((getSR().querySelector('.shape')?.style.display === 'none')? 1 : 0)} 
                            className='w-50 btn-primary btn'>show/hide</button>
                    <br/>
                    <button onClick={e=>getSR().style.border = "0"} className='w-50'>border -</button>
                    <button onClick={e=>getSR().style.border = "10px solid white"} className='w-50'>border +</button>
                    <br/>
                    <button onClick={e=>scaleMarker('width', -1, 'selected',e)} className='w-50'>width extra -</button>
                    <button onClick={e=>scaleMarker('width', 1, 'selected',e)} className='w-50'>width extra +</button><br/>
                    <button onClick={e=>scaleMarker('height', -1, 'selected',e)} className='w-50'>height extra -</button>
                    <button onClick={e=>scaleMarker('height', 1, 'selected',e)} className='w-50'>height extra +</button><br/>
                    <br/>
                    
                    <input id="Explore__position-update" className='w-25' defaultValue="" placeholder="pos for CMS"/>
                    <input id="Explore__scale-update" className='w-25' defaultValue=""  placeholder="scale for CMS"/>
                    <input id="Explore__scale-selected-update" className='w-25' defaultValue=""  placeholder="scale selected CMS"/>
                    
                    {/* All about the pin stick */}
                    <br/> stick size:<br/>
                    <button onClick={e=>sizeStick(-1,e)} className='w-25'>-</button>
                    <button onClick={e=>sizeStick(1,e)} className='w-25'>+</button>
                    <input id="Explore__scale-stick-size" className='w-50' defaultValue=""  placeholder="offset left stick CMS"/>
                    <br/><br/>Stick offset <br/>
                    <input id="Explore__scale-stick-offset-left" className='w-50' defaultValue=""  placeholder="offset left stick CMS"/>
                    <input id="Explore__scale-stick-offset-top" className='w-50' defaultValue=""  placeholder="offset top stick CMS"/>
                    <button onClick={e=>marginStick('margin-left', -1,e)} className='w-25'>left -</button>
                    <button onClick={e=>marginStick('margin-left', 1,e)} className='w-25'>left +</button>
                    <button onClick={e=>marginStick('margin-top', -1,e)} className='w-25'>top -</button>
                    <button onClick={e=>marginStick('margin-top', 1,e)} className='w-25'>top +</button>
                    

                </>
            }
        </div>        
    )
}

