/**
 * simple way to create hard coded translations. Use __('the key') 
 * and the right text depending on language will be used (it|en)
 */
const translations_it = {
  'privacy-link' : 'it/privacy-e-cookies',
  'intro-title': 'Dove Arte e Paesaggio si incontrano',
  'intro-text': 'Seleziona uno dei balconi nella mappa o clicca sui pulsanti',
  'mymap-explore-tip' : 'Benvenuto',
  'metatitle-text' : 'Montefeltro Art Views',
  'metadescription-text' : 'I paesaggi nascosti di Piero della Francesca e Leonardo da Vinci',
  'current-language-message' : 'La lingua impostata al momento è ',
  'loading-ebook-text': 'Caricando ebook. Esc per cancellare', 
  'Index': 'Indice',
  'Close': 'Chiudere',
  'Settings': 'Impost.',
  'Back': 'Indietro',
  'Next': 'Prossimo',
  'Prev': 'Prev',

  'Start Tour' : 'Iniziare Tour',
  'map-button-title' : 'Esplora i balconi sulla mappa',
  'map-button-alt' : 'Mappa dei balconi',
  'Map' : 'Mappa',

  'Tap': 'Clicca',
  'Click': 'Clicca',
  ' to see the overlay': ' per vedere la sovrimpressione',
  ' to see the inital image': ' per vedere l\'immagine originale',
  'hover to overlay the image' : 'portare il mouse sopra per vedere il confronto',
  'click to stick the image' : 'Clicca per fissare l\'immagine',

  'intro-cookie-consent': '© 2021 Regione Emilia-Romagna, Assessorato alla Cultura e Paesaggio, Progetto Pilota RECOLOR INTERREG Italia-Croazia. Tutti i diritti riservati.',
  'cookie-consent': 'La webapp MONTEFELTRO ART VIEWS non installa nessun cookie, né nessuna informazione personale dell’utente, a meno che questo accetti la finestra GDPR, in questo unico caso si installano soltanto i cookie per Google Analytics.'

  
}
const translations_en = {
  'privacy-link' : 'en/privacy-cookies',
  'intro-title': 'Where Art and Landscape intercross',
  'intro-text': 'Select one of the balconies in the map or click on one option',
  'mymap-explore-tip' : 'Welcome',
  'metatitle-text' : 'Montefeltro Art Views',
  'metadescription-text' : 'The hidden landscapes of Piero della Francesca and Leonardo da Vinci',
  'map-button-title' : 'Explore the balconies from the map',
  'map-button-alt' : 'Balconies map',
  'loading-ebook-text': 'Loading ebook. Esc to cancel', 

  'current-language-message' : 'The current language is ',
  'intro-cookie-consent': '© 2021 Regione Emilia-Romagna, Assessorato alla Cultura e Paesaggio, Pilot Project RECOLOR INTERREG Italy-Croatia. All rights reserved.',
  'cookie-consent': 'The MONTEFELTRO ART VIEWS webapp does not install any cookies or any personal information of the user, unless the user accepts the GDPR window, in this only case, only the cookies for Google Analytics are installed.'
}

export function __(key) {
  // quick way to get the right language without worrying about passing it as param.
  const lang = document.querySelector('html').lang.substr(0,2);
  if (lang === 'it' && translations_it[key]) {
      return translations_it[key];
  }
  if (lang === 'en' && translations_en[key]) {
      return translations_en[key];
  }
  return key;
}

export function _t(text, separator = '/') {
  const lang = document.querySelector('html').lang.substr(0,2);
  const both = text.split(separator);
  const english = both[0].trim();
  const italian = both.length>1? both[1].trim() : english;
  if (lang === 'en') return english;
  return italian;
}