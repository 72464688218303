// Generic button
import React from 'react'

/* USAGE:
    <CircleButton 
        onClickAction={ e=> setWebparams(Object.assign({...webparams}, {
                                        exploreLanscapeSelected: componentInfo.hotspots[selectedHotspot].explore_landscape }
        classes='btn-explore-180 btn-primary position-relative mx-2'
        iconClass='bi-camera2' textHelp='180º' textInside={ 'Explore Panorama' } /> */

export default function CircleButton({
    classes,
    onClickAction,
    iconClass,
    textHelp,
    textInside,
}) {


    return (
        <button className={`circle-btn ${classes}`}
               onClick={ onClickAction }>
           <i className={`bi ${iconClass}`}></i>
           <span className="circle-btn__text-help">{ textHelp }</span>
           <span className="circle-btn__text-inside">{ textInside }</span>
       </button>
    )
}

