import React from 'react'

import parse, { domToReact } from 'html-react-parser';

/**
 * Converts a string of html into jsx to render in react.
 *  inside the text, converts any href=" *** /reference/nicename" into an onClick to update Popup.js
 * @param {string} description : html as string
 */
export default function ParsedDescription( { description, webparams, setWebparams } ) {

    if (!description) return null;
    const options = {
            replace: ({ attribs, children }) => {
              if (!attribs) return;
              if (attribs.href?.includes('/reference/') ) {
                // grab the slug after the last slash in the url of href:
                const hrefText = attribs.href.endsWith('/')? attribs.href.substring(0, attribs.href.length - 1 ) : attribs.href; // remove last slash if that's the last index
                const refSlug = hrefText.substring(hrefText.lastIndexOf('/') + 1);
                return (
                    <a href="#reference" onClick={ e=> {
                        e.preventDefault();
                        // create the modal window with the hypertext ref here.
                        // call the API to grab the ref content and title.
                        const newWP = {...webparams};
                        newWP.reference = refSlug;
                        setWebparams(newWP);
                        }} >
                      {domToReact(children)}
                    </a>
                );
              }
              // if the link if for an image in the page:
              if (attribs.href?.includes('#media-') || attribs.href?.includes('/uploads/')) {
                return (
                <a href={ attribs.href } onClick={ e=> { 
                    e.preventDefault();
                    let querySel = '';
                    if (attribs.href.includes('/uploads/')) {
                      //const filename = attribs.href.substring( attribs.href.lastIndexOf('/') + 1 );
                      querySel = '[href="'+attribs.href+'"] img';
                      // querySel = '[data-mediaurl$="'+filename+'"] img';
                    } else {
                      const media_post_id = attribs.href.substring( attribs.href.lastIndexOf('-') + 1 );
                      querySel = '#media-'+media_post_id+' img';
                    }
                    const el = document.querySelector(querySel);
                    if (el) el.click();
                      else console.error('non abbiamo trovato quell\'immagine', querySel);
                    }} >
                  {domToReact(children)}
                </a> )
              }
              if (attribs.href?.includes('comp-')) {
                return (
                  <a href={ attribs.href } onClick={ e=> { 
                      e.preventDefault();
                      const compID = attribs.href.substring( attribs.href.lastIndexOf('#') + 1 );
                      const compEl = document.getElementById(compID); 
                      if (compEl) {
                        compEl.classList.add('highlight');
                        compEl.scrollIntoView({behavior: "smooth", block: "end", inline: "center"});
                      }
                  } } >
                    {domToReact(children)}
                  </a>)
              }
            }
        }

    return parse(description, options);    
}


