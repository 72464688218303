import React, {useState} from 'react';
//import { Link } from 'react-router-dom';

import './TopBar.scss';
import IndexList from '../Pages/Components/IndexList'
import { getMapPage, isDebug, isComingSoon } from '../helpers';
import { _t, __ } from '../translations'
import useKeypressHook from '../useKeyPressHook';
import { Offline } from 'react-detect-offline';


// PARAM: info of the current page.
/**
 * 
 * @param {*} allPages (.indexed .not_indexed)
 * @param {*} webparams
 * @param {*} currentPage
 */
export default function TopBar( {allPages, webparams, setWebparams, currentPage} ) {

    const [settingsOpen, setSettingsOpen] = useState(false); // dropdowns
    const [indexOpen, setIndexOpen] = useState(false);
    const [ebookLoading, setEbookLoading] = useState(false);
    const [ebookClosed, setEbookClosed] = useState(null); // null | true | false
    function dropdownOpenClose(stateToUpdate, setStateToUpdate) {
        setStateToUpdate(!stateToUpdate);
    }

    React.useEffect(()=>{
        if (indexOpen) 
            setWebparams(Object.assign({...webparams}, {extraClasses: webparams.extraClasses + ' is-index-open'}))
        else 
            setWebparams(Object.assign({...webparams}, {extraClasses: webparams.extraClasses.replace(' is-index-open','') }))
    },[indexOpen])

    const changeLanguage = React.useMemo( () => {
        let changeLanguageLink = webparams.lang === 'it'? '/en/' : '/';
        let changeLanguageTitle = webparams.lang === 'it'? 'English' : 'Italiano';
        if (currentPage?.translated_page_permalink) {
            changeLanguageLink = currentPage.translated_page_permalink;
            changeLanguageTitle = currentPage.translated_page_title;
        }
        return { link: changeLanguageLink, title: changeLanguageTitle };
    }, [currentPage, webparams.lang] );

    // computed 
    const longTitleClassComputed = React.useMemo(()=>{
        const [wrappedClass, longClass] = ['wrapped-title-xs-sm', 'long-title-xs-sm'];
        
        if (!currentPage) return wrappedClass;
        const optionMobileTitle = currentPage?.options?.long_title_mobile;
        const titleLength = currentPage.post_title?.length;
        const limitToBeConsideredLong = 15;
        if (!optionMobileTitle || optionMobileTitle === 'default') {
            return limitToBeConsideredLong < titleLength ? longClass : wrappedClass;
        }
        return optionMobileTitle === 'force-wrapped'? wrappedClass : longClass;
    }, [currentPage]);

    const mapPage = React.useMemo(()=>{
        const mapPObject = getMapPage(allPages.not_indexed);
        if (!mapPObject) return '/';
        return mapPObject.permalink;
    }, [allPages]);


    // closing ebook when using Escape
    useKeypressHook('Escape', () => { 
        if (indexOpen) {
            setIndexOpen(false);
        }
        if (webparams.ebook) {
            hideEbook();
        }
    }, [webparams.ebook, indexOpen]);

    // to hide the ebook, but not closing it, so we dont need to load it again if reopened
    const hideEbook = () => {
        setEbookClosed(true);
    }

    // when ebook finished load
    const ebookLoadedHandler = e => {
        setEbookLoading(false);
    }

    // PARTIAL
    const title_jsx = React.useMemo( () => {
        return currentPage? <>{ currentPage.post_title}</> : 
            <div className="TopBar__subtitle--logo-montefeltro">
                <img src={`${process.env.PUBLIC_URL}/imgs/logo-montefeltro.png`} alt="Montefeltro Art Views" />
            </div>
    }, [currentPage] );
    // if (!allPages?.indexed) 
    // return <div className="Loading 
    //             w-100 h-100 d-flex justify-content-center align-items-center position-absolute">
    //         <div className="spinner-border text-primary h1" role="status">
    //             <span className="sr-only">Loading...</span>
    //         </div>
    //     </div>
    // ;
    return ( <>
        <nav className={`TopBar navbar navbar-expand-lg navbar-light bg-primary ${
            settingsOpen? 'settings-open' : ''
        }`}>
            
            {/* LOGO CIRCLE */}
            <div className={`TopBar__circle--wrapper z-9 ${longTitleClassComputed}`}>
                <div className="TopBar__circle">
                    <a className="navbar-brand m-0 p-0" href={ webparams.lang === 'en'? '/en' : '/' }>
                        <img src={ `${process.env.PUBLIC_URL}/imgs/logo-recolor.jpg` }
                            className="img-fluid" id="logo-img" alt="logo" />
                    </a>
                    <hr className="separator-xs" />
                        { currentPage?.breadcrums[currentPage?.breadcrums.length-2]? (<>
                            <h1 className="TopBar__supertitle">{ currentPage?.breadcrums[currentPage?.breadcrums.length-2].post_title }</h1>
                            <h2 className="TopBar__subtitle">
                                { title_jsx }
                            </h2></>
                        ): <h1 className="TopBar__subtitle">
                            { title_jsx }
                        </h1> }
                </div>
            </div>
            
            {/* MAIN NAVIGATION */}
            <div className="TopBar__main-commands h-100">
                <ul className="list-unstyled navbar m-0 p-0">
                    <li className={`TopBar__button btn btn-primary ${webparams.isMapPage? 'btn-dark' : ''}`}
                        onClick={ e => { window.location = mapPage;
                            // window.location.href= webparams.lang === 'it'? `/it/mappa-balconi` : '';
                        }}><a className={`${webparams.isMapPage? 'text-light' : 'text-dark'}`} 
                              href={mapPage}
                              title={ __('map-button-title') } alt={ __('map-button-alt') } >
                                  <div className="TopBar__icon--wrapper">
                                    <i className="bi bi-map"></i>
                                  </div>
                                  <span className="TopBar__icon--text d-none d-md-flex">
                                    { __('Map') }
                                  </span>
                            </a>
                    </li>
                    <li className={`TopBar__button btn btn-primary TopBar__index-btn TopBar__dropdown--${indexOpen? 'open' : 'closed'}`}
                        onClick={e=>dropdownOpenClose(indexOpen, setIndexOpen)}>
                        
                        <div className="TopBar__icon--wrapper">
                            <i className="bi bi-list-nested"></i>
                        </div>
                        <span className="TopBar__icon--text d-none d-md-flex">
                            { __('Index') }
                        </span>
                        
                    </li>
                    <li className={`TopBar__button btn btn-primary btn-ebook`}
                        // onClick={ e => {
                        //     if (ebookClosed === true) {
                        //         setEbookClosed(false);
                        //     } else 
                        //         if (allPages.ebook_url) {
                        //             setWebparams( Object.assign( {...webparams}, {
                        //                 ebook: allPages.ebook_url
                        //             }));
                        //             setEbookLoading(true);
                        //         }
                        // }}
                        >
                            <a href={allPages.ebook_url} target="_blank" rel="noopener noreferrer" title="Open ebook in a new tab" >

                            <div className="TopBar__icon--wrapper">
                                <i className="bi bi-book"></i>
                            </div>
                            <span className="TopBar__icon--text d-none d-md-flex">
                                { __('Ebook') }
                            </span>

                            </a>
                            

                    </li>
                </ul>
            </div>
            
            {
                isDebug() && process.env.REACT_APP_WP_BASEURL && currentPage && !isComingSoon() &&
                <p className="text-dark d-none d-md-block">
                    <a  target="new" className="text-dark" 
                        href={ process.env.REACT_APP_WP_BASEURL + 'wp-admin/post.php?post='+currentPage?.ID+'&action=edit'}>
                            EDIT in WP
                    </a>
                </p> }
            

            <ul className={`TopBar__right-button hamburger TopBar__dropdown--${settingsOpen? 'open' : 'closed'}`} >
                
                { changeLanguage?.link &&
                <li className='mr-3 d-none d-md-block lang-name-menu'>
                    <a href={ changeLanguage?.link } title={ changeLanguage?.title }>
                    { webparams?.lang === 'it'? <b><i>change to English</i><span className="acronim">IT</span></b> : <b><i>cambia a italiano</i><span  className="acronim">EN</span></b> }
                    </a>
                </li> }

                <Offline>
                 <li className="mr-3 d-none d-sm-flex">
                    <i className="bi bi-wifi-off"></i>
                </li>   
                </Offline>
                <li id="install-app-btn" className="TopBar__button btn btn-warning m-0 mr-2"
                    style={ window.installButtonShow? {} : { opacity: 0, display: 'none' } }
                    onClick={ window.installButtonHandler?? (e=>alert('not loaded')) }>
                    <div className="TopBar__icon--wrapper">
                        <i className="bi bi-download"></i>
                    </div>
                    <span className="TopBar__icon--text d-none d-md-flex">
                        { _t('Install/Installa') }
                    </span>
                </li>

                <li className="TopBar__button m-0 mr-2"
                    onClick={(e)=> dropdownOpenClose(settingsOpen, setSettingsOpen) }>
                    <div className="TopBar__icon--wrapper hide-if-open">
                        <i className="bi bi-gear"></i>
                    </div>
                    <div className="TopBar__icon--wrapper show-if-open">
                        <i className="bi bi-x-lg"></i>
                    </div>
                    <span className="TopBar__icon--text d-none d-md-flex hide-if-open">
                        { __('Settings') }
                    </span>
                    <span className="TopBar__icon--text d-none d-md-flex show-if-open">
                        { __('Close') }
                    </span>


                    
                </li>
                {/* full page language swapper */}
                <ul className="TopBar__dropdown TopBar__dropdown--settings list-unstyled w-100vw lead">
                    <li className='non-link'>
                        { __('current-language-message') }
                        { webparams?.lang === 'it'? <b>Italiano </b> : <b>English </b> }
                    </li>
                    <li className='h2'>{
                        webparams?.lang === 'it'?
                            <a href={changeLanguage.link} title={changeLanguage.title}>
                                swap to English 
                            </a>
                             : 
                            <a href={changeLanguage.link} title={changeLanguage.title}>
                                Cambia a Italiano 
                            </a>
                    }</li>
                    <li className='non-link mt-5'>
                        { _t('The analyitics cookies are /Le cookies delle analitiche sono ') }
                        <span className={`ml-2 ${webparams.cookieConsentResponse? 'text-success': 'text-danger'}`}>{ webparams.cookieConsentResponse? _t('accepted/accettate') : (webparams.cookieConsentResponse === false? _t('rejected/rifiutate'): _t('not responded/non risposte') )   }</span>
                        <button className='btn btn-primary m-auto d-block' onClick={e=>{ 
                            localStorage.removeItem('cookieConsentResponse');
                            setWebparams( Object.assign({...webparams}, { cookieConsentResponse: null } ) ); // remove answer as if the user didnt answer yet
                            dropdownOpenClose(true, setSettingsOpen); // close the impostazione window.
                        }}>{_t('Change privacy settings/Cambia impostazioni privacy')}</button>
                    </li>
                </ul>
            </ul>
            {/* <div className={`TopBar__overlaywhenopen TopBar__overlaywhenopen--${settingsOpen? 'open' : 'closed'}`}>

            </div> */}
        </nav>

        { indexOpen && <div className="IndexFullScreen m-auto">
            <div className="close-index-btn">
                <button className='circle-btn btn btn-danger position-relative'
                        onClick={ e => setIndexOpen(false) }
                >
                    <i className="bi bi-arrow-left-circle"></i>
                    <span className="circle-btn__text-help">Close</span>
                    <span className="circle-btn__text-inside text-dark">{ 'X' }</span>
                </button>
            </div>
            {allPages?.indexed? 
            <IndexList  webparams={webparams} 
                        indexedPages={allPages.indexed} 
                        options={{ showThumbnail: true, twoColumns: true}} />
            : <p className='card p-3 text-center'>{_t('The index pages have not loaded. Please check your internet connection/Le pagine dell\'indice non si sono caricate. Per favore verifica la connessione internet.')}</p>}
        </div>} 

        { webparams.ebook && (
            <div id="ebook-wrapper" className={`bg-light ${
                ebookClosed? 'd-none' : 'd-block'
            }`}>
                { <p className='container text-info lead mt-2 mb-0 d-flex justify-content-center'>
                    { ebookLoading && 
                        <span>{ __('loading-ebook-text') } ... </span>
                    }

                    <button className='btn btn-danger float-right' onClick={
                        e => hideEbook()
                    }>
                        { __('Close') }
                    </button>
                </p> }
                <iframe key="ebook-iframe" onLoad={ e=> {
                        ebookLoadedHandler()
                    }
                } src={webparams.ebook} 
                  title={ _t('Ebook/Ebook') } />
            </div>
        )}
    </>
    )
}
