// Thanks to this script i can use videoEl.playing, and return true or false.

import { now } from "@tweenjs/tween.js";

// WHERE: Sigla
if (typeof HTMLMediaElement.prototype._playing === 'undefined')
    Object.defineProperty(HTMLMediaElement.prototype, '_playing', {
    get: function(){
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
    })

/**
 * RETURNS object
 */
export function getMapPage(allPages) {
    if (!allPages) return {};
    const lang          = getLang();
    const mapPage       = allPages.find( p => p.template.includes('template-map-balconies'));
    return mapPage;
}
/**
 * router: loads the right page. Sometimes I don't use, the permalink works fine.
 */
export function linkToPage(pageSlug, listOfPages = null) {
    const page_slug = (typeof pageSlug === 'object')? pageSlug.post_name : pageSlug;
    const lang          = getLang();
    const urlRelative   = `/${lang}/${page_slug}`;
    const pageInfo  = listOfPages? listOfPages.find(p => p.post_name === page_slug) : null;
    
    // when we use Helmet maybe this will be different.
    if (pageInfo) { // this DOESNT WORK.
        document.title = pageInfo.post_title;
        devdel('loaded page with linkToPage: ', pageInfo.post_title);
    }
    window.location.href= urlRelative;
}

// helper to add ?jander=foo to a uri
// WHEN: to be able to come back to the explore landscape and quadro when going to a tour page from there.
export function updateQueryStringParameter(uri, key, value) {
    // if null then we dont' add, but delete the param in the url
    if (value === null) {
        var uri = window.location.href.toString();
        return uri
                .replace(new RegExp('[?&]' + key + '=[^&#]*(#.*)?$'), '$1')
                .replace(new RegExp('([?&])' + key + '=[^&]*&'), '$1');
        // return  newUrl;
    }
    // now, if not null we actually add the param inthe querystring
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

/** trick to grab the lang without needing to access to the webparams state.
 *  In PageWrappr.js we set up this attribute.
 */
export function getLang() {
    // the lang is set in PageWrapper.
    const lang = document.querySelector('html').getAttribute('lang');
    return lang?? 'it';
}

/** the title of the image is the .title field for IT, the .alt for EN. Here we simplify the access */
export function getImageTitle(im) {
    // IT, field title / EN, field alt
    if (!im) return 'no image';
    const lang = getLang();
    if (lang === 'en') return im.alt;
    return im.title;
}

/** similar for the didascalia. Here we simplify the access */
export function getImageCaption(im) {
    // IT, field caption / EN, field description
    if (!im) return ' -- ';
    const lang = getLang();
    if (lang === 'en') return im.description;
    return im.caption;
}

// This is only for localhost, because if I want to load a texture in Threejs I have the CORS Origin problem
// export function convertFileUrlIntoFilePath(fileurl) {
//     if (!process.env.REACT_APP_WP_FILE_BASEPATH) return fileurl;
//     if (!process.env.REACT_APP_WP_BASEURL) return fileurl;
    
//     const filepath = fileurl.replace(process.env.REACT_APP_WP_BASEURL,process.env.REACT_APP_WP_FILE_BASEPATH);
//     return filepath;
// }

export function isComingSoon() {
    

    var search = window.location.search;
    var params = new URLSearchParams(search);
    // console.log('PAPAPAPA', params.get('coming-soon'));
    if (params.get('coming-soon')) return true;
    if (params.get('demo')) return false;

    // use env var, only if homepage.
    if ( process.env.REACT_APP_COMINGSOON === "1" &&
        (window.location.pathname === '/' || 
         window.location.pathname === '/en') )
        return true;

    return false;
}

export function isDebug() {
    return (process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG !== "0");
}
export function deval(string) {
    if (isDebug()) alert(string);
}

export function dev1(string, ...args) { 
    if (isDebug()) {
        const style = 'font-weight: bold; font-size: 50px;color: red; text-shadow: 1px 1px 0 rgb(217,31,38) , 2px 2px 0 rgb(226,91,14) , 1px 1px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';
        console.log('%c(D>) ' + string, style, ...args );
    }
}
export function dev2(string, ...args) { 
    if (isDebug()) {
        const style = 'font-weight: bold; color: green; text-shadow: 1px 1px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';
        console.log('%c(D>) ' + string, style, ...args );
    }
}
export function devdel(string, ...args) { 
    if (isDebug()) {
        const style = 'font-weight: bold; color: green; text-shadow: 1px 1px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';
        console.log('%c(TODELETE>) ' + string, style, ...args );
    }
}