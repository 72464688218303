import React, {useState, useEffect} from 'react'

import {    GoogleMap,
    useLoadScript,
    Marker } from "@react-google-maps/api"
import { _t, __ } from '../../../translations';

import CompLeafletMap from './CompLeafletMap';
// subcomponents
import MapMarkers from './google-map-subcomps/MapMarkers';
import MapTextLeft from './google-map-subcomps/MapTextLeft';
import MapViewDropdown from './google-map-subcomps/MapViewDropdown';
import Gallery from '../../../LayoutComponents/Gallery';

import {isDebug, getImageCaption, devdel, deval, dev1, updateQueryStringParameter} from '../../../helpers';
import { createMapOverlay } from './google-map-subcomps/map-overlays';
import useKeypressHook from '../../../useKeyPressHook';
import MapListOfHotspots from './google-map-subcomps/MapListOfHotspots';
import MapButtonsTop from './google-map-subcomps/MapButtonsTop';
import CircleButton from '../../../LayoutComponents/CircleButton';

/** =========== PARAMS for the GOOGLE MAP creation ====================== */
const libaries = [];
const mapContainerStyle = { width: '100%', height: '100%' }
var defaultCenter = { lat:43.80537, lng:12.3691 };
const options = {
    styles: require('./google-map-subcomps/style-google-map.json'),  // look in snazzymaps for a nice skin, put it in a json and import it
    disableDefaultUI: true, // -- take off the contols MAP/SATELLITE... so I can use my own. I can add a Logo... 
    mapTypeId: 'terrain' // use to map.setMapTypeId('terrain')
}
/** ==================================================================== */

/**
 * 
 * @param {componentInfo} componentInfo.hotspots
 *                        componentInfo.description
 * @returns jsx
 */

export default function CompLeafletOrGoogleMap({ componentInfo,
    index, 
    // setPano360Selected, // only for MapPage, not for regular Page Component
    webparams, setWebparams }) {
    // dev1('aver',componentInfo)
    if (!componentInfo) return null;
    
    if ((typeof componentInfo.description_layout === 'object')
        && componentInfo.description_layout.includes('use-leaflet-not-google')) {
        return <CompLeafletMap componentInfo={componentInfo}  index={index} webparams={webparams} setWebparams={setWebparams} />
    }
    return <RealCompGoogleMap componentInfo={componentInfo}  index={index} webparams={webparams} setWebparams={setWebparams} />
}

export /*default*/ function RealCompGoogleMap({ componentInfo,
                                        index, 
                                        // setPano360Selected, // only for MapPage, not for regular Page Component
                                        webparams, setWebparams }) {

/**-- STATE
 * =============================================================== */
    const [hoveredHotspot, setHoveredHotspot]   = useState(null);
    const [selectedHotspot, setSelectedHotspot] = useState(null); // integer
    const [mapOverlaySVG, setMapOverlaySVG]     = useState(null); // integer
    const [textOnLeft, setTextOnLeft]           = useState(null); // panel on the left of the screen (description of the component)
    const [mapTempParams, setMapTempParams]     = useState({}); // save the zoom backup value before zooming into a hotspot
    const [mapView, setMapView]                 = useState(componentInfo.description_layout?.includes('use-satellite-map')? 'hybrid' : options.mapTypeId); // sync with mapTypeId of the map (terrain, satellite)
    const [helperPosLatLng, setHelperPosLatLng] = useState({lat:0,lng:0}); // only in debug mode
    const [extraControls, setExtraControls]     = useState({ overlayToggle: null });
    const [getLocationActive, setGetLocationActive] = useState(null);
    // const [hotspots, setHotspots]            = useState([]); 

/**-- GOOGLE MAP setup, on mount, refs
* =============================================================== */
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libaries
    });
    // Imporant: getting access to the map object to access to its methods. 
    const myMapRef  = React.useRef(); // access to google controls.[..]
    const controlsRef  = React.useRef(); // 
    const onMapLoad = React.useCallback( (map) => {
        myMapRef.current = map;
        // setHotspots(componentInfo.hotspots);
        devdel('maploaded', map);

        if (componentInfo.initial_params?.lat && componentInfo.initial_params?.lng) {
            myMapRef.current.setCenter({    lat: parseFloat(componentInfo.initial_params?.lat),
                                            lng: parseFloat(componentInfo.initial_params?.lng) });
        }
        // test creation of polyline. This should show the map somwhow.
        // test
        // createMapPolyline(map);
        // creation of controls
        // map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(controlsRef.current);
        if (componentInfo.overlay_map && componentInfo.overlay_map.image && componentInfo.overlay_map.svg_bounds) {
            const OL = createMapOverlay(   myMapRef.current, 
                                componentInfo.overlay_map.image , 
                                componentInfo.overlay_map.svg_bounds);
            setMapOverlaySVG(OL);
            // OL.setOpacity(0.5); // works
            // set overlay toggle control to true.
            setExtraControls( Object.assign({...extraControls}, { overlayToggle: true }));
        }
    }, []); 

/**-- HANDLERS
* =============================================================== */
    // handler interacting with map and hotspots
    // useCallback ensures that this is not triggered on every render. We need it only once
    const panTo = React.useCallback( (lat,lng,thezoom=null) => {
        console.log(myMapRef.current)
        // alert('tehzoom'+thezoom)
        // myMapRef.current.panTo({ lat:42, lng: 10})
        myMapRef.current.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) });
        myMapRef.current.setZoom(thezoom? parseInt(thezoom) : 14);
    })
    const zoomControlHandler = (e, InOrOut) => {
        const newZoom = myMapRef.current.zoom + (InOrOut==='-'? -1 : 1);  
        myMapRef.current.setZoom(Math.min(Math.max(1, newZoom), 20));
    }
    // button to activate geolocation
    const handleGeolocation = (e) => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                myMapRef.current.setCenter(pos);
                setGetLocationActive(pos); // this places the marker there
            },
            () => {
                alert(_t('Error finding your location/Errore trovando la tua posizione'));    
            }
            );
        } else {
            // Browser doesn't support Geolocation
            alert(_t('Your browser does not support geo localization/Il tuo browser no supporta geolocalizzazione'));
        }        
    }

/**-- WATCH
* =============================================================== */
    // watch on SELECT HOTSPOT: the text on left will have a title
    useEffect(() => {
        
        // unselect ht: set text on left panel when no ht is selected
        if ( typeof selectedHotspot !== 'number' ) {
            setTextOnLeft( componentInfo?.description );
            if (mapTempParams.backupZoom) {
                myMapRef.current.setZoom(mapTempParams.backupZoom);
                setMapTempParams( Object.assign( {...mapTempParams}, { backupZoom: null } ));
            }
            setWebparams(Object.assign({...webparams}, { extraClasses: webparams.extraClasses.replace(' google-hotspot-selected' , '') }))

            

            return;
        }
    
        // select hotspot: set Text on left, pan and zoom
        const currentHt = componentInfo.hotspots[selectedHotspot];
        const htmlText = `<div>
            <h2 class="h3"> ${currentHt?.title} </h2>
            ${currentHt?.description}
        </div>`;
        setTextOnLeft( htmlText );
        setWebparams(Object.assign({...webparams}, { extraClasses: webparams.extraClasses + ' google-hotspot-selected' }));

        // TODO: Save the original zoom, then Pan to the hs in the map
        setMapTempParams( Object.assign( {...mapTempParams}, { backupZoom: myMapRef.current.getZoom() } ));
        panTo(currentHt.coordinates.lat, currentHt.coordinates.lng, currentHt.coordinates.zoom);

        // added querystr from url
        const newUrl = updateQueryStringParameter(window.location.href, 'selected', selectedHotspot ) ; // delete the query string in the url (it was created when the btn was clicked)
        window.history.replaceState(null, document.title, newUrl);

        return () => {
            // removed querystr from url. We dont want to load this on page load, only when a ht has been selected and then unselected.
            const newUrl = updateQueryStringParameter(window.location.href, 'selected', null ) ; // delete the query string in the url (it was created when the btn was clicked)
            window.history.replaceState(null, document.title, newUrl);
            deval('unselected ht'+mapTempParams.backupZoom);
        }        
    }, [selectedHotspot]);

    // ON HOVER (watch hoveredHotspot)
    React.useEffect(()=>{
        if (typeof selectedHotspot === 'number') return;
        if (typeof hoveredHotspot !== 'number') {
            // unhovered
            if (typeof selectedHotspot !== 'number') {
                dev1('a todelete',componentInfo.description_layout);
                if (componentInfo.description_layout.includes('show-list-of-hotspots'))
                    setTextOnLeft( '' );
                else 
                    setTextOnLeft( componentInfo?.description );
                // setTextOnLeft( mapTempParams.textOnLeft );
                // setMapTempParams( Object.assign( {...mapTempParams}, { textOnLeft: '' } ));
            }
            return;
        }
        const currentHt = componentInfo.hotspots[hoveredHotspot];
        
        const htmlText = `<div>
            <h2 class="h3"> ${currentHt?.title} </h2>
            <div>${currentHt?.excerpt}</div>
            <figure>
            ` + (currentHt.image? `<img src="${currentHt.image.sizes.large}" />` : '') + `
            ${`<figcaption>${getImageCaption(currentHt.image)}</figcaption>`}
            </figure>
        </div>`;
        // setMapTempParams( Object.assign( {...mapTempParams}, { textOnLeft: textOnLeft } ));
        setTextOnLeft( htmlText );

    }, [hoveredHotspot])

    // watch mapView (change to satellite/terrain)
    React.useEffect(() => {
        if (myMapRef.current)
            myMapRef.current.setMapTypeId(mapView);
    }, [mapView]);

    // watch extra Controls (change overlay on/off input click)
    React.useEffect(() => {
        if (mapOverlaySVG)
            mapOverlaySVG.setOpacity( extraControls.overlayToggle? 1 : 0) // show/hide overlay
        
    }, [extraControls]);


/**-- FUNCTIONS
* =============================================================== */ 
    function isFirstImageHorizontal() {
        if (typeof selectedHotspot !== 'number') return null;
        if (componentInfo?.hotspots[selectedHotspot]?.gallery?.length) {
            const firstImg = componentInfo.hotspots[selectedHotspot].gallery[0];
            if (firstImg.width > firstImg.height) return true;
        }
    }

// Hook to Esc fn   
useKeypressHook( 'Escape', () => {
    if (!webparams.exploreLanscapeSelected && // se abbiamo un'altro component a full screen non fare caso
        !webparams.exploreQuadroSelected &&
        !webparams.pano360Selected &&
        !webparams.videoBalconeSelected) {
            if (selectedHotspot) setSelectedHotspot(null);
        }
},[selectedHotspot, webparams.exploreLanscapeSelected, webparams.pano360Selected, webparams.videoBalconeSelected]);




/* =============================================================== */ 
/**-- TEMPLATE JSX
* =============================================================== */ 

    if (loadError) return "error loading Google Map";
    if (!isLoaded) return (<p className='badge badge-sucess'>Is loading...</p>)
    
    return <div id={`component-${index}`} 
                className={`GoogleMap position-relative 
                                GoogleMap--hotspot-${(typeof selectedHotspot === 'number'? 'selected' : 'unselected')} ${
                                componentInfo.description_layout.join(' ')
                            } GoogleMap--hotspot-${(typeof hoveredHotspot === 'number'? 'hovered' : 'unhovered')} `}>
        <GoogleMap 
            id="map-container"
            mapContainerStyle={mapContainerStyle}
            zoom={componentInfo.initial_params?.zoom? parseInt(componentInfo.initial_params.zoom) : 10} 
            center={defaultCenter}
            options={ componentInfo.description_layout?.includes('use-satellite-map')?  Object.assign(options, { mapTypeId: 'hybrid' }) : options}
            onLoad={onMapLoad}
            onMouseMove={ e => (isDebug()&&e.domEvent.shiftKey)? setHelperPosLatLng(e.latLng.toJSON()) : null }
            >
            {/* The Markers  */}
            <MapMarkers hotspots={componentInfo.hotspots}
                         selectedHotspot={selectedHotspot}  setSelectedHotspot={setSelectedHotspot} 
                         setHoveredHotspot={setHoveredHotspot}
                         />

            { getLocationActive &&
            <Marker 
                position={{ lat: parseFloat(getLocationActive.lat) , lng: parseFloat(getLocationActive.lng) }} 
                icon={{ url: process.env.REACT_APP_BASEURL + 'imgs/current-location-marker.png',
                        scaledSize: new window.google.maps.Size(15, 15), // scaled 15
                        origin: new window.google.maps.Point(0,0), // origin
                        anchor: new window.google.maps.Point(15/2,15/2), // anchor
                } }
                name={`current-location`}
                />
            }
            
            <div className="GoogleMap__controls d-flex position-absolute z-9" ref={controlsRef}>

                {/* OVERLAY TOGGLE */}
                { (extraControls.overlayToggle !== null) && <div className="overlay-toggle-wrapper d-block">
                        <input id="overlay-toggle" type="checkbox" defaultChecked={extraControls.overlayToggle} // we can set from CMS API ACF
                                onChange={ e => setExtraControls(Object.assign({...extraControls}, { overlayToggle: e.target.checked } )) } />
                        <label htmlFor="overlay-toggle">Overlay</label> 
                    </div>
                }

                <div className="GoogleMap__controls--zoombnts">
                    <button className='btn btn-primary rounded-circle' onClick={ e => zoomControlHandler(e, '+') }>+</button>
                    <button className='btn btn-primary rounded-circle' onClick={ e => zoomControlHandler(e, '-') }>-</button>
                </div>
                <MapViewDropdown currentValueIndex={mapView}
                                allValues={ { terrain: "Terrain", satellite: "Satellite", roadmap: "Road", hybrid: "Hybrid" } } 
                                updateFunction={ (value => setMapView(value))}  //  see the watch mapView, which updates myMapRef.current.setMapTypeId(value); 
                                />
                <div className="GoogleMap__controls--geolocalitation-btn">
                    <button className='btn btn-primary rounded-circle'
                            onClick={handleGeolocation}>
                            <i className="bi bi-cursor"></i>
                    </button>
                </div>
                
            </div>
        </GoogleMap>

        
        {/* Button to select the 180 Panorama Component */}
        <MapButtonsTop 
            webparams={webparams} setWebparams={setWebparams} 
            selectedHotspot={selectedHotspot} setSelectedHotspot={setSelectedHotspot}
            componentInfo={componentInfo} />

        {/* text on left is: the description of the component by default. 
                        when hovering the ht, puts the image and title. 
                        when selecting ht, shows description and actions 
                        Extra params, in webparams, like description_layout (ie [first-letter-large])
                        */}
        
        <MapTextLeft    theTextDescription={textOnLeft} 
                        selectedHotspot={selectedHotspot} setSelectedHotspot={setSelectedHotspot}
                        hoveredHotspot={hoveredHotspot}
                        hotspots={componentInfo.hotspots}
                        // setPano360Selected={setPano360Selected}
                        //setExploreLanscapeSelected={setExploreLanscapeSelected}
                        webparams={Object.assign(webparams, {description_layout: componentInfo.description_layout}) } setWebparams={setWebparams} />
        

        
        <MapListOfHotspots
                selectedHotspot={selectedHotspot} setSelectedHotspot={setSelectedHotspot}
                hoveredHotspot={hoveredHotspot} setHoveredHotspot={setHoveredHotspot}
                componentInfo={componentInfo} />
        

        {/* In Tour Page: Gallery for Page Map component */}
        { componentInfo.gallery?
            <div className={`GoogleMap__gallery`}>
                <Gallery gallery={componentInfo.gallery} webparams={webparams} />
            </div>:null }

        {/* In Map Page: Image on the right of the painting for selected balcony */}
        { typeof(selectedHotspot) === 'number' && componentInfo.hotspots[selectedHotspot].gallery &&
            <div className={`GoogleMap__gallery 
                             GoogleMap__gallery--hotspot-gallery 
                            ${'gallery-length-'+componentInfo.hotspots[selectedHotspot].gallery.length} `+
                            ' GoogleMap__gallery--fist-img-'+ (isFirstImageHorizontal()? 'horizontal' : 'vertical')
                            }>
                <Gallery gallery={componentInfo.hotspots[selectedHotspot].gallery} webparams={webparams} quality={'large'} />
            </div> }
        
        {   // shows a button to change from landscape to explore quadro. This is overlayed on top of the CompExplore
            webparams.exploreLanscapeSelected && typeof(selectedHotspot) === 'number' && componentInfo.hotspots[selectedHotspot].explore_quadro && (
            <div className="btn-swap-landscape-to-quadro-wrapper">
                <CircleButton
                    onClickAction={ e=> setWebparams(Object.assign({...webparams}, 
                                                                {
                                                                    exploreLanscapeSelected:null,
                                                                    exploreQuadroSelected: componentInfo.hotspots[selectedHotspot].explore_quadro
                                                                })) }
                    classes='btn-primary position-relative btn-primary'
                    iconClass='bi-bi-arrow-left-circle' textHelp={_t('Explore Painting/Esplora Quadro')} textInside={ _t('Swap/Cambia a Quadro') } />
            </div>
        ) }
         {   // shows a button to change from landscape to explore quadro. This is overlayed on top of the CompExplore
            webparams.exploreQuadroSelected && typeof(selectedHotspot) === 'number' && componentInfo.hotspots[selectedHotspot].explore_landscape && (
            <div className="btn-swap-landscape-to-quadro-wrapper">
                <CircleButton
                    onClickAction={ e=> setWebparams(Object.assign({...webparams}, 
                                                                {
                                                                    exploreLanscapeSelected: componentInfo.hotspots[selectedHotspot].explore_landscape,
                                                                    exploreQuadroSelected: null
                                                                })) }
                    classes='btn-primary position-relative btn-primary'
                    iconClass='bi-bi-arrow-left-circle' textHelp={__('Explore Landscape')} textInside={ __('Swap') } />
            </div>
        ) }


        {isDebug() && <div className="GoogleMap__debug-info">
            Lat: {helperPosLatLng.lat}<br/>
            Lon: {helperPosLatLng.lng}<br/>
            SelectedHt: { selectedHotspot }
        </div>}
    </div>;

}

/* =============================================================== */ 
/**-- END TEMPLATE JSX
* =============================================================== */ 