import React from 'react'
import './MapViewDropdown.scss';

export default function MapViewDropdown( { currentValueIndex, allValues, updateFunction }) {
    
    const [open, setOpen] = React.useState(false);
    const currentLabel    = allValues[currentValueIndex];
    if (!currentLabel) return null;
    return (
        <div className={`MapViewDropdown dropup dropdown ${open? 'open' : 'close'} mx-3`} onClick={ e => setOpen(!open) }>
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                { currentLabel }
            </button>
            <div className={`dropdown-menu ${open? 'show' : 'hide'}`} aria-labelledby="dropdownMenuButton">
                { Object.keys(allValues).map( key=> <a
                        key={`mapview-${key}`}
                        onClick={ e => updateFunction(key) }
                        className={`dropdown-item ${key===currentValueIndex? 'selected' : ''}`}>
                            { allValues[key] }
                        </a> )
                }
            </div>
        </div>
            
    )
}

