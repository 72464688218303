import React from 'react'

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { getImageCaption, getImageTitle } from '../helpers';
/**
 * 
 * @param {array} gallery. Array of imgs. 
 *      Each img is an WP media object with img.sizes.thumbnail ...
 *      
 */
export default function Gallery( { gallery, webparams, quality }) {
    if (! gallery ) return null;
    if (! gallery.length || gallery.length < 1 ) return null;

    return (
        <SimpleReactLightbox>
        <SRLWrapper>
            { gallery.map( (img,i) => {
                const large = img.url;
                const sm    = quality? img.sizes[quality] : img.sizes.medium;
                const ratio = img.width/img.height; 
                return <figure  className={`img-n-caption ${ratio>1? 'horizontal' : 'vertical'}`}
                                id={`media-${img.ID}`}
                                data-mediaurl={img.link}
                                key={`img-${img.ID}`}  >
                            <a href={large} className="ContentAndGallery__galleryimage">
                                <img src={sm} alt={ getImageCaption(img) } />
                            </a>
                            <figcaption className='caption'>
                                { getImageTitle(img) }
                            </figcaption>
                        </figure>
            }) }
        </SRLWrapper>
        </SimpleReactLightbox>
    )
}